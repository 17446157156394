import {CustomData} from "../CustomDataType";
import {IncomingWebrtcCall} from "../IncomingWebrtcCall";

export class IncomingWebrtcCallEvent {
    constructor(private _incomingCall: IncomingWebrtcCall,
                private _customData: CustomData) {
    }

    get incomingCall(): IncomingWebrtcCall {
        return this._incomingCall;
    }

    get customData(): CustomData {
        return this._customData;
    }
}