import {AudioFilterFactory} from "./filters/AudioFilter";
import {AudioQualityMode} from "./AudioQualityMode";

export class AudioOptions {
    constructor(private _audioFilterFactory: AudioFilterFactory,
                private _audioQualityMode: AudioQualityMode) {
    }

    get audioFilterFactory(): AudioFilterFactory {
        return this._audioFilterFactory;
    }

    get audioQualityMode(): AudioQualityMode {
        return this._audioQualityMode;
    }

    static builder(): AudioOptionsBuilder {
        return new AudioOptionsBuilder();
    }
}

class AudioOptionsBuilder {
    private _audioFilterFactory: AudioFilterFactory;
    private _audioQualityMode: AudioQualityMode

    setAudioFilterFactory(audioFilterFactory: AudioFilterFactory): AudioOptionsBuilder {
        this._audioFilterFactory = audioFilterFactory;
        return this;
    }

    setAudioQualityMode(audioQualityMode: AudioQualityMode): AudioOptionsBuilder {
        this._audioQualityMode = audioQualityMode;
        return this;
    }

    build() {
        return new AudioOptions(this._audioFilterFactory, this._audioQualityMode);
    }
}
