import {AudioStats} from "./AudioStats";

export class CurrentMediaStats {
    public currentAudioStats: AudioStats = new AudioStats();
    public mos: number = 5.0;

    public toJSON(): any {
        return {
            currentAudioStats: this.currentAudioStats.toJSON(),
            mos: this.mos
        };
    }
}