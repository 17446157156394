export class EmptyAudioStream {

     private readonly audioContext: AudioContext;
     private readonly oscillator: OscillatorNode;
     private readonly mediaStreamDestination: MediaStreamAudioDestinationNode;

    constructor() {
        this.audioContext = new AudioContext();
        this.oscillator = this.audioContext.createOscillator();
        this.mediaStreamDestination = this.audioContext.createMediaStreamDestination();
        this.mediaStreamDestination.stream.getAudioTracks()[0].enabled = false;
        this.oscillator.connect(this.mediaStreamDestination);
        this.oscillator.start();
    }

    stream(): MediaStream {
        return this.mediaStreamDestination.stream;
    }

    close(): void {
        this.audioContext.close().catch();
        this.oscillator.stop();
    }
}
