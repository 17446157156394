export class IceCandidate {

    private _protocol: string;
    private _ip: string;
    private _port: number;
    private _type: string;

    constructor(stats: any) {
        this._protocol = stats.protocol;
        this._ip = stats.ip || stats.address;
        this._port = stats.port;
        this._type = stats.candidateType;
    }

    get protocol(): string {
        return this._protocol;
    }

    set protocol(value: string) {
        this._protocol = value;
    }

    get ip(): string {
        return this._ip;
    }

    set ip(value: string) {
        this._ip = value;
    }

    get port(): number {
        return this._port;
    }

    set port(value: number) {
        this._port = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    public toJSON() {
        return {
            protocol: this._protocol,
            ip: this._ip,
            port: this._port,
            type: this._type
        };
    }

}
