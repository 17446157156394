import {Call} from "../Call";
import {Endpoint} from "../../util/Endpoint";
import {CallOptions} from "../options/CallOptions";
import {CallStatus} from "../CallStatus";
import {ApiEventEmitter} from "../../util/ApiEventEmitter";
import {AudioFilter} from "../options/filters/AudioFilter";
import {AnyCallsApiEvent, CallsApiEvent} from "../event/CallsApiEvents";
import {CallsEventHandlers} from "../event/CallsEventHandlers";
import {ApplicationCall} from "../ApplicationCall";
import {CustomData} from "../CustomDataType";
import {AudioQualityMode} from "../options/AudioQualityMode";

export class DefaultCall implements Call {
    protected apiEventEmitter: ApiEventEmitter;
    protected applicationCall: ApplicationCall;

    constructor(protected basicCallOptions: CallOptions,
                protected sourceEndpoint: Endpoint,
                protected destinationEndpoint: Endpoint) {
        this.apiEventEmitter = new ApiEventEmitter();
    }

    on(name: AnyCallsApiEvent, handler: CallsEventHandlers.Any): void {
        if (!Object.values(CallsApiEvent)
            .find(apiEvent => apiEvent === name)) {
            throw new Error(`Unknown event: ${name}!`);
        }
        this.apiEventEmitter.on(name, handler);
    }

    id(): string {
        return this.applicationCall.id();
    }

    options(): CallOptions {
        return this.basicCallOptions;
    }

    customData(): CustomData {
        return this.options().customData;
    }

    duration(): number {
        return this.applicationCall.duration();
    }

    status(): CallStatus {
        return this.applicationCall.status();
    }

    startTime(): Date {
        return this.applicationCall.startTime();
    }

    establishTime(): Date {
        return this.applicationCall.establishTime();
    }

    endTime(): Date {
        return this.applicationCall.endTime();
    }

    mute(shouldMute: boolean): Promise<void> {
        return this.applicationCall.mute(shouldMute);
    }

    muted(): boolean {
        return this.applicationCall.muted();
    }

    sendDTMF(dtmf: string): Promise<void> {
        return this.applicationCall.sendDTMF(dtmf);
    }

    setAudioInputDevice(deviceId: string): Promise<void> {
        return this.applicationCall.setAudioInputDevice(deviceId);
    }

    audioFilter(): AudioFilter {
        return this.applicationCall.audioFilter();
    }

    setAudioFilter(audioFilter: AudioFilter): Promise<void> {
        return this.applicationCall.setAudioFilter(audioFilter);
    }

    clearAudioFilter(): Promise<void> {
        return this.applicationCall.clearAudioFilter();
    }

    counterpart(): Endpoint {
        return undefined;
    }

    source(): Endpoint {
        return this.sourceEndpoint;
    }

    destination(): Endpoint {
        return this.destinationEndpoint;
    }

    setAudioQualityMode(audioQualityMode: AudioQualityMode): void {
        return this.applicationCall.setAudioQualityMode(audioQualityMode);
    }

    audioQualityMode(): AudioQualityMode {
        return this.applicationCall.audioQualityMode();
    }

    hangup(): void {
        return this.applicationCall.hangup();
    }
}
