import {AudioFilter, AudioFilterFactory} from "./AudioFilter";

export class ManagedAudioFilter {
    public static createWrapped(factory: AudioFilterFactory): ManagedAudioFilter {
        if (!factory) {
            return null;
        }
        const instance = factory()
        if (!instance) {
            return null;
        }
        return ManagedAudioFilter.wrap(instance);
    }

    public static wrap(AudioFilter: AudioFilter): ManagedAudioFilter {
        if (!AudioFilter) {
            return null;
        }
        return new ManagedAudioFilter(AudioFilter);
    }

    private _sourceTrack: MediaStreamTrack;
    private _filteredTrack: MediaStreamTrack;

    public get delegate(): AudioFilter {
        return this._delegate;
    }

    constructor(private _delegate: AudioFilter) {
    }

    async apply(stream: MediaStream, trackIndex: number): Promise<MediaStreamTrack> {
        let inputTrack = stream.getAudioTracks()[trackIndex];
        if (!inputTrack) {
            return;
        }
        if (this._sourceTrack) {
            this._sourceTrack.stop();
        }
        this._sourceTrack = inputTrack;
        stream.removeTrack(inputTrack);
        this._filteredTrack = await this.delegate.apply(inputTrack);
        stream.addTrack(this._filteredTrack);
        return this._filteredTrack;
    }

    release(): Promise<void> {
        this._sourceTrack?.stop();
        this._filteredTrack?.stop();
        return this.delegate.release();
    }

    async restore(stream: MediaStream): Promise<void> {
        if (this._filteredTrack) {
            stream.removeTrack(this._filteredTrack);
            stream.addTrack(this._sourceTrack);
            this._filteredTrack.stop();
            delete this._filteredTrack;
            delete this._sourceTrack;
        }
    }
}
