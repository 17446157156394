import {CameraOrientation} from "./CameraOrientation";
import {VideoFilterFactory} from "./filters/VideoFilter";
import {VideoMode} from "./VideoMode";

export class VideoOptions {
    constructor(private _cameraOrientation: CameraOrientation,
                private _videoFilterFactory: VideoFilterFactory,
                private _videoMode: VideoMode,
                private _cameraVideoFrameRate: number,
                private _screenShareFrameRate: number) {
    }

    get cameraOrientation(): CameraOrientation {
        return this._cameraOrientation;
    }

    get videoFilterFactory(): VideoFilterFactory {
        return this._videoFilterFactory;
    }

    get videoMode(): VideoMode {
        return this._videoMode;
    }

    get cameraVideoFrameRate(): number {
        return this._cameraVideoFrameRate;
    }

    get screenShareFrameRate(): number {
        return this._screenShareFrameRate;
    }

    static builder(): VideoOptionsBuilder {
        return new VideoOptionsBuilder();
    }
}

class VideoOptionsBuilder {
    private _cameraOrientation: CameraOrientation = CameraOrientation.FRONT;
    private _videoFilterFactory: VideoFilterFactory;
    private _videoMode: VideoMode = VideoMode.PRESENTATION;
    private _cameraVideoFrameRate: number = 24
    private _screenShareFrameRate: number = 8

    setCameraOrientation(_cameraOrientation: CameraOrientation): VideoOptionsBuilder {
        this._cameraOrientation = _cameraOrientation;
        return this;
    }

    setVideoFilterFactory(videoFilterFactory: VideoFilterFactory): VideoOptionsBuilder {
        this._videoFilterFactory = videoFilterFactory;
        return this;
    }

    setVideoMode(videoMode: VideoMode): VideoOptionsBuilder {
        this._videoMode = videoMode;
        return this;
    }

    setCameraVideoFrameRate(cameraVideoFrameRate: number): VideoOptionsBuilder {
        this._cameraVideoFrameRate = cameraVideoFrameRate;
        return this;
    }

    setScreenShareFrameRate(screenShareFrameRate: number): VideoOptionsBuilder {
        this._screenShareFrameRate = screenShareFrameRate;
        return this;
    }

    build() {
        return new VideoOptions(this._cameraOrientation, this._videoFilterFactory, this._videoMode, this._cameraVideoFrameRate, this._screenShareFrameRate);
    }
}
