export default Object.freeze({
    cameraEncodings: [
        {
            rid: 'h',
            active: true,
            maxBitrate: 500000
        },
        {
            rid: 'm',
            active: true,
            maxBitrate: 250000,
            scaleResolutionDownBy: 2
        },
        {
            rid: 'l',
            active: true,
            maxBitrate: 150000,
            scaleResolutionDownBy: 4
        }
    ] as RTCRtpEncodingParameters[],

    cameraEncodingsMobile: [
        {
            rid: 'm',
            active: true,
            maxBitrate: 250000
        },
        {
            rid: 'l',
            active: true,
            maxBitrate: 150000,
            scaleResolutionDownBy: 2
        }
    ] as RTCRtpEncodingParameters[],

    screenShareEncodings: [
        {
            rid: 's',
            active: true,
            maxBitrate: 300000
        }
    ] as RTCRtpEncodingParameters[]
});
