type RetryDelayFunction = (iteration: number) => number;
type RetryTimeoutFunction = (callable: () => void, delayMs: number) => any;

export enum RetryFailReason {
    MaxRetries
}

export default class Retry {
    static linearDelay(baseDelayMs: number, baseIncrementMs?: number): RetryDelayFunction {
        baseIncrementMs = baseIncrementMs || baseDelayMs;
        return iteration => baseDelayMs + iteration * baseIncrementMs;
    }

    static fibonacciDelay(baseDelayMs: number): RetryDelayFunction {
        const phi = (1 + 5 ** .5) / 2, psi = 1 - phi;
        const fib = (n: number) => Math.round((phi ** n - psi ** n) / (5 ** .5));
        return iteration => baseDelayMs * fib(iteration + 1);
    }

    static exponentialDelay(baseDelayMs: number, factor: number = 2): RetryDelayFunction {
        return iteration => factor ** iteration * baseDelayMs;
    }

    private awaitingTimeout: boolean = false;
    private iteration: number = 0;
    private reject: (reason: RetryFailReason) => void;

    constructor(
            private callable: (iteration: number) => void,
            private delayFunction: RetryDelayFunction,
            private maxDelayMs: number = 5000,
            private maxRetries: number = 5,
            private timeoutFunction: RetryTimeoutFunction = window.setTimeout.bind(window)) {}

    retry(): Retry {
        if (this.awaitingTimeout)
            return this;
        if (this.iteration >= this.maxRetries) {
            this.reject?.(RetryFailReason.MaxRetries);
            return this;
        }
        let timeoutMs = Math.min(this.maxDelayMs, this.delayFunction(this.iteration));
        this.awaitingTimeout = true;
        this.timeoutFunction(() => {
            this.awaitingTimeout = false;
            this.callable(this.iteration++);
        }, timeoutMs);
        return this;
    }

    catch(reject: (reason: RetryFailReason) => void): Retry {
        this.reject = reject;
        return this;
    }
}
