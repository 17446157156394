import {IceCandidate} from "./IceCandidate";

export class SelectedCandidatePair {
    private local: IceCandidate;
    private remote: IceCandidate;

    constructor(local: IceCandidate, remote: IceCandidate) {
        this.local = local;
        this.remote = remote;
    }
}
