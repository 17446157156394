import VideoType from "../../util/VideoType";

export abstract class AbstractCapturer {
    constructor(protected localCameraStream: () => MediaStream,
                protected localScreenShareStream: () => MediaStream,
                protected remotePeerConnection: () => RTCPeerConnection,
                protected mid: (identity: string, type: VideoType) => string,
                protected currentUserIdentity: string) {
    }

    protected async getCanvas(identity: string, videoType: VideoType) {
        let videoStream = this.getVideoStream(identity, videoType);
        if (!videoStream) {
            throw new Error(`Video not found for identity ${identity} and video type ${videoType}`);
        }

        return this.drawImage(videoStream);
    }

    private async drawImage(videoStream: MediaStream) {
        const video = await this.getVideoElement(videoStream);

        let canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        video.srcObject = null;

        return canvas;
    }

    private getVideoStream(identity: string, videoType: VideoType) {
        if (identity === this.currentUserIdentity) {
            return videoType === VideoType.CAMERA ? this.localCameraStream() : this.localScreenShareStream();
        } else {
            const mid = this.mid(identity, videoType);
            for (const transceiver of this.remotePeerConnection()?.getTransceivers()) {
                if (transceiver.mid === mid) {
                    return new MediaStream([transceiver.receiver.track]);
                }
            }
        }
        return null;
    }

    private async getVideoElement(stream: MediaStream) {
        const video = document.createElement('video')

        const track = stream.getVideoTracks()[0];
        video.width = track.getSettings().width;
        video.height = track.getSettings().height;
        video.autoplay = true;
        video.playsInline = true;
        video.srcObject = stream;

        await video.play();

        return video;
    }
}
