export class AudioStats {
    public codec: string;
    public roundTripTime: number = 0;
    public packetsSent: number = 0;
    public bytesSent: number = 0;
    public retransmittedPacketsSent: number = 0;
    public retransmittedBytesSent: number = 0;
    public packetsReceived: number = 0;
    public bytesReceived: number = 0;
    public packetsLost: number = 0;
    public jitter: number = 0;

    public toJSON() {
        return {
            codec: this.codec,
            roundTripTime: this.roundTripTime,
            packetsSent: this.packetsSent,
            bytesSent: this.bytesSent,
            retransmittedPacketsSent: this.retransmittedPacketsSent,
            retransmittedBytesSent: this.retransmittedBytesSent,
            packetsReceived: this.packetsReceived,
            bytesReceived: this.bytesReceived,
            packetsLost: this.packetsLost,
            jitter: this.jitter
        };
    }
}