import {CallStats} from "../CallStats";
import {VideoLocalMediaStats} from "./video/VideoLocalMediaStats";
import {VideoRemoteMediaStats} from "./video/VideoRemoteMediaStats";
import {LocalMediaStats} from "./LocalMediaStats";
import {RemoteMediaStats} from "./RemoteMediaStats";
import {ExtractedLocalStats, ExtractedRemoteStats, ExtractedStats} from "./ExtractedStats";
import {AudioLocalMediaStats} from "./audio/AudioLocalMediaStats";
import {AudioRemoteMediaStats} from "./audio/AudioRemoteMediaStats";

export class MediaStatsDiffUtil {
    public static diff(previousCallStats: ExtractedStats, currentCallStats: ExtractedStats, isVideo: boolean): ExtractedStats {
        if (!previousCallStats) {
            return currentCallStats;
        }

        let diffCallStats = new CallStats(isVideo);
        let diffLocalStats: ExtractedLocalStats = new Map<string, LocalMediaStats>();
        let diffRemoteStats: ExtractedRemoteStats = new Map<string, RemoteMediaStats>();

        diffCallStats.bytesSent = currentCallStats.callStats.bytesSent - previousCallStats.callStats.bytesSent;
        diffCallStats.bytesReceived = currentCallStats.callStats.bytesReceived - previousCallStats.callStats.bytesReceived;
        diffCallStats.currentRoundTripTime = currentCallStats.callStats.currentRoundTripTime;
        diffCallStats.availableOutgoingBitrate = currentCallStats.callStats.availableOutgoingBitrate;

        MediaStatsDiffUtil.calculateLocalDiff(
            diffLocalStats,
            previousCallStats.extractedLocalStats,
            currentCallStats.extractedLocalStats);

        MediaStatsDiffUtil.calculateRemoteDiff(
            diffRemoteStats,
            previousCallStats.extractedRemoteStats,
            currentCallStats.extractedRemoteStats);

        return new ExtractedStats(diffCallStats, diffRemoteStats, diffLocalStats);
    }

    private static forEachStatsPair<E, T extends Map<string, E>>(
        previousCallStats: T,
        currentCallStats: T,
        callback: (previousStats: E, currentStats: E) => void
    ) {
        currentCallStats.forEach((currentStats, key) => {
            const previousStats = previousCallStats.get(key);
            if (!previousStats) {
                return;
            }
            callback(previousStats, currentStats);
        });
    }

    private static calculateRemoteDiff(diffCallStats: ExtractedRemoteStats,
                                       previousCallStats: ExtractedRemoteStats,
                                       currentCallStats: ExtractedRemoteStats) {
        MediaStatsDiffUtil.forEachStatsPair<RemoteMediaStats, ExtractedRemoteStats>(
            previousCallStats,
            currentCallStats,
            (previousStats, currentStats) => {
                let diffStats: VideoRemoteMediaStats | AudioRemoteMediaStats;
                if (currentStats.video) {
                    diffStats = new VideoRemoteMediaStats(currentStats.id);

                    MediaStatsDiffUtil.calculateRemoteVideoDiff(
                        diffStats,
                        <VideoRemoteMediaStats>currentStats,
                        <VideoRemoteMediaStats>previousStats);

                    diffCallStats[currentStats.id] = diffStats;
                } else {
                    diffStats = new AudioRemoteMediaStats(currentStats.id);

                    MediaStatsDiffUtil.calculateRemoteAudioDiff(
                        diffStats,
                        <AudioRemoteMediaStats>currentStats,
                        <AudioRemoteMediaStats>previousStats);
                }
                diffCallStats.set(currentStats.id, diffStats);
            });
    }

    private static calculateLocalDiff(diffCallStats: ExtractedLocalStats,
                                      previousCallStats: ExtractedLocalStats,
                                      currentCallStats: ExtractedLocalStats) {
        MediaStatsDiffUtil.forEachStatsPair<LocalMediaStats, ExtractedLocalStats>(
            previousCallStats,
            currentCallStats,
            (previousStats, currentStats) => {
                let diffStats: VideoLocalMediaStats | AudioLocalMediaStats;

                if (currentStats.video) {
                    diffStats = new VideoLocalMediaStats(currentStats.id);

                    MediaStatsDiffUtil.calculateLocalVideoDiff(
                        <VideoLocalMediaStats>diffStats,
                        <VideoLocalMediaStats>currentStats,
                        <VideoLocalMediaStats>previousStats);
                } else {
                    diffStats = new AudioLocalMediaStats(currentStats.id);

                    MediaStatsDiffUtil.calculateLocalAudioDiff(
                        diffStats,
                        <AudioLocalMediaStats>currentStats,
                        <AudioLocalMediaStats>previousStats);
                }
                diffCallStats.set(currentStats.id, diffStats);
            });
    }

    private static calculateLocalAudioDiff(diffAudioMediaStats: AudioLocalMediaStats, currentAudioMediaStats: AudioLocalMediaStats, previousAudioMediaStats: AudioLocalMediaStats) {
        MediaStatsDiffUtil.calculateCommonLocalMediaDiff(diffAudioMediaStats, currentAudioMediaStats, previousAudioMediaStats);
    }

    private static calculateRemoteAudioDiff(diffAudioMediaStats: AudioRemoteMediaStats, currentAudioMediaStats: AudioRemoteMediaStats, previousAudioMediaStats: AudioRemoteMediaStats) {
        MediaStatsDiffUtil.calculateCommonRemoteMediaDiff(diffAudioMediaStats, currentAudioMediaStats, previousAudioMediaStats);

        diffAudioMediaStats.jitter = currentAudioMediaStats.jitter;
        diffAudioMediaStats.concealedSamples = currentAudioMediaStats.concealedSamples - previousAudioMediaStats.concealedSamples;
        diffAudioMediaStats.concealmentEvents = currentAudioMediaStats.concealmentEvents - previousAudioMediaStats.concealmentEvents;
        diffAudioMediaStats.codec = currentAudioMediaStats.codec;
    }

    private static calculateLocalVideoDiff(diffVideoMediaStats: VideoLocalMediaStats, currentVideoMediaStats: VideoLocalMediaStats, previousVideoMediaStats: VideoLocalMediaStats) {
        MediaStatsDiffUtil.calculateCommonLocalMediaDiff(diffVideoMediaStats, currentVideoMediaStats, previousVideoMediaStats);
        MediaStatsDiffUtil.calculateCommonVideoLocalMediaDiff(diffVideoMediaStats, currentVideoMediaStats, previousVideoMediaStats);
    }

    private static calculateRemoteVideoDiff(diffVideoMediaStats: VideoRemoteMediaStats, currentVideoMediaStats: VideoRemoteMediaStats, previousVideoMediaStats: VideoRemoteMediaStats) {
        MediaStatsDiffUtil.calculateCommonRemoteMediaDiff(diffVideoMediaStats, currentVideoMediaStats, previousVideoMediaStats);
        MediaStatsDiffUtil.calculateCommonVideoRemoteMediaDiff(diffVideoMediaStats, currentVideoMediaStats, previousVideoMediaStats);

        diffVideoMediaStats.codec = currentVideoMediaStats.codec;
    }

    private static calculateCommonLocalMediaDiff(diffLocalMediaStats: LocalMediaStats, currentLocalMediaStats: LocalMediaStats, previousLocalMediaStats: LocalMediaStats) {
        diffLocalMediaStats.bytesSent = currentLocalMediaStats.bytesSent - previousLocalMediaStats.bytesSent;
        diffLocalMediaStats.packetsSent = currentLocalMediaStats.packetsSent - previousLocalMediaStats.packetsSent;
        diffLocalMediaStats.retransmittedBytesSent = currentLocalMediaStats.retransmittedBytesSent - previousLocalMediaStats.retransmittedBytesSent;
        diffLocalMediaStats.retransmittedPacketsSent = currentLocalMediaStats.retransmittedPacketsSent - previousLocalMediaStats.retransmittedPacketsSent;
    }

    private static calculateCommonRemoteMediaDiff(diffRemoteMediaStats: RemoteMediaStats, currentRemoteMediaStats: RemoteMediaStats, previousRemoteMediaStats: RemoteMediaStats) {
        diffRemoteMediaStats.bytesReceived = currentRemoteMediaStats.bytesReceived - previousRemoteMediaStats.bytesReceived;
        diffRemoteMediaStats.packetsReceived = currentRemoteMediaStats.packetsReceived - previousRemoteMediaStats.packetsReceived;
        diffRemoteMediaStats.packetsLost = currentRemoteMediaStats.packetsLost - previousRemoteMediaStats.packetsLost;
        diffRemoteMediaStats.jitterBufferDelay = currentRemoteMediaStats.jitterBufferDelay - previousRemoteMediaStats.jitterBufferDelay;
        diffRemoteMediaStats.jitterBufferEmittedCount = currentRemoteMediaStats.jitterBufferEmittedCount - previousRemoteMediaStats.jitterBufferEmittedCount;
        if (diffRemoteMediaStats.jitterBufferEmittedCount > 0) {
            diffRemoteMediaStats.averageJitterBufferDelay = Math.round(diffRemoteMediaStats.jitterBufferDelay / diffRemoteMediaStats.jitterBufferEmittedCount);
        }
    }

    private static calculateCommonVideoLocalMediaDiff(diffVideoLocalMediaStats: VideoLocalMediaStats, currentVideoLocalMediaStats: VideoLocalMediaStats, previousVideoLocalMediaStats: VideoLocalMediaStats) {
        diffVideoLocalMediaStats.frameHeight = currentVideoLocalMediaStats.frameHeight;
        diffVideoLocalMediaStats.frameWidth = currentVideoLocalMediaStats.frameWidth;
        diffVideoLocalMediaStats.framesSent = currentVideoLocalMediaStats.framesSent - previousVideoLocalMediaStats.framesSent;
        diffVideoLocalMediaStats.framesEncoded = currentVideoLocalMediaStats.framesEncoded - previousVideoLocalMediaStats.framesEncoded;
        diffVideoLocalMediaStats.keyFramesEncoded = currentVideoLocalMediaStats.keyFramesEncoded - previousVideoLocalMediaStats.keyFramesEncoded;
        diffVideoLocalMediaStats.nackCount = currentVideoLocalMediaStats.nackCount - previousVideoLocalMediaStats.nackCount;
        diffVideoLocalMediaStats.firCount = currentVideoLocalMediaStats.firCount - previousVideoLocalMediaStats.firCount;
        diffVideoLocalMediaStats.pliCount = currentVideoLocalMediaStats.pliCount - previousVideoLocalMediaStats.pliCount;
        diffVideoLocalMediaStats.qpSum = currentVideoLocalMediaStats.qpSum - previousVideoLocalMediaStats.qpSum;
        diffVideoLocalMediaStats.qualityLimitationReason = currentVideoLocalMediaStats.qualityLimitationReason;
        if (diffVideoLocalMediaStats.framesEncoded > 0) {
            diffVideoLocalMediaStats.averageQP = Math.round(diffVideoLocalMediaStats.qpSum / diffVideoLocalMediaStats.framesEncoded);
        }
    }

    private static calculateCommonVideoRemoteMediaDiff(diffVideoRemoteMediaStats: VideoRemoteMediaStats, currentVideoRemoteMediaStats: VideoRemoteMediaStats, previousVideoRemoteMediaStats: VideoRemoteMediaStats) {
        diffVideoRemoteMediaStats.frameHeight = currentVideoRemoteMediaStats.frameHeight;
        diffVideoRemoteMediaStats.frameWidth = currentVideoRemoteMediaStats.frameWidth;
        diffVideoRemoteMediaStats.framesReceived = currentVideoRemoteMediaStats.framesReceived - previousVideoRemoteMediaStats.framesReceived;
        diffVideoRemoteMediaStats.framesDecoded = currentVideoRemoteMediaStats.framesDecoded - previousVideoRemoteMediaStats.framesDecoded;
        diffVideoRemoteMediaStats.keyFramesDecoded = currentVideoRemoteMediaStats.keyFramesDecoded - previousVideoRemoteMediaStats.keyFramesDecoded;
        diffVideoRemoteMediaStats.framesDropped = currentVideoRemoteMediaStats.framesDropped - previousVideoRemoteMediaStats.framesDropped;
        diffVideoRemoteMediaStats.nackCount = currentVideoRemoteMediaStats.nackCount - previousVideoRemoteMediaStats.nackCount;
        diffVideoRemoteMediaStats.firCount = currentVideoRemoteMediaStats.firCount - previousVideoRemoteMediaStats.firCount;
        diffVideoRemoteMediaStats.pliCount = currentVideoRemoteMediaStats.pliCount - previousVideoRemoteMediaStats.pliCount;
        diffVideoRemoteMediaStats.qpSum = currentVideoRemoteMediaStats.qpSum - previousVideoRemoteMediaStats.qpSum;
        if (diffVideoRemoteMediaStats.framesDecoded > 0) {
            diffVideoRemoteMediaStats.averageQP = Math.round(diffVideoRemoteMediaStats.qpSum / diffVideoRemoteMediaStats.framesDecoded);
        }
    }
}
