import {CallOptions} from "./CallOptions";
import {AudioOptions} from "./AudioOptions";
import {PhoneCallRecordingOptions} from "./PhoneCallRecordingOptions";
import {CustomData} from "../CustomDataType";
import {AudioQualityMode} from "./AudioQualityMode";

export class PhoneCallOptions extends CallOptions {
    constructor(private _from: string,
                audio: boolean,
                audioOptions: AudioOptions,
                recordingOption: PhoneCallRecordingOptions,
                customData: CustomData) {
        super(audio, audioOptions, recordingOption, customData);
    }

    get from(): string {
        return this._from;
    }

    static builder(): PhoneCallOptionsBuilder {
        return new PhoneCallOptionsBuilder();
    }
}

class PhoneCallOptionsBuilder {
    private _from: string = null;
    private _audio: boolean = true;
    private _audioOptions: AudioOptions = new AudioOptions(null, AudioQualityMode.AUTO);
    private _recordingOptions: PhoneCallRecordingOptions = new PhoneCallRecordingOptions();
    private _customData: CustomData = {};

    setFrom(_from: string): PhoneCallOptionsBuilder {
        this._from = _from;
        return this;
    }

    setAudio(_audio: boolean): PhoneCallOptionsBuilder {
        this._audio = _audio;
        return this;
    }

    setAudioOptions(_audioOptions: AudioOptions): PhoneCallOptionsBuilder {
        this._audioOptions = _audioOptions;
        return this;
    }

    setRecordingOptions(recordingOptions: PhoneCallRecordingOptions): PhoneCallOptionsBuilder {
        this._recordingOptions = recordingOptions;
        return this;
    }

    setCustomData(_customData: CustomData): PhoneCallOptionsBuilder {
        this._customData = _customData;
        return this;
    }

    build() {
        return new PhoneCallOptions(this._from,
            this._audio,
            this._audioOptions,
            this._recordingOptions,
            this._customData
        );
    }
}
