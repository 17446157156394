import Bowser from 'bowser';

export default class Browser {
    private static browser = Bowser.getParser(window.navigator.userAgent)

    static isMobile() {
        let deviceOsName = this.browser.getOSName(true);
        return deviceOsName.includes("android") || deviceOsName.includes("ios");
    }

    static isWeb() {
        return !this.isMobile();
    }

    static getBrowser() {
        return this.browser.getBrowser();
    }

    static getOS() {
        return this.browser.getOS();
    }
}
