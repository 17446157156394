import events, {EventEmitter} from "events";
import {InfobipGateway, InfobipGatewayImpl} from "./gateway/InfobipGateway";
import Status, {StatusLabels} from "./gateway/Status";
import {Logger} from "./log/Logger";
import {DefaultLogger} from "./log/impl/DefaultLogger";
import {Device} from "./device/Device";
import {DefaultDevice} from "./device/DefaultDevice";
import jwtDecode from "jwt-decode";
import * as log from "loglevel";
import {LoggerOptions} from "./log/LoggerOptions";
import {ApplicationCall} from "./call/ApplicationCall";
import {DefaultIncomingApplicationCall} from "./call/impl/DefaultIncomingApplicationCall";
import {DefaultOutgoingApplicationCall} from "./call/impl/DefaultOutgoingApplicationCall";
import {IncomingApplicationCallEvent} from "./call/event/IncomingApplicationCallEvent";
import {ApiEventEmitter} from "./util/ApiEventEmitter";
import {RoomCall} from "./call/RoomCall";
import {DefaultRoomCall} from "./call/impl/DefaultRoomCall";
import {FlowType} from "./call/FlowType";
import {PhoneCall} from "./call/PhoneCall";
import {PhoneCallOptions} from "./call/options/PhoneCallOptions";
import {DefaultOutgoingPhoneCall} from "./call/impl/DefaultOutgoingPhoneCall";
import {ApplicationErrorCode} from "./call/ApplicationErrorCode";
import {WebrtcCall} from "./call/WebrtcCall";
import {DefaultOutgoingWebrtcCall} from "./call/impl/DefaultOutgoingWebrtcCall";
import {WebrtcCallOptions} from "./call/options/WebrtcCallOptions";
import {ViberCallOptions} from "./call/options/ViberCallOptions";
import {ViberCall} from "./call/ViberCall";
import {DefaultOutgoingViberCall} from "./call/impl/DefaultOutgoingViberCall";
import {DefaultIncomingWebrtcCall} from "./call/impl/DefaultIncomingWebrtcCall";
import {IncomingWebrtcCallEvent} from "./call/event/IncomingWebrtcCallEvent";
import {RoomCallOptions} from "./call/options/RoomCallOptions";
import {Properties} from "./Properties";
import {CustomData} from "./call/CustomDataType";
import {ApplicationCallOptions} from "./call/options/ApplicationCallOptions";
import {InfobipRTC} from "./InfobipRTC";
import {User} from "./call/User";
import {AnyInfobipRTCEvent, InfobipRTCEvent, InfobipRTCEvents} from "./event/InfobipRTCEvents";
import {InfobipRTCEventHandlers} from "./event/InfobipRTCEventHandlers";
import ValidationUtil from "./call/impl/util/ValidationUtil";
import {CallErrorEvent} from "./call/ws/event/CallEvents";
import {ErrorCode} from "./call/event/ErrorCode";
import ConnectedEvent = InfobipRTCEvents.ConnectedEvent;
import ReconnectingEvent = InfobipRTCEvents.ReconnectingEvent;
import ReconnectedEvent = InfobipRTCEvents.ReconnectedEvent;
import DisconnectedEvent = InfobipRTCEvents.DisconnectedEvent;

export class DefaultInfobipRTC implements InfobipRTC {
    private static IDENTITY_PATTERN = /^[\p{L}\p{N}\w.\-_+=/]{3,64}$/u;
    private static CONFERENCE_ID_PATTERN = /^[\p{L}\p{N}\w.\-_+=/]{3,250}$/u;
    private static PHONE_NUMBER_PATTERN = /^[+]?\p{N}+$/u;

    private readonly gateway: InfobipGateway;
    private readonly eventEmitter: EventEmitter;
    private readonly apiEventEmitter: ApiEventEmitter;
    private readonly device: Device;
    private readonly rtcConfig: any;
    private readonly currentUser: User;
    private readonly logger: Logger;
    private apiUrl: string;

    private callActive: boolean;

    constructor(private token: string, private rtcOptions: any = {}) {
        if (!this.isWebRTCSupported()) {
            throw new Error('Browser does not support WebRTC.');
        }

        rtcOptions.debug ? log.enableAll() : log.setLevel(log.levels.ERROR);

        this.eventEmitter = new events.EventEmitter();
        this.apiEventEmitter = new ApiEventEmitter();

        this.logger = new DefaultLogger(token, new LoggerOptions(), this.rtcOptions.statsUrl, this.rtcOptions.headersProvider);
        this.currentUser = this.getUserFromToken(jwtDecode(this.token));
        this.gateway = new InfobipGatewayImpl(this.eventEmitter, this.logger, this.token);
        this.device = new DefaultDevice(this.logger);
        this.rtcConfig = {};
        this.configureEventHandlers.call(this);
    }

    connect() {
        this.validateStatus(Status.OFFLINE, 'connect');
        this.gateway.connect(false);
    }

    disconnect() {
        if (this.logger) {
            this.logger.stop();
        }
        this.gateway.disconnect();
    }

    connectedUser(): User {
        return this.currentUser;
    }

    on(name: AnyInfobipRTCEvent, handler: InfobipRTCEventHandlers.Any) {
        if (!Object.values(InfobipRTCEvent)
            .find(apiEvent => apiEvent === name)) {
            throw new Error(`Unknown event: ${name}!`);
        }
        this.apiEventEmitter.on(name, handler);
    }

    callApplication(applicationId: string, options?: ApplicationCallOptions): ApplicationCall {
        this.validateApplicationId(applicationId);
        this.validateStatus(Status.CONNECTED, 'callApplication');
        this.checkIfAnythingIsActive();

        if (options?.customData) {
            ValidationUtil.validateCustomData(options.customData);
        }

        options ??= ApplicationCallOptions.builder().build();
        let call = new DefaultOutgoingApplicationCall(
            this.eventEmitter,
            this.gateway,
            this.logger,
            this.rtcConfig,
            this.device,
            applicationId,
            options,
            this.currentUser.identity,
            this.token,
            this.apiUrl
        );

        this.callActive = true;
        return <ApplicationCall>call;
    }

    callWebrtc(identity: string, options?: WebrtcCallOptions): WebrtcCall {
        if (!DefaultInfobipRTC.IDENTITY_PATTERN.test(identity)) {
            throw Error("Invalid destination.")
        }

        options ??= WebrtcCallOptions.builder().build();
        return new DefaultOutgoingWebrtcCall(this, options, this.currentUser.identity, identity);
    }

    callPhone(phoneNumber: string, phoneCallOptions?: PhoneCallOptions): PhoneCall {
        if (!DefaultInfobipRTC.PHONE_NUMBER_PATTERN.test(phoneNumber)) {
            throw new Error("Invalid destination");
        }

        phoneCallOptions ??= PhoneCallOptions.builder().build();
        return new DefaultOutgoingPhoneCall(this, phoneCallOptions, this.currentUser.identity, phoneNumber);
    }

    callViber(phoneNumber: string, from: string, viberCallOptions?: ViberCallOptions): ViberCall {
        if (!DefaultInfobipRTC.PHONE_NUMBER_PATTERN.test(phoneNumber)) {
            throw new Error("Invalid destination");
        }
        if (!DefaultInfobipRTC.PHONE_NUMBER_PATTERN.test(from)) {
            throw Error("Invalid caller identifier.")
        }

        viberCallOptions ??= ViberCallOptions.builder().build();
        return new DefaultOutgoingViberCall(this, viberCallOptions, this.currentUser.identity, phoneNumber, from);
    }

    joinRoom(roomName: string, options?: RoomCallOptions): RoomCall {
        if (!roomName || !DefaultInfobipRTC.CONFERENCE_ID_PATTERN.test(roomName)) {
            throw Error("Invalid room name.")
        }

        options ??= RoomCallOptions.builder().build();
        return new DefaultRoomCall(this, this.currentUser.identity, options, roomName);
    }

    debug(debug: boolean) {
        if (debug) {
            log.enableAll()
        } else {
            log.setLevel(log.levels.ERROR);
        }
    }

    getAudioInputDevices(): Promise<MediaDeviceInfo[]> {
        return this.device.getAudioInputDevices();
    }

    setAudioInputDevice(deviceId: string) {
        this.device.setAudioInputDevice(deviceId);
    }

    unsetAudioInputDevice(deviceId: string) {
        this.device.unsetAudioInputDevice(deviceId);
    }

    getAudioOutputDevices(): Promise<MediaDeviceInfo[]> {
        return this.device.getAudioOutputDevices();
    }

    getVideoInputDevices(): Promise<MediaDeviceInfo[]> {
        return this.device.getVideoInputDevices();
    }

    setVideoInputDevice(deviceId: string) {
        this.device.setVideoInputDevice(deviceId);
    }

    unsetVideoInputDevice(deviceId: string) {
        this.device.unsetVideoInputDevice(deviceId);
    }

    private validateApplicationId(applicationId: string) {
        if (!applicationId || typeof (applicationId) !== 'string' || applicationId.trim().length === 0) {
            throw new Error(`Invalid applicationId '${applicationId}'`);
        }
    }

    private validateStatus(requiredStatus: Status, methodName: string) {
        const currentStatus = this.gateway.status;
        if (requiredStatus !== currentStatus) {
            throw new Error(`Cannot ${methodName}, required status is ${StatusLabels.get(requiredStatus)}, current status is ${StatusLabels.get(currentStatus)}.`);
        }
    }

    private configureEventHandlers() {
        this.eventEmitter.once('registered', this.handleRegistered.bind(this));
        this.eventEmitter.on('registration_failed', this.handleDisconnect.bind(this));
        this.eventEmitter.once('disconnected', this.handleDisconnect.bind(this));
        this.eventEmitter.on('reconnecting', this.handleReconnecting.bind(this));
        this.eventEmitter.on('reconnected', this.handleReconnected.bind(this));
        this.eventEmitter.on('application_call', this.handleIncomingApplicationCall.bind(this));
        this.eventEmitter.on('call_finished', this.handleHangup.bind(this));
    }

    private handleRegistered(event: any) {
        if (event.iceServers) {
            this.rtcConfig.iceServers = event.iceServers;
        }
        let statsUrl = this.rtcOptions.statsUrl || event.apiUrl;

        this.apiUrl = event.apiUrl;
        this.logger.setOptions(event.loggerOptions ?? new LoggerOptions());
        this.logger.setLogLevel(event.logLevel);
        this.logger.setApiUrl(statsUrl);
        this.apiEventEmitter.emit(InfobipRTCEvent.CONNECTED, <ConnectedEvent>{identity: event.identity});
    }

    private handleIncomingApplicationCall(event: any) {
        if (this.callActive) {
            return this.apiEventEmitter.emit('call-error', <CallErrorEvent>{status: ApplicationErrorCode.BUSY});
        }

        let destination = this.currentUser.identity;
        this.callActive = true;

        if (event.applicationId === Properties.WEBRTC_APPLICATION_ID) {
            if (event.internalCustomData?.type === FlowType.WEBRTC.toString()) {
                let incomingCall = new DefaultIncomingWebrtcCall(
                    this.eventEmitter,
                    this.gateway,
                    this.logger,
                    this.rtcConfig,
                    this.device,
                    this.hasRemoteVideo(event.internalCustomData),
                    destination,
                    event.caller.identity,
                    event.description,
                    this.token,
                    this.apiUrl,
                    event.correlationId
                );
                this.apiEventEmitter.emit(InfobipRTCEvent.INCOMING_WEBRTC_CALL, new IncomingWebrtcCallEvent(incomingCall, event.customData));
            } else {
                this.logger.warn(`Received incoming basic call without matching flow type: ${JSON.stringify(event)}`);
            }
        } else {
            let incomingCall = new DefaultIncomingApplicationCall(this.eventEmitter, this.gateway, this.logger, this.rtcConfig,
                this.device, event.caller, event.applicationId, event.description, destination, this.token, this.apiUrl, event.correlationId);
            this.apiEventEmitter.emit(InfobipRTCEvent.INCOMING_APPLICATION_CALL, new IncomingApplicationCallEvent(incomingCall, event.customData));
        }
    }

    private hasRemoteVideo(customData: CustomData): boolean {
        return "true" === customData.isVideo;
    }

    private handleHangup(event: any) {
        this.callActive = false;
    }

    private handleReconnecting(event: any) {
        this.apiEventEmitter.emit(InfobipRTCEvent.RECONNECTING, <ReconnectingEvent>{});
    }

    private handleReconnected(event: any) {
        this.apiEventEmitter.emit(InfobipRTCEvent.RECONNECTED, <ReconnectedEvent>{});
    }

    private handleConnectionClosed(event?: CloseEvent) {
        if (this.callActive) {
            let hangupStatus: ErrorCode;
            if (event?.code === 4000) {
                hangupStatus = ApplicationErrorCode.NORMAL_HANGUP;
            } else {
                hangupStatus = ApplicationErrorCode.NETWORK_ERROR;
            }
            this.eventEmitter.emit('hangup', {status: hangupStatus});
        }
    }

    private handleDisconnect(event: any) {
        this.handleConnectionClosed(event);
        this.apiEventEmitter.emit(InfobipRTCEvent.DISCONNECTED, <DisconnectedEvent>{reason: event.reason});

        this.eventEmitter.removeAllListeners('registered');
        this.eventEmitter.removeAllListeners('registration_failed');
        this.eventEmitter.removeAllListeners('disconnected');
        this.eventEmitter.removeAllListeners('reconnecting');
        this.eventEmitter.removeAllListeners('reconnected');
        this.eventEmitter.removeAllListeners('application_call');
        this.eventEmitter.removeAllListeners('hangup');
        this.eventEmitter.removeAllListeners('call_finished');

        Object.values(InfobipRTCEvent).forEach(eventName => this.apiEventEmitter.removeAllListeners(eventName))
    }

    private isWebRTCSupported() {
        // @ts-ignore
        return RTCPeerConnection && (navigator.getUserMedia ||
            (navigator.mediaDevices && navigator.mediaDevices.getUserMedia));
    }

    private getUserFromToken(token: any) {
        let identity = token.identity;
        let displayName = token.name;
        return new User(identity, displayName);
    }

    private checkIfAnythingIsActive() {
        if (this.callActive) {
            throw Error('Call already in progress.');
        }
    }
}
