export enum WsEvent {
    // ice
    // TODO add trickle ice for audio, call video publisher, conference video publisher & subscriber
    TRICKLE_ICE = 'trickle_ice',
    // call
    RINGING = 'ringing',
    CALL_RESPONSE = 'call_response',
    CALL_ACCEPTED = 'call_accepted',
    HANGUP = 'hangup',
    CALL_ERROR = 'call-error',
    // call - video
    JOINED_VIDEO_CALL = 'joined_video_call',
    PUBLISHED_VIDEO_CALL = 'published_video_call',
    UNPUBLISHED_VIDEO_CALL = 'unpublished_video_call',
    JOIN_VIDEO_CALL_ERROR = 'join_video_call_error',
    PUBLISH_VIDEO_CALL_ERROR = 'publish_video_call_error',
    // conference
    JOINED_APPLICATION_CONFERENCE = 'joined_application_conference',
    PARTICIPANT_JOINING = 'participant_joining',
    PARTICIPANT_JOINED = 'participant_joined',
    PARTICIPANT_MEDIA_CHANGED = 'participant_media_changed',
    PARTICIPANT_STARTED_TALKING = 'participant_started_talking',
    PARTICIPANT_STOPPED_TALKING = 'participant_stopped_talking',
    PARTICIPANT_LEFT = 'participant_left',
    LEFT_APPLICATION_CONFERENCE = 'left_application_conference',
    // conference - video publisher
    JOINED_VIDEO_CONFERENCE = 'joined_video_conference',
    PUBLISHED_VIDEO_CONFERENCE = 'published_video_conference',
    UNPUBLISHED_VIDEO_CONFERENCE = 'unpublished_video_conference',
    PUBLISH_VIDEO_CONFERENCE_ERROR = 'publish_video_conference_error',
    JOIN_VIDEO_CONFERENCE_ERROR = 'join_video_conference_error',
    // conference - video subscriber
    // TODO rename events for subscribe?
    SUBSCRIBED_VIDEO = "subscribed_video",
    UPDATED_VIDEO = 'updated_video',
    SUBSCRIBE_VIDEO_CONFERENCE_ERROR = "subscribe_video_conference_error",
    // dialog
    DIALOG_CREATED = 'dialog_created',
    DIALOG_ESTABLISHED = 'dialog_established',
    DIALOG_FINISHED = 'dialog_finished',
    DIALOG_FAILED = 'dialog_failed',
    // data channel
    SETUP_DATA_CHANNEL = 'setup_data_channel',
    SETUP_DATA_CHANNEL_ERROR = 'setup_data_channel_error',
    PARTICIPANT_NETWORK_QUALITY = 'participant_network_quality'
}
