import {CallStats} from "../CallStats";
import {RemoteMediaStats} from "./RemoteMediaStats";
import {LocalMediaStats} from "./LocalMediaStats";
import {AudioLocalMediaStats} from "./audio/AudioLocalMediaStats";
import {AudioRemoteMediaStats} from "./audio/AudioRemoteMediaStats";

export type ExtractedRemoteStats = Map<string, RemoteMediaStats>;
export type ExtractedLocalStats = Map<string, LocalMediaStats>;

export class ExtractedStats {
    public trackId: string;

    constructor(
        public callStats: CallStats,
        public extractedRemoteStats: ExtractedRemoteStats,
        public extractedLocalStats: ExtractedLocalStats
    ) {
        this.callStats = callStats;
        this.extractedRemoteStats = extractedRemoteStats;
        this.extractedLocalStats = extractedLocalStats;
    }

    get localAudio(): AudioLocalMediaStats {
        const audioStats = Array.from(this.extractedLocalStats.values())
            .filter(e => !e.video);
        return <AudioLocalMediaStats>audioStats[0];
    }

    get remoteAudio(): AudioRemoteMediaStats {
        const audioStats = Array.from(this.extractedRemoteStats.values())
            .filter(e => !e.video);
        return <AudioRemoteMediaStats>audioStats[0];
    }

    public toJSON(): any {
        return {
            trackId: this.trackId,
            callStats: this.callStats.toJSON()
        };
    }
}