import {Logger} from "../Logger";
import {NetworkQualityStatistics} from "./media/NetworkQualityStatistics";
import {SelectedCandidatePair} from "./transport/SelectedCandidatePair";
import {MediaStatsAdapter} from "./media/MediaStatsAdapter";
import {CallMediaStatsLog, SelectedIceCandidatePairLog} from "../Log";
import {ExtractedStats} from "./media/ExtractedStats";
import {MediaStatsDiffUtil} from "./media/MediaStatsDiffUtil";
import {RemoteMediaStats} from "./media/RemoteMediaStats";
import {LocalMediaStats} from "./media/LocalMediaStats";
import {TotalMediaStats} from "../../call/stats/TotalMediaStats";
import {AudioStats} from "../../call/stats/AudioStats";
import {AudioRemoteMediaStats} from "./media/audio/AudioRemoteMediaStats";
import {AudioLocalMediaStats} from "./media/audio/AudioLocalMediaStats";
import {CurrentMediaStats} from "../../call/stats/CurrentMediaStats";

export type NetworkQualityStatisticsCallback = (networkQualityStatistics: NetworkQualityStatistics, currentMediaStats: CurrentMediaStats) => void;

export class PeerConnectionMediaMonitor {
    protected static MONITOR_ICE_STATES = new Set(["connected", "completed"]);
    protected static DEFAULT_MONITOR_SUMMARY_INTERVAL = 1_000; // TODO get from portunus
    protected static DEFAULT_MONITOR_TRACK_INTERVAL = 10_000; // TODO get from portunus

    protected readonly summaryInterval: any;
    protected readonly trackMonitoringInterval: any;
    protected selectedCandidatePair: SelectedCandidatePair;
    protected networkQualityStatisticsListener: NetworkQualityStatisticsCallback;

    private readonly previousConferenceStats: Map<string, ExtractedStats> = new Map<string, ExtractedStats>();

    constructor(public callId: string,
                private name: string,
                public conferenceId: string,
                private pc: RTCPeerConnection,
                private isVideo: boolean,
                private logger: Logger) {
        this.summaryInterval = setInterval(() => this.sendSummaryStats(), PeerConnectionMediaMonitor.DEFAULT_MONITOR_SUMMARY_INTERVAL);
        this.trackMonitoringInterval = setInterval(() => this.sendTrackStats(), PeerConnectionMediaMonitor.DEFAULT_MONITOR_TRACK_INTERVAL);
    }

    setIsVideo(isVideo: boolean) {
        this.isVideo = isVideo;
    }

    stop(): TotalMediaStats {
        let totalMediaStats = this.sendTotalCallStats();
        clearInterval(this.summaryInterval);
        clearInterval(this.trackMonitoringInterval);
        return totalMediaStats;
    }

    onNetworkQualityStatistics(callback: NetworkQualityStatisticsCallback) {
        this.networkQualityStatisticsListener = callback;
    }

    protected sendSelectedCandidatePair(rtcStatsReport: RTCStatsReport) {
        if (!this.selectedCandidatePair) {
            let selectedCandidatePair = MediaStatsAdapter.extractSelectedCandidatePair(rtcStatsReport);
            if (selectedCandidatePair) {
                this.logger.log((new SelectedIceCandidatePairLog(this.callId, selectedCandidatePair)));
                this.selectedCandidatePair = selectedCandidatePair;
            }
        }
    }


    protected sendTotalCallStats(): TotalMediaStats {
        let totalMediaStats = new TotalMediaStats();
        this.previousConferenceStats.forEach((callStats: ExtractedStats, trackId: string) => {
            if (trackId) {
                if (!this.isVideo) {
                    this.fillAudioStats(callStats, totalMediaStats.totalAudioStats);
                }
                this.sendExtractedStats(callStats, trackId, "Total");
            } else {
                if (!this.isVideo) {
                    totalMediaStats.totalAudioStats.roundTripTime = callStats.callStats.currentRoundTripTime ?? 0
                }
                this.sendExtractedMediaStats(
                    callStats,
                    callStats.callStats.timestamp,
                    null,
                    "Total"
                );
            }
        });
        return totalMediaStats;
    }

    protected sendTrackStats() {
        if (!this.callId && !this.conferenceId) {
            return;
        }
        if (!this.pc || !PeerConnectionMediaMonitor.MONITOR_ICE_STATES.has(this.pc.iceConnectionState)) {
            return;
        }

        this.pc.getSenders().forEach(sender => this.sendStatsForTrack(sender.track));
        this.pc.getReceivers().forEach(receiver => this.sendStatsForTrack(receiver.track));
    }

    protected sendSummaryStats() {
        if (!this.callId && !this.conferenceId) {
            return;
        }
        if (!this.pc || !PeerConnectionMediaMonitor.MONITOR_ICE_STATES.has(this.pc.iceConnectionState)) {
            return;
        }

        this.sendSummaryStatsForPeerConnection();
    }

    private sendStatsForTrack(track: MediaStreamTrack) {
        if (!track) {
            return;
        }

        const trackId = track?.id;
        const trackLabel = track?.label || (this.name + "_" + trackId);
        this.sendStatsForLabeledTrack(track, trackLabel || "unknown");
    }

    private emitNetworkQualityStats(previousCallStats: ExtractedStats, diffStats: ExtractedStats) {
        const remoteAudioStats = diffStats.remoteAudio;
        if (!remoteAudioStats) {
            return;
        }
        const localAudioStats = diffStats.localAudio;
        if (!localAudioStats) {
            return;
        }
        if (!diffStats.callStats.currentRoundTripTime) {
            return;
        }

        const networkQualityStatistics = NetworkQualityStatistics.forRTCStats(
            diffStats.callStats.currentRoundTripTime,
            remoteAudioStats.jitter,
            remoteAudioStats.packetsReceived,
            remoteAudioStats.packetsLost,
            remoteAudioStats.codec || localAudioStats.codec
        );

        if (previousCallStats || networkQualityStatistics.mos !== 1.0) {
            diffStats.callStats.mos = networkQualityStatistics.mos;

            if (this.networkQualityStatisticsListener) {
                let currentMediaStats = this.getCurrentMediaStats(diffStats);
                this.networkQualityStatisticsListener(networkQualityStatistics, currentMediaStats);
            }
        }
    }

    private getCurrentMediaStats(diffStats: ExtractedStats): CurrentMediaStats {
        let currentMediaStats = new CurrentMediaStats();
        let currentAudioStats = new AudioStats();

        currentAudioStats.roundTripTime = diffStats.callStats.currentRoundTripTime ?? 0
        currentAudioStats.jitter = diffStats.remoteAudio.jitter
        currentAudioStats.codec = diffStats.remoteAudio.codec ?? diffStats.localAudio.codec!

        currentAudioStats.packetsLost = diffStats.remoteAudio.packetsLost
        currentAudioStats.packetsReceived = diffStats.remoteAudio.packetsReceived
        currentAudioStats.bytesReceived = diffStats.remoteAudio.bytesReceived

        currentAudioStats.packetsSent = diffStats.localAudio.packetsSent
        currentAudioStats.retransmittedPacketsSent = diffStats.localAudio.retransmittedPacketsSent
        currentAudioStats.bytesSent = diffStats.localAudio.bytesSent
        currentAudioStats.retransmittedBytesSent = diffStats.localAudio.retransmittedBytesSent

        currentMediaStats.currentAudioStats = currentAudioStats
        return currentMediaStats
    }

    private sendSummaryStatsForPeerConnection() {
        this.pc.getStats()
            .then(rtcStatsReport => {
                let currentCallStats = MediaStatsAdapter.extract(rtcStatsReport, this.isVideo);
                let previousCallStats = this.previousConferenceStats.get(null);
                let diffCallStats = MediaStatsDiffUtil.diff(previousCallStats, currentCallStats, this.isVideo);

                this.emitNetworkQualityStats(previousCallStats, diffCallStats);
                this.sendExtractedMediaStats(
                    diffCallStats,
                    diffCallStats.callStats.timestamp,
                    null,
                    this.name);

                this.previousConferenceStats.set(null, currentCallStats);
                this.sendSelectedCandidatePair(rtcStatsReport);
            });
    }

    private sendStatsForLabeledTrack(track: MediaStreamTrack, label: string) {
        this.pc.getStats(track)
            .then(rtcStatsReport => {
                let currentCallStats = MediaStatsAdapter.extract(rtcStatsReport, this.isVideo);
                let previousCallStats = this.previousConferenceStats.get(track.id);
                let diffCallStats = MediaStatsDiffUtil.diff(previousCallStats, currentCallStats, this.isVideo);

                this.sendExtractedStats(diffCallStats, track.id, label);

                this.previousConferenceStats.set(track.id, currentCallStats);
                this.sendSelectedCandidatePair(rtcStatsReport);
            });
    }

    private sendExtractedMediaStats(data: ExtractedStats | RemoteMediaStats | LocalMediaStats,
                                    timestamp: number,
                                    trackId: string,
                                    label: string) {
        data.trackId = trackId || data["id"];
        this.logger.log(new CallMediaStatsLog(label, this.conferenceId, this.callId, this.name, data, timestamp));
    }

    private sendExtractedStats(diffCallStats: ExtractedStats, trackId: string, label: string) {
        diffCallStats.extractedRemoteStats.forEach(remoteStats =>
            this.sendExtractedMediaStats(remoteStats, diffCallStats.callStats.timestamp, trackId, label));
        diffCallStats.extractedLocalStats.forEach(localStats =>
            this.sendExtractedMediaStats(localStats, diffCallStats.callStats.timestamp, trackId, label));
    }

    private fillAudioStats(stats: ExtractedStats, audioStats: AudioStats) {
        let audioLocalMediaStats = stats.localAudio;
        if (audioLocalMediaStats != null) {
            this.fillLocalAudioStats(audioStats, audioLocalMediaStats);
        }
        let audioRemoteMediaStats = stats.remoteAudio;
        if (audioRemoteMediaStats != null) {
            this.fillRemoteAudioStats(audioStats, audioRemoteMediaStats);
        }
    }

    private fillRemoteAudioStats(audioStats: AudioStats, audioRemoteMediaStats: AudioRemoteMediaStats) {
        if (audioStats.codec == null) {
            audioStats.codec = audioRemoteMediaStats.codec;
        }
        audioStats.bytesReceived = audioRemoteMediaStats.bytesReceived;
        audioStats.packetsReceived = audioRemoteMediaStats.packetsReceived;
        audioStats.packetsLost = audioRemoteMediaStats.packetsLost;
        audioStats.jitter = audioRemoteMediaStats.jitter;
    }

    private fillLocalAudioStats(audioStats: AudioStats, audioLocalMediaStats: AudioLocalMediaStats) {
        if (audioStats.codec == null) {
            audioStats.codec = audioLocalMediaStats.codec;
        }
        audioStats.bytesSent = audioLocalMediaStats.bytesSent;
        audioStats.packetsSent = audioLocalMediaStats.packetsSent;
        audioStats.retransmittedBytesSent = audioLocalMediaStats.retransmittedBytesSent;
        audioStats.retransmittedPacketsSent = audioLocalMediaStats.retransmittedPacketsSent;
    }
}