export interface AcquiredLock {
    release(): void;
}

export class AsyncLock {
    private taskQueue: (() => Promise<any>)[] = [];
    private active = false;

    public acquireLock(): Promise<AcquiredLock> {
        return new Promise(lockResolve => {
            this.withLock(() => {
                return new Promise((resolve) => {
                    lockResolve({
                        release: () => {
                            resolve(null)
                        }
                    });
                });
            });
        });
    }

    public withLock<T>(action: () => Promise<T>): Promise<T> {
        let deferredResolve: (value: T) => void;
        let deferredReject: (reason?: any) => void;
        const deferred: Promise<T> = new Promise((resolve, reject) => {
            deferredResolve = resolve;
            deferredReject = reject;
        });
        const executeTask = async () => {
            await action().then(deferredResolve, deferredReject);
            if (this.taskQueue.length > 0) {
                this.taskQueue.shift()();
            } else {
                this.active = false;
            }
        };
        if (this.active) {
            this.taskQueue.push(executeTask);
        } else {
            this.active = true;
            executeTask();
        }
        return deferred;
    }
}