import {CallOptions} from "../../options/CallOptions";
import {RoomCallOptions} from "../../options/RoomCallOptions";

type RecordingOptionsJson = { [key: string]: string }

export function getRecordingOptions(options: CallOptions | RoomCallOptions): RecordingOptionsJson | null {
    const recordingType = options?.recordingOptions?.recordingType;
    if (recordingType && recordingType !== "UNDEFINED") {
        return {
            recordingOptions: JSON.stringify(options?.recordingOptions)
        }
    }
    return null;
}
