import {RemoteMediaStats} from "../RemoteMediaStats";

export class VideoRemoteMediaStats extends RemoteMediaStats {
    public frameWidth: number;
    public frameHeight: number;
    public framesReceived: number = 0;
    public framesDecoded: number = 0;
    public keyFramesDecoded: number = 0;
    public framesDropped: number = 0;
    public nackCount: number = 0;
    public firCount: number = 0;
    public pliCount: number = 0;
    public qpSum: number = 0;
    public averageQP: number = 0;

    constructor(id: string) {
        super(id);
    }

    get video(): boolean {
        return true;
    }

    public toJSON() {
        return {
            ...super.toJSON(),
            frameWidth: this.frameWidth,
            frameHeight: this.frameHeight,
            framesReceived: this.framesReceived,
            framesDecoded: this.framesDecoded,
            keyFramesDecoded: this.keyFramesDecoded,
            framesDropped: this.framesDropped,
            nackCount: this.nackCount,
            firCount: this.firCount,
            pliCount: this.pliCount,
            averageQP: this.averageQP
        };
    }
}
