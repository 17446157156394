import {CallStats} from "../CallStats";
import {RemoteMediaStats} from "./RemoteMediaStats";
import {LocalMediaStats} from "./LocalMediaStats";
import {AudioRemoteMediaStats} from "./audio/AudioRemoteMediaStats";
import {VideoRemoteMediaStats} from "./video/VideoRemoteMediaStats";
import {VideoLocalMediaStats} from "./video/VideoLocalMediaStats";
import {SelectedCandidatePair} from "../transport/SelectedCandidatePair";
import {IceCandidate} from "../transport/IceCandidate";
import {AudioLocalMediaStats} from "./audio/AudioLocalMediaStats";
import {ExtractedLocalStats, ExtractedRemoteStats, ExtractedStats} from "./ExtractedStats";

export class MediaStatsAdapter {
    public static extract(rtcStatsReport: any, isVideo: boolean): ExtractedStats {
        let callStats = new CallStats(isVideo);
        let selectedCandidatePair = null;
        const extractedRemoteStats: ExtractedRemoteStats = new Map<string, RemoteMediaStats>();
        const extractedLocalStats: ExtractedLocalStats = new Map<string, LocalMediaStats>();

        rtcStatsReport.forEach((statsReport: any) => {
            if (statsReport.type === "transport" && statsReport.dtlsState === "connected") {
                selectedCandidatePair = rtcStatsReport.get(statsReport.selectedCandidatePairId);
            } else if (statsReport.type === "inbound-rtp") {
                const inboundRtp = <RTCInboundRtpStreamStats>statsReport;
                const stats = MediaStatsAdapter.extractInboundRTPStats(inboundRtp);
                MediaStatsAdapter.populateCodec(inboundRtp, rtcStatsReport, stats);
                extractedRemoteStats.set(statsReport.id, stats);
            } else if (statsReport.type === "outbound-rtp") {
                const outboundRtp = <RTCOutboundRtpStreamStats>statsReport;
                const stats = MediaStatsAdapter.extractOutboundRTPStats(outboundRtp);
                MediaStatsAdapter.populateCodec(outboundRtp, rtcStatsReport, stats);
                extractedLocalStats.set(stats.id, stats);
            } else if (statsReport.type === "remote-inbound-rtp") {
                // RTT on Firefox
                MediaStatsAdapter.extractRTTIfMissing(callStats, statsReport);
            }
        });

        if (!selectedCandidatePair) {
            // Fallback for Firefox.
            selectedCandidatePair = MediaStatsAdapter.getSelectedCandidatePair(rtcStatsReport);
        }
        if (selectedCandidatePair) {
            MediaStatsAdapter.extractTransportMetrics(callStats, selectedCandidatePair);
        }

        return new ExtractedStats(callStats, extractedRemoteStats, extractedLocalStats);
    }

    private static populateCodec(statsReport: RTCInboundRtpStreamStats,
                                 rtcStatsReport: any,
                                 stats: RemoteMediaStats | LocalMediaStats) {
        const codecId = statsReport.codecId;
        const codecStatsReport = !codecId ? MediaStatsAdapter.extractCodecStatsReport(rtcStatsReport) : rtcStatsReport.get(codecId);

        if (!codecStatsReport) {
            return;
        }

        if (stats.video) {
            stats.codec = MediaStatsAdapter.extractVideoCodec(codecStatsReport);
        } else {
            stats.codec = MediaStatsAdapter.extractAudioCodec(codecStatsReport);
        }
    }

    public static extractSelectedCandidatePair(rtcStatsReport: any): SelectedCandidatePair {
        let selectedCandidatePair = null;

        rtcStatsReport.forEach((statsReport: any) => {
            if (statsReport.type === "transport" && statsReport.selectedCandidatePairId) {
                selectedCandidatePair = rtcStatsReport.get(statsReport.selectedCandidatePairId);
            }
        });

        if (!selectedCandidatePair) {
            // Fallback for Firefox.
            selectedCandidatePair = MediaStatsAdapter.getSelectedCandidatePair(rtcStatsReport);
        }

        if (!selectedCandidatePair) {
            return null;
        }
        let localCandidate = rtcStatsReport.get(selectedCandidatePair.localCandidateId);
        let remoteCandidate = rtcStatsReport.get(selectedCandidatePair.remoteCandidateId);
        let local = new IceCandidate(localCandidate);
        let remote = new IceCandidate(remoteCandidate);
        return new SelectedCandidatePair(local, remote);
    }

    private static getSelectedCandidatePair(stats: any): any {
        let selectedCandidatePair = null;

        stats.forEach((statsReport: any) => {
            if (statsReport.type === 'candidate-pair' && statsReport.selected) {
                selectedCandidatePair = statsReport;
            }
        });

        return selectedCandidatePair;
    }

    private static extractInboundRTPStats(statsReport: RTCInboundRtpStreamStats): RemoteMediaStats {
        let stats: RemoteMediaStats;
        if (statsReport.kind === "audio") {
            stats = MediaStatsAdapter.extractAudioRemoteMediaStats(statsReport);
            MediaStatsAdapter.extractCommonRemoteMediaStats(stats, statsReport);
        } else {
            stats = MediaStatsAdapter.extractVideoRemoteMediaStats(statsReport);
            MediaStatsAdapter.extractCommonRemoteMediaStats(stats, statsReport);
        }
        return stats;
    }

    private static extractOutboundRTPStats(statsReport: RTCOutboundRtpStreamStats): LocalMediaStats {
        let stats: LocalMediaStats;
        if (statsReport.kind === "audio") {
            stats = new AudioLocalMediaStats(statsReport.id);
            MediaStatsAdapter.extractCommonLocalMediaStats(stats, statsReport);
        } else {
            stats = MediaStatsAdapter.extractVideoLocalMediaStats(statsReport);
            MediaStatsAdapter.extractCommonLocalMediaStats(stats, statsReport);
        }
        return stats;
    }

    private static extractCommonRemoteMediaStats(remoteMediaStats: RemoteMediaStats, statsReport: RTCInboundRtpStreamStats) {
        remoteMediaStats.packetsReceived = statsReport.packetsReceived;
        remoteMediaStats.packetsLost = statsReport.packetsLost;
        remoteMediaStats.bytesReceived = statsReport.bytesReceived;
        MediaStatsAdapter.extractAverageJitterBufferDelay(remoteMediaStats, statsReport);
    }

    private static extractCommonLocalMediaStats(localMediaStats: LocalMediaStats, statsReport: RTCOutboundRtpStreamStats) {
        localMediaStats.bytesSent = statsReport.bytesSent;
        localMediaStats.packetsSent = statsReport.packetsSent;
        localMediaStats.retransmittedBytesSent = statsReport.retransmittedBytesSent;
        localMediaStats.retransmittedPacketsSent = statsReport.retransmittedPacketsSent;
    }

    private static extractAudioRemoteMediaStats(statsReport: RTCInboundRtpStreamStats): AudioRemoteMediaStats {
        const audioRemoteMediaStats = new AudioRemoteMediaStats(statsReport.id);
        audioRemoteMediaStats.jitter = statsReport.jitter * 1000;
        audioRemoteMediaStats.concealedSamples = statsReport.concealedSamples;
        audioRemoteMediaStats.concealmentEvents = statsReport.concealmentEvents;
        return audioRemoteMediaStats;
    }

    private static extractVideoRemoteMediaStats(statsReport: any): VideoRemoteMediaStats {
        const videoRemoteMediaStats: VideoRemoteMediaStats = new VideoRemoteMediaStats(statsReport.id);
        videoRemoteMediaStats.frameWidth = statsReport.frameWidth;
        videoRemoteMediaStats.frameHeight = statsReport.frameHeight;
        videoRemoteMediaStats.framesReceived = statsReport.framesReceived;
        videoRemoteMediaStats.framesDropped = statsReport.framesDropped;
        videoRemoteMediaStats.framesDecoded = statsReport.framesDecoded;
        videoRemoteMediaStats.keyFramesDecoded = statsReport.keyFramesDecoded;
        videoRemoteMediaStats.nackCount = statsReport.nackCount;
        videoRemoteMediaStats.firCount = statsReport.firCount;
        videoRemoteMediaStats.pliCount = statsReport.pliCount;
        videoRemoteMediaStats.qpSum = statsReport.qpSum;
        if (videoRemoteMediaStats.framesDecoded > 0) {
            videoRemoteMediaStats.averageQP = Math.round(videoRemoteMediaStats.qpSum / videoRemoteMediaStats.framesDecoded);
        }
        return videoRemoteMediaStats;
    }

    private static extractVideoLocalMediaStats(statsReport: any): VideoLocalMediaStats {
        const videoLocalMediaStats = new VideoLocalMediaStats(statsReport.id);
        videoLocalMediaStats.frameWidth = statsReport.frameWidth;
        videoLocalMediaStats.frameHeight = statsReport.frameHeight;
        videoLocalMediaStats.framesSent = statsReport.framesSent;
        videoLocalMediaStats.framesEncoded = statsReport.framesEncoded;
        videoLocalMediaStats.keyFramesEncoded = statsReport.keyFramesEncoded;
        videoLocalMediaStats.nackCount = statsReport.nackCount;
        videoLocalMediaStats.firCount = statsReport.firCount;
        videoLocalMediaStats.pliCount = statsReport.pliCount;
        videoLocalMediaStats.qpSum = statsReport.qpSum;
        if (videoLocalMediaStats.framesEncoded > 0) {
            videoLocalMediaStats.averageQP = Math.round(videoLocalMediaStats.qpSum / videoLocalMediaStats.framesEncoded);
        }
        videoLocalMediaStats.qualityLimitationReason = statsReport.qualityLimitationReason;
        return videoLocalMediaStats;
    }

    private static extractAverageJitterBufferDelay(remoteMediaStats: RemoteMediaStats, statsReport: any) {
        remoteMediaStats.jitterBufferDelay = statsReport.jitterBufferDelay;
        remoteMediaStats.jitterBufferEmittedCount = statsReport.jitterBufferEmittedCount;
        if (remoteMediaStats.jitterBufferEmittedCount > 0) {
            remoteMediaStats.averageJitterBufferDelay = Math.round(remoteMediaStats.jitterBufferDelay / remoteMediaStats.jitterBufferEmittedCount);
        }
        return remoteMediaStats;
    }

    private static extractTransportMetrics(callStats: CallStats, statsReport: any) {
        callStats.bytesReceived = statsReport.bytesReceived;
        callStats.bytesSent = statsReport.bytesSent;
        callStats.availableOutgoingBitrate = statsReport.availableOutgoingBitrate;
        if (statsReport.currentRoundTripTime) {
            callStats.currentRoundTripTime = statsReport.currentRoundTripTime * 1000;
        }
    }

    private static extractRTTIfMissing(callStats: CallStats, statsReport: any) {
        if (!callStats.currentRoundTripTime) {
            callStats.currentRoundTripTime = statsReport.roundTripTime * 1000;
        }
    }

    private static extractCodecStatsReport(rtcStatsReport: any): any {
        return Array.from(rtcStatsReport.values()).find((statsReport: any) => {
            return statsReport.type === "codec";
        });
    }

    private static extractAudioCodec(codecStatsReport: any): string {
        let codec = codecStatsReport.mimeType;
        if (codec) {
            return codec.toLowerCase().replace("audio/", "");
        }
        return null;
    }

    private static extractVideoCodec(codecStatsReport: any): string {
        let codec = codecStatsReport.mimeType;
        if (codec) {
            return codec.toLowerCase().replace("video/", "");
        }
        return null;
    }
}
