import {NetworkQualityStatistics} from "../../../log/monitor/media/NetworkQualityStatistics";
import * as log from "loglevel";

export class NetworkQualityMonitor {
    private readonly OBSERVE_INTERVAL = 5;
    private previousQuality = NetworkQualityStatistics.forMos(5);
    private currentQuality = NetworkQualityStatistics.forMos(5);
    private qualityMeasurementsBuffer: NetworkQualityStatistics[] = [];

    observe(networkQualityStatistics: NetworkQualityStatistics) {
        let newMos = networkQualityStatistics.mos;
        if (newMos == null || newMos <= 0 || newMos > 5) {
            return log.warn(`Invalid MOS value: ${newMos} for statistics: ${JSON.stringify(networkQualityStatistics)}`);
        }
        if (this.isQualityMeasurementsBufferFull()) {
            this.qualityMeasurementsBuffer = [];
        }

        this.qualityMeasurementsBuffer.push(networkQualityStatistics);

        if (this.isQualityMeasurementsBufferFull()) {
            this.previousQuality = this.currentQuality;
            this.currentQuality = NetworkQualityStatistics.forMos(this.getCurrentMos());
        }
    }

    current(): NetworkQualityStatistics {
        return this.currentQuality;
    }

    localNetworkChanged(): boolean {
        if (this.observationInProgress()) {
            return false;
        }
        return this.previousQuality.networkQuality !== this.currentQuality.networkQuality;
    }

    remoteNetworkChanged(): boolean {
        if (this.observationInProgress()) {
            return false;
        }
        return (this.currentQuality.mos <= 3.6 && this.previousQuality.mos > 3.6) ||
            (this.currentQuality.mos > 3.6 && this.previousQuality.mos <= 3.6)
    }

    private isQualityMeasurementsBufferFull() {
        return this.qualityMeasurementsBuffer.length === this.OBSERVE_INTERVAL;
    }

    private getCurrentMos() {
        let allIntervalsMosSum = this.qualityMeasurementsBuffer
            .map(networkQualityStatistic => networkQualityStatistic.mos)
            .reduce((a, b) => a + b);
        return Math.round((allIntervalsMosSum / this.OBSERVE_INTERVAL + Number.EPSILON) * 100) / 100;
    }

    private observationInProgress() {
        return this.qualityMeasurementsBuffer.length < this.OBSERVE_INTERVAL;
    }
}
