import {CustomData} from "../CustomDataType";
import {FlowType} from "../FlowType";
import {RoomCall} from "../RoomCall";
import {RoomCallOptions} from "../options/RoomCallOptions";
import {CallsEventHandlers} from "../event/CallsEventHandlers";
import {CameraOrientation} from "../options/CameraOrientation";
import {CallStatus} from "../CallStatus";
import {VideoFilter} from "../options/filters/VideoFilter";
import {AudioFilter} from "../options/filters/AudioFilter";
import {ApiEventEmitter} from "../../util/ApiEventEmitter";
import {AnyCallsApiEvent, CallsApiEvent, CallsApiEvents} from "../event/CallsApiEvents";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import {ApplicationCall} from "../ApplicationCall";
import {LocalCapturer} from "../LocalCapturer";
import {ServerCapturer} from "../ServerCapturer";
import ValidationUtil from "./util/ValidationUtil";
import {InfobipRTC} from "../../InfobipRTC";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";
import {DataChannel} from "../DataChannel";
import {AudioQualityMode} from "../options/AudioQualityMode";
import {getRecordingOptions} from "./util/CallOptionsUtil";
import RoomJoinedEvent = CallsApiEvents.RoomJoinedEvent;
import ConferenceJoinedEvent = CallsApiEvents.ConferenceJoinedEvent;
import EarlyMediaEvent = CallsApiEvents.EarlyMediaEvent;
import HangupEvent = CallsApiEvents.HangupEvent;
import ErrorEvent = CallsApiEvents.ErrorEvent;
import CameraVideoAddedEvent = CallsApiEvents.CameraVideoAddedEvent;
import CameraVideoUpdatedEvent = CallsApiEvents.CameraVideoUpdatedEvent;
import ScreenShareAddedEvent = CallsApiEvents.ScreenShareAddedEvent;
import ParticipantJoiningEvent = CallsApiEvents.ParticipantJoiningEvent;
import ParticipantJoinedEvent = CallsApiEvents.ParticipantJoinedEvent;
import ParticipantMutedEvent = CallsApiEvents.ParticipantMutedEvent;
import ParticipantUnmutedEvent = CallsApiEvents.ParticipantUnmutedEvent;
import ParticipantDeafEvent = CallsApiEvents.ParticipantDeafEvent;
import ParticipantUndeafEvent = CallsApiEvents.ParticipantUndeafEvent;
import ParticipantStartedTalkingEvent = CallsApiEvents.ParticipantStartedTalkingEvent;
import ParticipantStoppedTalkingEvent = CallsApiEvents.ParticipantStoppedTalkingEvent;
import ParticipantCameraVideoAddedEvent = CallsApiEvents.ParticipantCameraVideoAddedEvent;
import ParticipantCameraVideoRemovedEvent = CallsApiEvents.ParticipantCameraVideoRemovedEvent;
import ParticipantScreenShareAddedEvent = CallsApiEvents.ParticipantScreenShareAddedEvent;
import ParticipantScreenShareRemovedEvent = CallsApiEvents.ParticipantScreenShareRemovedEvent;
import ParticipantRemovedEvent = CallsApiEvents.ParticipantRemovedEvent;
import NetworkQualityChangedEvent = CallsApiEvents.NetworkQualityChangedEvent;
import ParticipantNetworkQualityChangedEvent = CallsApiEvents.ParticipantNetworkQualityChangedEvent;
import CallReconnectedEvent = CallsApiEvents.ReconnectedEvent;
import RoomRejoinedEvent = CallsApiEvents.RoomRejoinedEvent;
import RoomRejoiningEvent = CallsApiEvents.RoomRejoiningEvent;
import {DisplayOptions} from "../options/DisplayOptions";

export class DefaultRoomCall implements RoomCall {
    private applicationCall: ApplicationCall;
    private apiEventEmitter: ApiEventEmitter;
    private roomJoinTime: Date;

    private reconnectedEvent: CallsApiEvents.ReconnectedEvent = null;
    private establishedEvent: CallsApiEvents.EstablishedEvent = null;
    private conferenceJoinedEvent: CallsApiEvents.ConferenceJoinedEvent = null;

    constructor(infobipRtc: InfobipRTC,
                currentUserIdentity: string,
                private roomOptions: RoomCallOptions,
                private roomName: string) {
        this.apiEventEmitter = new ApiEventEmitter();
        let applicationCallOptions = this.mapOptions(this.roomName, this.roomOptions);
        this.applicationCall = infobipRtc.callApplication(roomName, applicationCallOptions);
        if (roomOptions?.autoRejoin) {
            this.applicationCall.setReconnectHandler(this.createCustomDataForReconnect.bind(this));
        }
        this.initEventHandlers();
    }

    private mapOptions(roomName: string, roomOptions: RoomCallOptions): ApplicationCallOptions {
        if (roomOptions?.customData) {
            ValidationUtil.validateCustomData(roomOptions?.customData);
        }

        let internalCustomData: CustomData = {
            type: FlowType.ROOM.toString(),
            roomName: roomName,
            ...getRecordingOptions(roomOptions),
        };

        let applicationCallOptions = ApplicationCallOptions.builder()
            .setAudio(roomOptions?.audio)
            .setAudioOptions(roomOptions?.audioOptions)
            .setVideo(roomOptions?.video)
            .setVideoOptions(roomOptions?.videoOptions)
            .setDataChannel(roomOptions?.dataChannel)
            .build();

        if (roomOptions?.customData) {
            applicationCallOptions.customData = roomOptions?.customData
        }

        return new InternalApplicationCallOptions(
            applicationCallOptions,
            internalCustomData
        );
    }

    name(): string {
        return this.roomName;
    }

    cameraOrientation(): CameraOrientation {
        return this.applicationCall.cameraOrientation();
    }

    cameraVideo(localVideo: boolean): Promise<void> {
        return this.applicationCall.cameraVideo(localVideo);
    }

    duration(): number {
        return this.joinTime == null ? 0 : this.getDurationInSeconds(this.leaveTime() != null ? this.leaveTime() : new Date());
    }

    joinTime(): Date {
        return this.roomJoinTime;
    }

    leaveTime(): Date {
        return this.applicationCall.endTime();
    }

    leave(): void {
        return this.applicationCall.hangup();
    }

    hasCameraVideo(): boolean {
        return this.applicationCall.hasCameraVideo();
    }

    hasScreenShare(): boolean {
        return this.applicationCall.hasScreenShare();
    }

    id(): string {
        return this.applicationCall.id();
    }

    muted(): boolean {
        return this.applicationCall.muted();
    }

    on(name: AnyCallsApiEvent, handler: CallsEventHandlers.Any): void {
        if (!Object.values(CallsApiEvent)
            .find(apiEvent => apiEvent === name)) {
            throw new Error(`Unknown event: ${name}!`);
        }
        this.apiEventEmitter.on(name, handler);
    }

    options(): RoomCallOptions {
        return this.roomOptions;
    }

    customData(): CustomData {
        return this.options().customData;
    }

    pauseIncomingVideo(): void {
        this.applicationCall.pauseIncomingVideo();
    }

    resumeIncomingVideo(): void {
        this.applicationCall.resumeIncomingVideo();
    }

    screenShare(screenShare: boolean): Promise<void> {
        return this.applicationCall.screenShare(screenShare);
    }

    startScreenShare(displayOptions?: DisplayOptions): Promise<void> {
        return this.applicationCall.startScreenShare(displayOptions);
    }

    stopScreenShare(): Promise<void> {
        return this.applicationCall.stopScreenShare();
    }

    sendDTMF(dtmf: string): Promise<void> {
        return this.applicationCall.sendDTMF(dtmf);
    }

    audioFilter(): AudioFilter {
        return this.applicationCall.audioFilter();
    }

    setAudioFilter(audioFilter: AudioFilter): Promise<void> {
        return this.applicationCall.setAudioFilter(audioFilter);
    }

    clearAudioFilter(): Promise<void> {
        return this.applicationCall.clearAudioFilter();
    }

    setAudioInputDevice(deviceId: string): Promise<void> {
        return this.applicationCall.setAudioInputDevice(deviceId);
    }

    setCameraOrientation(cameraOrientation: CameraOrientation): Promise<void> {
        return this.applicationCall.setCameraOrientation(cameraOrientation);
    }

    localCapturer(): LocalCapturer {
        return this.applicationCall.localCapturer();
    }

    serverCapturer(): ServerCapturer {
        return this.applicationCall.serverCapturer();
    }

    dataChannel(): DataChannel {
        return this.applicationCall.dataChannel();
    }

    setAudioQualityMode(audioQualityMode: AudioQualityMode): void {
        return this.applicationCall.setAudioQualityMode(audioQualityMode);
    }

    audioQualityMode(): AudioQualityMode {
        return this.applicationCall.audioQualityMode();
    }

    videoFilter(): VideoFilter {
        return this.applicationCall.videoFilter();
    }

    setVideoFilter(videoFilter: VideoFilter): Promise<void> {
        return this.applicationCall.setVideoFilter(videoFilter);
    }

    clearVideoFilter(): Promise<void> {
        return this.applicationCall.clearVideoFilter();
    }

    setVideoInputDevice(deviceId: string): Promise<void> {
        return this.applicationCall.setVideoInputDevice(deviceId);
    }

    status(): CallStatus {
        return this.applicationCall.status();
    }

    mute(shouldMute: boolean): Promise<void> {
        return this.applicationCall.mute(shouldMute);
    }

    private getDurationInSeconds(currentTime: Date) {
        return Math.floor((currentTime.getTime() - this.roomJoinTime.getTime()) / 1000);
    }

    private emitRoomJoinedEvent() {
        if (this.conferenceJoinedEvent && this.establishedEvent) {
            const roomJoinedEvent = this.createRoomJoinedEvent(this.establishedEvent, this.conferenceJoinedEvent);
            this.conferenceJoinedEvent = null;
            this.establishedEvent = null;
            this.roomJoinTime = new Date();
            this.apiEventEmitter.emit(CallsApiEvent.ROOM_JOINED, roomJoinedEvent);
        }
    }

    private emitRoomRejoinedEvent() {
        if (this.conferenceJoinedEvent && this.reconnectedEvent) {
            const event = this.createRoomRejoinedEvent(this.reconnectedEvent, this.conferenceJoinedEvent);
            this.conferenceJoinedEvent = null;
            this.reconnectedEvent = null;
            this.apiEventEmitter.emit(CallsApiEvent.ROOM_REJOINED, <RoomRejoinedEvent>event);
        }
    }

    private initEventHandlers() {
        this.applicationCall.on(CallsApiEvent.CONFERENCE_JOINED, (event: ConferenceJoinedEvent) => {
            this.conferenceJoinedEvent = event;
            this.emitRoomJoinedEvent();
            this.emitRoomRejoinedEvent();
        });

        this.applicationCall.on(CallsApiEvent.ESTABLISHED, (event: EarlyMediaEvent) => {
            this.establishedEvent = event;
            this.emitRoomJoinedEvent();
        });

        this.applicationCall.on(CallsApiEvent.HANGUP, (event: HangupEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ROOM_LEFT, event);
        });

        this.applicationCall.on(CallsApiEvent.ERROR, (event: ErrorEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ERROR, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_ADDED, (event: CameraVideoAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_UPDATED, (event: CameraVideoUpdatedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_UPDATED, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_REMOVED, () => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_REMOVED, {});
        });

        this.applicationCall.on(CallsApiEvent.SCREEN_SHARE_ADDED, (event: ScreenShareAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.SCREEN_SHARE_REMOVED, (event) => {
            this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_REMOVED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_JOINING, (event: ParticipantJoiningEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_JOINING, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_JOINED, (event: ParticipantJoinedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_JOINED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_MUTED, (event: ParticipantMutedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_MUTED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_UNMUTED, (event: ParticipantUnmutedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_UNMUTED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_DEAF, (event: ParticipantDeafEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_DEAF, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_UNDEAF, (event: ParticipantUndeafEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_UNDEAF, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_STARTED_TALKING, (event: ParticipantStartedTalkingEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_STARTED_TALKING, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_STOPPED_TALKING, (event: ParticipantStoppedTalkingEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_STOPPED_TALKING, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED, (event: ParticipantCameraVideoAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED, (event: ParticipantCameraVideoRemovedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_ADDED, (event: ParticipantScreenShareAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_REMOVED, (event: ParticipantScreenShareRemovedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_REMOVED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_LEFT, (event: ParticipantRemovedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_LEFT, event);
        });

        this.applicationCall.on(CallsApiEvent.NETWORK_QUALITY_CHANGED, (event: NetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.NETWORK_QUALITY_CHANGED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_NETWORK_QUALITY_CHANGED, (event: ParticipantNetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_NETWORK_QUALITY_CHANGED, event);
        });

        this.applicationCall.on(CallsApiEvent.RECONNECTING, () => {
            this.apiEventEmitter.emit(CallsApiEvent.ROOM_REJOINING, <RoomRejoiningEvent>{});
        });

        this.applicationCall.on(CallsApiEvent.RECONNECTED, (event: CallReconnectedEvent) => {
            this.reconnectedEvent = event;
            this.emitRoomRejoinedEvent();
        });
    }

    private createRoomJoinedEvent(
        establishedEvent: CallsApiEvents.EstablishedEvent,
        conferenceJoinedEvent: CallsApiEvents.ConferenceJoinedEvent
    ): RoomJoinedEvent {
        let roomJoinedEvent: CallsApiEvents.RoomJoinedEvent = {
            id: undefined,
            name: undefined,
            participants: undefined,
            stream: undefined
        };
        Object.assign(roomJoinedEvent, establishedEvent, conferenceJoinedEvent);
        return roomJoinedEvent;
    }

    private createRoomRejoinedEvent(
        reconnectedEvent: CallsApiEvents.ReconnectedEvent,
        conferenceJoinedEvent: CallsApiEvents.ConferenceJoinedEvent
    ): RoomRejoinedEvent {
        let event: CallsApiEvents.RoomRejoinedEvent = {
            id: undefined,
            name: undefined,
            participants: undefined,
            stream: undefined
        };
        Object.assign(event, reconnectedEvent, conferenceJoinedEvent);
        return event;
    }

    createCustomDataForReconnect(reconnectingCallId: string, reconnectingCallOptions: ApplicationCallOptions): ApplicationCallOptions {
        reconnectingCallOptions.customData["reconnectingCallId"] = reconnectingCallId;
        return reconnectingCallOptions;
    }
}
