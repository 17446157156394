import VideoType from "../../util/VideoType";
import {LocalWebrtcCapturer} from "../LocalWebrtcCapturer";
import {LocalCapturer} from "../LocalCapturer";

export class DefaultLocalWebrtcCapturer implements LocalWebrtcCapturer {
    constructor(private capturer: LocalCapturer,
                private currentUserIdentity: string,
                private counterpartIdentity: string) {
    }

    takeLocalScreenshot(videoType: VideoType): Promise<string> {
        return this.capturer.takeScreenshot(this.currentUserIdentity, videoType);
    }

    takeRemoteScreenshot(videoType: VideoType): Promise<string> {
        return this.capturer.takeScreenshot(this.counterpartIdentity, videoType);
    }
}
