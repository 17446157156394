import {AbstractCapturer} from "./AbstractCapturer";
import {ServerCapturer} from "../ServerCapturer";
import {FileResponse} from "../FileResponse";
import VideoType from "../../util/VideoType";
import {Logger} from "../../log/Logger";
import {ApiResponse, DefaultHttpClient, HttpClient} from "../../util/HttpClient";

export class DefaultServerCapturer extends AbstractCapturer implements ServerCapturer {
    private static TIMEOUT = 3000;
    private static FILE_STORAGE_NOT_ENABLED_ERROR: string = "File storage is not enabled for account.";
    private static GENERIC_FILE_UPLOAD_ERROR: string = "File upload failed.";
    private httpClient: HttpClient;

    constructor(localCameraStream: () => MediaStream,
                localScreenShareStream: () => MediaStream,
                remotePeerConnection: () => RTCPeerConnection,
                mid: (identity: string, type: VideoType) => string,
                currentUserIdentity: string,
                private readonly token: string,
                private readonly apiUrl: string,
                private readonly logger: Logger
    ) {
        super(localCameraStream, localScreenShareStream, remotePeerConnection, mid, currentUserIdentity)
        this.initializeHttpClient();
    }

    async takeScreenshot(identity: string, videoType: VideoType): Promise<FileResponse> {
        let fileResponse: ApiResponse<FileResponse> = null;

        try {
            const canvas = await this.getCanvas(identity, videoType);
            const file: File = await this.toFile(
                canvas,
                `${identity}_${videoType.toString().toLowerCase()}_${Date.now()}.png`,
            );
            fileResponse = await this.uploadImage(file);
        } catch (e) {
            throw new Error(DefaultServerCapturer.GENERIC_FILE_UPLOAD_ERROR);
        }

        if (fileResponse.ok) {
            this.logger.info("Successfully uploaded file: " + JSON.stringify(fileResponse.data))
            return fileResponse.data;
        } else {
            throw new Error(this.getErrorMessage(fileResponse.error));
        }
    }

    private initializeHttpClient() {
        this.httpClient = new DefaultHttpClient(`https://${this.apiUrl}`, {"Authorization": `Bearer ${this.token}`});
    }

    private async uploadImage(file: File): Promise<ApiResponse<FileResponse>> {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("fileFormat", "PNG");

        return await this.httpClient.post<FileResponse>("/webrtc/1/files", formData);
    }

    private async toFile(canvas: HTMLCanvasElement, fileName: string): Promise<File> {
        return new Promise(((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject("File generation timed out.")
            }, DefaultServerCapturer.TIMEOUT);

            canvas.toBlob(blob => {
                clearTimeout(timeoutId);
                resolve(new File([blob], fileName, { type: "image/png" }));
            });
        }));
    }

    private getErrorMessage(error: any): string {
        if (error?.requestError?.serviceException?.text === DefaultServerCapturer.FILE_STORAGE_NOT_ENABLED_ERROR) {
            return DefaultServerCapturer.FILE_STORAGE_NOT_ENABLED_ERROR;
        }
        return DefaultServerCapturer.GENERIC_FILE_UPLOAD_ERROR;
    }
}
