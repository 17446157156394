export class CallStats {
    public bytesSent: number = 0;
    public bytesReceived: number = 0;
    public currentRoundTripTime: number;
    public availableOutgoingBitrate: number;
    public mos: number;

    public timestamp: number = Date.now();
    private readonly isVideo: boolean;

    constructor(isVideo: boolean) {
        this.isVideo = isVideo;
    }

    public toJSON() {
        return {
            bytesSent: this.bytesSent,
            bytesReceived: this.bytesReceived,
            currentRoundTripTime: this.currentRoundTripTime,
            availableOutgoingBitrate: this.availableOutgoingBitrate,
            mos: this.mos
        };
    }
}
