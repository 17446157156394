import {PhoneCallOptions} from "../options/PhoneCallOptions";
import {Endpoint, PhoneEndpoint, WebrtcEndpoint} from "../../util/Endpoint";
import {FlowType} from "../FlowType";
import {DefaultCall} from "./DefaultCall";
import {PhoneCall} from "../PhoneCall";
import {CallsApiEvent, CallsApiEvents} from "../event/CallsApiEvents";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import ValidationUtil from "./util/ValidationUtil";
import {CustomData} from "../CustomDataType";
import {InfobipRTC} from "../../InfobipRTC";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";
import EarlyMediaEvent = CallsApiEvents.EarlyMediaEvent;
import EstablishedEvent = CallsApiEvents.EstablishedEvent;
import HangupEvent = CallsApiEvents.HangupEvent;
import ErrorEvent = CallsApiEvents.ErrorEvent;
import NetworkQualityChangedEvent = CallsApiEvents.NetworkQualityChangedEvent;
import {getRecordingOptions} from "./util/CallOptionsUtil";

export class DefaultOutgoingPhoneCall extends DefaultCall implements PhoneCall {
    constructor(infobipRtc: InfobipRTC,
                phoneCallOptions: PhoneCallOptions,
                currentUserIdentity: string,
                phoneNumberDestination: string) {
        super(phoneCallOptions, new WebrtcEndpoint(currentUserIdentity), new PhoneEndpoint(phoneNumberDestination));
        let applicationCallOptions = this.mapOptions(phoneNumberDestination, phoneCallOptions);
        this.applicationCall = infobipRtc.callApplication(phoneNumberDestination, applicationCallOptions);
        this.initEventHandlers();
    }

    counterpart(): Endpoint {
        return this.destination();
    }

    options(): PhoneCallOptions {
        return <PhoneCallOptions>this.basicCallOptions;
    }

    private mapOptions(phoneNumberDestination: string, phoneCallOptions: PhoneCallOptions): ApplicationCallOptions {
        if (phoneCallOptions?.customData) {
            ValidationUtil.validateCustomData(phoneCallOptions?.customData);
        }

        const internalCustomData: CustomData = {
            to: phoneNumberDestination,
            from: phoneCallOptions?.from,
            type: FlowType.PHONE_NUMBER.toString(),
            ...getRecordingOptions(phoneCallOptions),
            withDialog: "true"
        };

        let applicationCallOptions = ApplicationCallOptions.builder()
            .setAudio(phoneCallOptions?.audio)
            .setAudioOptions(phoneCallOptions?.audioOptions)
            .build();

        if (phoneCallOptions?.customData) {
            applicationCallOptions.customData = phoneCallOptions?.customData;
        }

        return new InternalApplicationCallOptions(
            applicationCallOptions,
            internalCustomData
        );
    }

    private initEventHandlers() {
        this.applicationCall.on(CallsApiEvent.RINGING, () => {
            this.apiEventEmitter.emit(CallsApiEvent.RINGING, {});
        });
        this.applicationCall.on(CallsApiEvent.EARLY_MEDIA, (event: EarlyMediaEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.EARLY_MEDIA, event);
        });
        this.applicationCall.on(CallsApiEvent.ESTABLISHED, (event: EstablishedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ESTABLISHED, event);
        });
        this.applicationCall.on(CallsApiEvent.HANGUP, (event: HangupEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.HANGUP, event);
        });
        this.applicationCall.on(CallsApiEvent.ERROR, (event: ErrorEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ERROR, event);
        });
        this.applicationCall.on(CallsApiEvent.NETWORK_QUALITY_CHANGED, (event: NetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.NETWORK_QUALITY_CHANGED, event);
        });
    }
}
