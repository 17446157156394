import {AudioOptions} from "./AudioOptions";
import {RecordingOptions} from "./RecordingOptions";
import {CustomData} from "../CustomDataType";

export class CallOptions {
    constructor(private _audio: boolean,
                private _audioOptions: AudioOptions,
                private _recordingOptions: RecordingOptions,
                private _customData: CustomData) {
    }

    get audio(): boolean {
        return this._audio;
    }

    set audio(value: boolean) {
        this._audio = value;
    }

    get audioOptions(): AudioOptions {
        return this._audioOptions;
    }

    set audioOptions(value: AudioOptions) {
        this._audioOptions = value;
    }

    get recordingOptions(): RecordingOptions {
        return this._recordingOptions;
    }

    set recordingOptions(value: RecordingOptions) {
        this._recordingOptions = value;
    }

    get customData(): CustomData {
        return this._customData;
    }

    set customData(value: CustomData) {
        this._customData = value;
    }
}

