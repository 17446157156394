import {Endpoint} from "../../util/Endpoint";

export enum DataChannelEvent {
    TEXT_DELIVERED_EVENT = "text-delivered",
    TEXT_RECEIVED_EVENT = "text-received",
    BROADCAST_TEXT_RECEIVED_EVENT = "broadcast-text-received"
}

export type AnyDataChannelEvent = DataChannelEvent.TEXT_DELIVERED_EVENT |
    DataChannelEvent.TEXT_RECEIVED_EVENT |
    DataChannelEvent.BROADCAST_TEXT_RECEIVED_EVENT;

export namespace DataChannelEvents {
    export interface TextDeliveredEvent {
        id: string;
        date: Date;
        delivered: boolean;
    }

    export interface TextReceivedEvent {
        text: string;
        from: Endpoint;
        isDirect: boolean;
        date: Date;
    }

    export interface BroadcastTextReceivedEvent {
        text: string;
        date: Date;
    }
}
