import {ServerWebrtcCapturer} from "../ServerWebrtcCapturer";
import VideoType from "../../util/VideoType";
import {FileResponse} from "../FileResponse";
import {ServerCapturer} from "../ServerCapturer";

export class DefaultServerWebrtcCapturer implements ServerWebrtcCapturer {
    constructor(private capturer: ServerCapturer,
                private currentUserIdentity: string,
                private counterpartIdentity: string) {
    }

    takeLocalScreenshot(videoType: VideoType): Promise<FileResponse> {
        return this.capturer.takeScreenshot(this.currentUserIdentity, videoType);
    }

    takeRemoteScreenshot(videoType: VideoType): Promise<FileResponse> {
        return this.capturer.takeScreenshot(this.counterpartIdentity, videoType);
    }
}
