import {LocalMediaStats} from "../LocalMediaStats";

export class AudioLocalMediaStats extends LocalMediaStats {
    constructor(id: string) {
        super(id);
    }

    get video(): boolean {
        return false;
    }

    public toJSON(): any {
        return super.toJSON();
    }
}