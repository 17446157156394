import VideoType from "../../util/VideoType";
import {LocalCapturer} from "../LocalCapturer";
import {AbstractCapturer} from "./AbstractCapturer";

export class DefaultLocalCapturer extends AbstractCapturer implements LocalCapturer  {
    async takeScreenshot(identity: string, videoType: VideoType): Promise<string> {
        const canvas = await this.getCanvas(identity, videoType);

        return canvas.toDataURL();
    }
}
