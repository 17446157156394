import {WebrtcCallOptions} from "../options/WebrtcCallOptions";
import {DefaultCall} from "./DefaultCall";
import {Endpoint} from "../../util/Endpoint";
import {WebrtcCall} from "../WebrtcCall";
import {CameraOrientation} from "../options/CameraOrientation";
import {VideoFilter} from "../options/filters/VideoFilter";
import {CallsApiEvent, CallsApiEvents} from "../event/CallsApiEvents";
import {DefaultLocalWebrtcCapturer} from "./DefaultLocalWebrtcCapturer";
import {LocalWebrtcCapturer} from "../LocalWebrtcCapturer";
import {ServerWebrtcCapturer} from "../ServerWebrtcCapturer";
import {DefaultServerWebrtcCapturer} from "./DefaultServerWebrtcCapturer";
import {DataChannel} from "../DataChannel";
import EarlyMediaEvent = CallsApiEvents.EarlyMediaEvent;
import EstablishedEvent = CallsApiEvents.EstablishedEvent;
import HangupEvent = CallsApiEvents.HangupEvent;
import ErrorEvent = CallsApiEvents.ErrorEvent;
import ParticipantCameraVideoAddedEvent = CallsApiEvents.ParticipantCameraVideoAddedEvent;
import ParticipantScreenShareAddedEvent = CallsApiEvents.ParticipantScreenShareAddedEvent;
import NetworkQualityChangedEvent = CallsApiEvents.NetworkQualityChangedEvent;
import ParticipantNetworkQualityChangedEvent = CallsApiEvents.ParticipantNetworkQualityChangedEvent;
import ScreenShareAddedEvent = CallsApiEvents.ScreenShareAddedEvent;
import CameraVideoUpdatedEvent = CallsApiEvents.CameraVideoUpdatedEvent;
import CameraVideoAddedEvent = CallsApiEvents.CameraVideoAddedEvent;
import {DisplayOptions} from "../options/DisplayOptions";

export class DefaultWebrtcCall extends DefaultCall implements WebrtcCall {
    private isRemoteCameraVideo: boolean = false;
    private isRemoteScreenShare: boolean = false;
    private localWebrtcCapturer: LocalWebrtcCapturer;
    private serverWebrtcCapturer: ServerWebrtcCapturer;

    constructor(webrtcCallOptions: WebrtcCallOptions,
                sourceEndpoint: Endpoint,
                destinationEndpoint: Endpoint) {
        super(webrtcCallOptions, sourceEndpoint, destinationEndpoint);
    }

    pauseIncomingVideo(): void {
        this.applicationCall.pauseIncomingVideo();
    }

    resumeIncomingVideo(): void {
        this.applicationCall.resumeIncomingVideo();
    }

    cameraVideo(cameraVideo: boolean): Promise<void> {
        return this.applicationCall.cameraVideo(cameraVideo);
    }

    hasCameraVideo(): boolean {
        return this.applicationCall.hasCameraVideo();
    }

    hasRemoteCameraVideo(): boolean {
        return this.isRemoteCameraVideo;
    }

    screenShare(screenShare: boolean): Promise<void> {
        return this.applicationCall.screenShare(screenShare);
    }

    startScreenShare(displayOptions?: DisplayOptions): Promise<void> {
        return this.applicationCall.startScreenShare(displayOptions);
    }

    stopScreenShare(): Promise<void> {
        return this.applicationCall.stopScreenShare();
    }

    hasScreenShare(): boolean {
        return this.applicationCall.hasScreenShare();
    }

    hasRemoteScreenShare(): boolean {
        return this.isRemoteScreenShare;
    }

    setVideoInputDevice(deviceId: string): Promise<void> {
        return this.applicationCall.setVideoInputDevice(deviceId);
    }

    cameraOrientation(): CameraOrientation {
        return this.applicationCall.cameraOrientation();
    }

    setCameraOrientation(cameraOrientation: CameraOrientation): Promise<void> {
        return this.applicationCall.setCameraOrientation(cameraOrientation);
    }

    options(): WebrtcCallOptions {
        return <WebrtcCallOptions>this.basicCallOptions;
    }

    videoFilter(): VideoFilter {
        return this.applicationCall.videoFilter();
    }

    setVideoFilter(videoFilter: VideoFilter): Promise<void> {
        return this.applicationCall.setVideoFilter(videoFilter);
    }

    clearVideoFilter(): Promise<void> {
        return this.applicationCall.clearVideoFilter();
    }

    localCapturer(): LocalWebrtcCapturer {
        return this.localWebrtcCapturer ??= new DefaultLocalWebrtcCapturer(
            this.applicationCall.localCapturer(),
            [this.sourceEndpoint, this.destinationEndpoint].filter(endpoint => endpoint !== this.counterpart())[0].identifier,
            this.counterpart().identifier
        );
    }

    serverCapturer(): ServerWebrtcCapturer {
        return this.serverWebrtcCapturer ??= new DefaultServerWebrtcCapturer(
            this.applicationCall.serverCapturer(),
            [this.sourceEndpoint, this.destinationEndpoint].filter(endpoint => endpoint !== this.counterpart())[0].identifier,
            this.counterpart().identifier
        );
    }

    dataChannel(): DataChannel {
        return this.applicationCall.dataChannel();
    }

    protected initEventHandlers() {
        this.applicationCall.on(CallsApiEvent.RINGING, () => {
            this.apiEventEmitter.emit(CallsApiEvent.RINGING, {});
        });

        this.applicationCall.on(CallsApiEvent.EARLY_MEDIA, (event: EarlyMediaEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.EARLY_MEDIA, event);
        });

        this.applicationCall.on(CallsApiEvent.ESTABLISHED, (event: EstablishedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ESTABLISHED, event);
        });

        this.applicationCall.on(CallsApiEvent.HANGUP, (event: HangupEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.HANGUP, event);
        });

        this.applicationCall.on(CallsApiEvent.ERROR, (event: ErrorEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ERROR, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_ADDED, (event: CameraVideoAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_UPDATED, (event: CameraVideoUpdatedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_UPDATED, event);
        });

        this.applicationCall.on(CallsApiEvent.CAMERA_VIDEO_REMOVED, () => {
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_REMOVED, {});
        });

        this.applicationCall.on(CallsApiEvent.SCREEN_SHARE_ADDED, (event: ScreenShareAddedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_ADDED, event);
        });

        this.applicationCall.on(CallsApiEvent.SCREEN_SHARE_REMOVED, (event) => {
            this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_REMOVED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_MUTED, () => {
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_MUTED, {});
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_UNMUTED, () => {
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_UNMUTED, {});
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED, (event: ParticipantCameraVideoAddedEvent) => {
            this.isRemoteCameraVideo = true;
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_CAMERA_VIDEO_ADDED, {stream: event.stream});
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED, () => {
            this.isRemoteCameraVideo = false;
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_CAMERA_VIDEO_REMOVED, {});
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_ADDED, (event: ParticipantScreenShareAddedEvent) => {
            this.isRemoteScreenShare = true;
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_SCREEN_SHARE_ADDED, {stream: event.stream});
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_SCREEN_SHARE_REMOVED, () => {
            this.isRemoteScreenShare = false;
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_SCREEN_SHARE_REMOVED, {});
        });

        this.applicationCall.on(CallsApiEvent.NETWORK_QUALITY_CHANGED, (event: NetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.NETWORK_QUALITY_CHANGED, event);
        });

        this.applicationCall.on(CallsApiEvent.PARTICIPANT_NETWORK_QUALITY_CHANGED, (event: ParticipantNetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.REMOTE_NETWORK_QUALITY_CHANGED, <CallsApiEvents.RemoteNetworkQualityChangedEvent>{
                networkQuality: event.networkQuality
            });
        });

        this.applicationCall.on(CallsApiEvent.DIALOG_JOINED, event => {
            const remote = event.remote;
            if (remote.endpoint.identifier === this.counterpart().identifier && remote.media.audio.muted) {
                this.apiEventEmitter.emit(CallsApiEvent.REMOTE_MUTED, {});
            }
        });
    }
}
