import {CallOptions} from "./CallOptions";
import {VideoOptions} from "./VideoOptions";
import {AudioOptions} from "./AudioOptions";
import {CameraOrientation} from "./CameraOrientation";
import {CustomData} from "../CustomDataType";
import {WebrtcCallRecordingOptions} from "./WebrtcCallRecordingOptions";
import {VideoMode} from "./VideoMode";
import {AudioQualityMode} from "./AudioQualityMode";

export class WebrtcCallOptions extends CallOptions {
    constructor(audio: boolean,
                audioOptions: AudioOptions,
                recordingOptions: WebrtcCallRecordingOptions,
                customData: CustomData,
                private _video: boolean,
                private _videoOptions: VideoOptions,
                private _dataChannel: boolean) {
        super(audio, audioOptions, recordingOptions, customData);
    }

    get video(): boolean {
        return this._video;
    }

    set video(value: boolean) {
        this._video = value;
    }

    get videoOptions(): VideoOptions {
        return this._videoOptions;
    }

    set videoOptions(value: VideoOptions) {
        this._videoOptions = value;
    }

    get dataChannel(): boolean {
        return this._dataChannel;
    }

    set dataChannel(value: boolean) {
        this._dataChannel = value;
    }

    static builder(): WebrtcCallOptionsBuilder {
        return new WebrtcCallOptionsBuilder();
    }
}

class WebrtcCallOptionsBuilder {
    private _audio: boolean = true;
    private _audioOptions: AudioOptions = new AudioOptions(null, AudioQualityMode.AUTO);
    private _recordingOptions: WebrtcCallRecordingOptions = new WebrtcCallRecordingOptions();
    private _customData: CustomData = {};
    private _video: boolean = false;
    private _videoOptions: VideoOptions = new VideoOptions(CameraOrientation.FRONT, null, VideoMode.PRESENTATION, 24, 8);
    private _dataChannel: boolean = false;

    setAudio(value: boolean): WebrtcCallOptionsBuilder {
        this._audio = value;
        return this;
    }

    setAudioOptions(value: AudioOptions): WebrtcCallOptionsBuilder {
        this._audioOptions = value;
        return this;
    }

    setRecordingOptions(recordingOptions: WebrtcCallRecordingOptions) {
        this._recordingOptions = recordingOptions;
        return this;
    }

    setCustomData(value: CustomData): WebrtcCallOptionsBuilder {
        this._customData = value;
        return this;
    }

    setVideo(value: boolean): WebrtcCallOptionsBuilder {
        this._video = value;
        return this;
    }

    setVideoOptions(value: VideoOptions): WebrtcCallOptionsBuilder {
        this._videoOptions = value;
        return this;
    }

    setDataChannel(_dataChannel: boolean): WebrtcCallOptionsBuilder {
        this._dataChannel = _dataChannel;
        return this;
    }

    build(): WebrtcCallOptions {
        return new WebrtcCallOptions(
            this._audio,
            this._audioOptions,
            this._recordingOptions,
            this._customData,
            this._video,
            this._videoOptions,
            this._dataChannel
        );
    }
}
