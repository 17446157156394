import log from "loglevel";

const EventEmitter = require('events');

export class ApiEventEmitter extends EventEmitter {
    on(event: string | symbol, listener: (...args: any[]) => void): this {
        return super.on(event, wrapHandler(listener));
    };

    once(event: string | symbol, listener: (...args: any[]) => void): this {
        return super.once(event, wrapHandler(listener));
    };
}

function wrapHandler<F extends (...args: any[]) => any>(fn: F): F {
    return <F>function (...args: any[]) {
        let result: any;
        try {
            result = fn(...args);
        } catch (error) {
            log.error("An error has occurred in the handler function", error);
        }
        return result;
    }
}
