import {CallOptions} from "./CallOptions";
import {AudioOptions} from "./AudioOptions";
import {ViberCallRecordingOptions} from "./ViberCallRecordingOptions";
import {CustomData} from "../CustomDataType";
import {AudioQualityMode} from "./AudioQualityMode";

export class ViberCallOptions extends CallOptions {
    constructor(audio: boolean,
                audioOptions: AudioOptions,
                recordingOptions: ViberCallRecordingOptions,
                customData: CustomData) {
        super(audio, audioOptions, recordingOptions, customData);
    }

    static builder(): ViberCallOptionsBuilder {
        return new ViberCallOptionsBuilder();
    }
}

class ViberCallOptionsBuilder {
    private _audio: boolean = true;
    private _audioOptions: AudioOptions = new AudioOptions(null, AudioQualityMode.AUTO);
    private _recordingOptions: ViberCallRecordingOptions = new ViberCallRecordingOptions();
    private _customData: CustomData = {};

    setAudio(_audio: boolean): ViberCallOptionsBuilder {
        this._audio = _audio;
        return this;
    }

    setAudioOptions(_audioOptions: AudioOptions): ViberCallOptionsBuilder {
        this._audioOptions = _audioOptions;
        return this;
    }

    setRecordingOptions(recordingOptions: ViberCallRecordingOptions): ViberCallOptionsBuilder {
        this._recordingOptions = recordingOptions;
        return this;
    }

    setCustomData(value: CustomData): ViberCallOptionsBuilder {
        this._customData = value;
        return this;
    }

    build() {
        return new ViberCallOptions(this._audio,
            this._audioOptions,
            this._recordingOptions,
            this._customData
        );
    }
}
