import {InfobipGateway} from "../../gateway/InfobipGateway";
import {EventEmitter} from "events";
import HangupStatusFactory from "../util/HangupStatusFactory";
import {Logger} from "../../log/Logger";
import {Device} from "../../device/Device";
import {CallStatus} from "../CallStatus";
import {DefaultApplicationCall} from "./DefaultApplicationCall";
import {IncomingApplicationCall} from "../IncomingApplicationCall";
import {WsAction} from "../ws/WsAction";
import {DeclineType} from "../DeclineType";
import {DeclineOptions} from "../options/DeclineOptions";
import HangupReasonFactory from "./util/HangupReasonFactory";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";

export class DefaultIncomingApplicationCall extends DefaultApplicationCall implements IncomingApplicationCall {
    constructor(eventEmitter: EventEmitter,
                gateway: InfobipGateway,
                logger: Logger,
                rtcConfig: any,
                device: Device,
                private caller: any,
                applicationId: string,
                private remoteOffer: RTCSessionDescriptionInit,
                currentUserIdentity: string,
                token: string,
                apiUrl: string,
                correlationId: string) {
        super(eventEmitter, gateway, logger, rtcConfig, device, applicationId, null, currentUserIdentity, token, apiUrl, correlationId);
        this.callStatus = CallStatus.RINGING;
    }

    from(): string {
        return this.caller.identity;
    }

    fromDisplayName(): string {
        return this.caller.displayName;
    }

    accept(options?: ApplicationCallOptions): void {
        if (this.callStatus !== CallStatus.RINGING) {
            return;
        }
        this.callStatus = CallStatus.CONNECTING;
        this.applicationCallOptions = options || ApplicationCallOptions.builder().build();
        this.localAudio = {active: this.applicationCallOptions?.audio ?? true};
        this.localCameraVideo = {active: this.applicationCallOptions?.video ?? false};

        this.createAudioPeerConnection();
        this.negotiateAudio(this.applicationCallOptions);
        if (this.applicationCallOptions?.dataChannel) {
            this.createDataChannel();
        }
    }

    protected async negotiateAudio(options?: ApplicationCallOptions): Promise<void> {
        try {
            const stream = await this.getLocalAudioStream(
                options?.audio,
                options?.video,
                options?.videoOptions?.cameraOrientation,
                options?.videoOptions?.cameraVideoFrameRate
            );

            this.setLocalAudioStream(stream);
            await this.setRemoteDescription();
            this.setRemoteCandidates();
            this.addTracks();
            let answer = await this.audioPC.peerConnection.createAnswer();
            answer = await this.setLocalDescription(this.audioPC.peerConnection, answer);
            this.sendAnswer(answer);
        } catch (error) {
            this.handleIncomingCallFlowError(error);
        }
    }

    decline(options?: DeclineOptions) {
        if (this.callStatus !== CallStatus.RINGING) {
            return;
        }
        this.callStatus = CallStatus.FINISHED;
        this.declineCall(DeclineType.BUSY, 'Busy Here', options?.declineOnAllDevices);
    }

    private async setRemoteDescription() {
        return await this.audioPC.peerConnection.setRemoteDescription(this.remoteOffer);
    }

    private addTracks() {
        let stream = this.emptyAudioStream ? this.emptyAudioStream.stream() : this.localAudioStream;

        stream.getTracks().forEach((track: MediaStreamTrack) =>
            this.audioPC.peerConnection.addTrack(track, this.localAudioStream));

        this.localAudio.transceiver = this.audioPC.peerConnection.getTransceivers()
            .find(transceiver => transceiver.receiver.track.kind === 'audio');
    }

    private sendAnswer(answer: RTCSessionDescriptionInit) {
        this.gateway.send({
            action: WsAction.APP_CALL_ACCEPT,
            callId: this.callId,
            description: answer,
            media: {
                audio: {
                    muted: !this.localAudio.active,
                },
                video: {
                    camera: this.localCameraVideo.active
                }
            }
        });
    }

    private handleIncomingCallFlowError(error: any) {
        this.logger.error(`Call flow error occurred: ${error}`, this.callId);
        let reason = HangupReasonFactory.getHangupReason(error);
        this.declineCall(DeclineType.ERROR, reason);
        let hangupStatus = HangupStatusFactory.getApplicationHangupStatus(error);
        this.eventEmitter.emit('call-error', {status: hangupStatus});
    }

    private declineCall(declineType: DeclineType, reason: string, declineOnAllDevices: boolean = false) {
        let message = {
            action: WsAction.APP_CALL_DECLINE,
            callId: this.callId,
            declineType: declineType,
            reason: reason,
            declineOnAllDevices: declineOnAllDevices
        };
        this.gateway.send(message);
    }
}
