import VideoType from "../../../util/VideoType";
import {Logger} from "../../../log/Logger";

export const BitrateConfig: { [key in VideoType]: number } = {
    [VideoType.CAMERA]: 2_000_000,
    [VideoType.SCREENSHARE]: 2_500_000,
};

export function configureForSending(peerConnection: RTCPeerConnection, streamKind: "audio" | "video", bitrateBps: number, logger: Logger) {
    if (!peerConnection) {
        return;
    }

    const senders = peerConnection.getSenders();
    senders.forEach((sender) => {
        if (sender.track && sender.track.kind !== streamKind) {
            return
        }

        const parameters = sender.getParameters()
        parameters.encodings ??= [{}]

        if (bitrateBps <= 0) {
            return;
        }
        logger.debug(`Configuring ${sender?.track?.id} bitrate for video streaming to ${bitrateBps / 1_000} [Kbps]`);

        parameters.encodings.forEach(encoding => {
            encoding.maxBitrate = bitrateBps;
        })
        sender.setParameters(parameters)
            .catch(err => logger.error(`Changing sender parameters failed: ${err?.message}`))
    });
}
