import {NetworkQuality} from "../../../call/event/NetworkQuality";
import {CodecWeights} from "./CodecWeights";

export class NetworkQualityStatistics {
    public readonly networkQuality: NetworkQuality;

    private constructor(public readonly mos: number = 0) {
        this.networkQuality = this.getNetworkQuality();
    }

    static forRTCStats(rtt: number, jitter: number, packetsReceived: number, packetsLost: number, codec: string): NetworkQualityStatistics {
        let mos = NetworkQualityStatistics.calculateMos(rtt, jitter, packetsReceived, packetsLost, codec);
        return new NetworkQualityStatistics(mos);
    }

    static forMos(mos: number): NetworkQualityStatistics {
        return new NetworkQualityStatistics(mos);
    }

    private static calculateMos(rtt: number, jitter: number, packetsReceived: number, packetsLost: number, codec: string): number {
        // TODO What needs to be done when packet loss is negative due to duplicated packets (therefore mos is negative in that case)?
        let codecDelay = 10.0;
        let effectiveLatency = rtt + 2 * jitter + codecDelay;
        let packetsSum = packetsLost + packetsReceived;
        let percentagePacketsLost = packetsSum === 0 ? 0 : packetsLost * 100 / packetsSum
        let codecWeightFactor = CodecWeights.get(codec);

        let transmissionRatingFactor = effectiveLatency < 160 ? 93.2 - (effectiveLatency / 40) : 93.2 - ((effectiveLatency - 120) / 10);
        transmissionRatingFactor = transmissionRatingFactor - 2.5 * percentagePacketsLost * codecWeightFactor;
        if (transmissionRatingFactor < 0) {
            return 1.0;
        }
        let mos = 1 + 0.035 * transmissionRatingFactor + 0.000007 * transmissionRatingFactor * (transmissionRatingFactor - 60) * (100 - transmissionRatingFactor);
        return Math.round((mos + Number.EPSILON) * 100) / 100;
    }

    private getNetworkQuality(): NetworkQuality {
        if (this.mos > 4.34) {
            return NetworkQuality.EXCELLENT;
        } else if (this.mos > 4.03) {
            return NetworkQuality.GOOD;
        } else if (this.mos > 3.6) {
            return NetworkQuality.FAIR;
        } else if (this.mos > 3.1) {
            return NetworkQuality.POOR;
        } else {
            return NetworkQuality.BAD;
        }
    }
}
