import {WebrtcCallOptions} from "./call/options/WebrtcCallOptions";
import {WebrtcCall} from "./call/WebrtcCall";
import {ApplicationCallOptions} from "./call/options/ApplicationCallOptions";
import {ApplicationCall} from "./call/ApplicationCall";
import {RoomCallOptions} from "./call/options/RoomCallOptions";
import {RoomCall} from "./call/RoomCall";
import {PhoneCallOptions} from "./call/options/PhoneCallOptions";
import {PhoneCall} from "./call/PhoneCall";
import {ViberCallOptions} from "./call/options/ViberCallOptions";
import {ViberCall} from "./call/ViberCall";
import {DefaultInfobipRTC} from "./DefaultInfobipRTC";
import {InfobipRTCEvent} from "./event/InfobipRTCEvents";
import {InfobipRTCEventHandlers} from "./event/InfobipRTCEventHandlers";
import {User} from "./call/User";

export type InfobipRTCFactory = (token: string, rtcOptions: any) => InfobipRTC;
export const createInfobipRtc: InfobipRTCFactory = ((token, rtcOptions = {}) => {
    return new DefaultInfobipRTC(token, rtcOptions)
});

export interface InfobipRTC {
    connect(): void;
    disconnect(): void;
    connectedUser(): User;
    callWebrtc(identity: string, options?: WebrtcCallOptions): WebrtcCall;
    callPhone(phoneNumber: string, phoneCallOptions?: PhoneCallOptions): PhoneCall;
    callViber(phoneNumber: string, from: string, viberCallOptions?: ViberCallOptions): ViberCall;
    joinRoom(roomName: string, options?: RoomCallOptions): RoomCall;
    callApplication(applicationId: string, options?: ApplicationCallOptions): ApplicationCall;
    getAudioInputDevices(): Promise<MediaDeviceInfo[]>;
    setAudioInputDevice(deviceId: string): void;
    unsetAudioInputDevice(deviceId: string): void;
    getAudioOutputDevices(): Promise<MediaDeviceInfo[]>;
    getVideoInputDevices(): Promise<MediaDeviceInfo[]>;
    setVideoInputDevice(deviceId: string): void;
    unsetVideoInputDevice(deviceId: string): void;

    on(name: InfobipRTCEvent.CONNECTED, handler: InfobipRTCEventHandlers.Connected): void
    on(name: InfobipRTCEvent.RECONNECTING, handler: InfobipRTCEventHandlers.Reconnecting): void
    on(name: InfobipRTCEvent.RECONNECTED, handler: InfobipRTCEventHandlers.Reconnected): void
    on(name: InfobipRTCEvent.DISCONNECTED, handler: InfobipRTCEventHandlers.Disconnected): void
    on(name: InfobipRTCEvent.INCOMING_APPLICATION_CALL, handler: InfobipRTCEventHandlers.IncomingApplicationCall): void
    on(name: InfobipRTCEvent.INCOMING_WEBRTC_CALL, handler: InfobipRTCEventHandlers.IncomingWebrtcCall): void
}
