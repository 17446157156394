import {InfobipGateway} from "../../gateway/InfobipGateway";
import {EventEmitter} from "events";
import {Logger} from "../../log/Logger";
import {Device} from "../../device/Device";
import {DefaultApplicationCall} from "./DefaultApplicationCall";
import {CallStatus} from "../CallStatus";
import Action from "./Action";
import {CustomData} from "../CustomDataType";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";

export class DefaultOutgoingApplicationCall extends DefaultApplicationCall {
    private static WEBRTC_APPLICATION_ID = "WEBRTC";

    constructor(eventEmitter: EventEmitter,
                gateway: InfobipGateway,
                logger: Logger,
                rtcConfig: any,
                device: Device,
                applicationId: string,
                options: ApplicationCallOptions,
                currentUserIdentity: string,
                token: string,
                apiUrl: string) {
        super(eventEmitter, gateway, logger, rtcConfig, device, applicationId, options, currentUserIdentity, token, apiUrl);
        this.localAudio = {active: options?.audio ?? true};
        this.localCameraVideo = {active: options?.video ?? false};
        // TODO: use options.videoOptions
        this.createAudioPeerConnection();
        this.negotiateAudio(options);
    }

    protected async negotiateAudio(options?: ApplicationCallOptions): Promise<void> {
        try {
            const stream = await this.getLocalAudioStream(
                this.applicationCallOptions?.audio,
                this.applicationCallOptions?.video,
                this.applicationCallOptions?.videoOptions?.cameraOrientation,
                this.applicationCallOptions?.videoOptions?.cameraVideoFrameRate
            );

            this.setLocalAudioStream(stream);
            this.createAudioTransceiver();
            let offer = await this.audioPC.peerConnection.createOffer();
            offer = await this.setLocalDescription(this.audioPC.peerConnection, offer);
            this.sendOffer(offer, options);
        } catch (error) {
            this.handleCallFlowError(error);
        }
    }

    private sendOffer(offer: RTCSessionDescriptionInit, options?: ApplicationCallOptions) {
        const isInternal: boolean = options instanceof InternalApplicationCallOptions
        let customData: CustomData = options?.customData;

        this.callStatus = CallStatus.CALLING;
        this.gateway.send({
            action: Action.APPLICATION_CALL,
            callId: this.callId,
            destination: this._applicationId,
            applicationId: isInternal ? DefaultOutgoingApplicationCall.WEBRTC_APPLICATION_ID : this._applicationId,
            ...(options?.entityId && {entityId: options?.entityId}),
            ...(options?.videoOptions?.videoMode && {videoMode: options?.videoOptions?.videoMode}),
            description: offer,
            customData: customData,
            ...isInternal && {internalCustomData: (<InternalApplicationCallOptions>options)?.internalCustomData()},
            media: {
                audio: {
                    muted: !this.localAudio.active,
                },
                video: {
                    camera: this.localCameraVideo.active
                }
            }
        });
    }
}
