import {RemoteMediaStats} from "../RemoteMediaStats";

export class AudioRemoteMediaStats extends RemoteMediaStats {
    public jitter: number = 0;
    public concealedSamples: number = 0;
    public concealmentEvents: number = 0;

    constructor(id: string) {
        super(id);
    }

    get video(): boolean {
        return false;
    }

    public toJSON() {
        return {
            ...super.toJSON(),
            jitter: this.jitter,
            concealedSamples: this.concealedSamples,
            concealmentEvents: this.concealmentEvents
        }
    }
}
