import {AudioStats} from "./AudioStats";

export class TotalMediaStats {
    public totalAudioStats: AudioStats = new AudioStats();

    public toJSON(): any {
        return {
            totalAudioStats: this.totalAudioStats.toJSON()
        };
    }
}