import _ from "lodash";
import {MediaDevice} from "./device/MediaDevice";
import {CameraOrientation} from "./call/options/CameraOrientation";

const AUDIO_INPUT = "audioinput";
const AUDIO_OUTPUT = "audiooutput";
const VIDEO_INPUT = "videoinput";

export class RTCMediaDevice {
    static getAudioInputDevices(): Promise<MediaDevice[]> {
        return RTCMediaDevice.requestPermission({audio: true, video: false})
            .then(() => RTCMediaDevice.getDevices(mediaDeviceInfo => mediaDeviceInfo.kind === AUDIO_INPUT));
    }

    static getAudioOutputDevices(): Promise<MediaDevice[]> {
        return RTCMediaDevice.getDevices(mediaDeviceInfo => mediaDeviceInfo.kind === AUDIO_OUTPUT);
    }

    static getVideoInputDevices(): Promise<MediaDevice[]> {
        return RTCMediaDevice.requestPermission({audio: false, video: true})
            .then(() => RTCMediaDevice.getDevices(mediaDeviceInfo => mediaDeviceInfo.kind === VIDEO_INPUT));
    }

    static getMediaStream(deviceId: string): Promise<MediaStream> {
        return RTCMediaDevice.getDevices(mediaDeviceInfo => mediaDeviceInfo.deviceId === deviceId)
            .then(devices => RTCMediaDevice.getMediaStreamForDevice(devices[0]));
    }

    static getAudioMediaStream(deviceId: string | null): Promise<MediaStream> {
        const constraints = { audio: { deviceId: deviceId ?? undefined }, video: false };
        return navigator.mediaDevices.getUserMedia(constraints);
    }

    static getVideoMediaStream(deviceId: string | null, cameraOrientation?: CameraOrientation): Promise<MediaStream> {
        const constraints = {
            audio: false,
            video: { deviceId: deviceId ?? undefined, facingMode: cameraOrientation }
        };
        return navigator.mediaDevices.getUserMedia(constraints);
    }

    static closeMediaStream(mediaStream: MediaStream) {
        mediaStream.getTracks().forEach(track => track.stop())
    }

    private static getDevices(filterPredicate: (mediaDeviceInfo: MediaDeviceInfo) => boolean): Promise<MediaDevice[]> {
        return navigator.mediaDevices.enumerateDevices()
            .then(devices => _.uniqBy(devices, 'deviceId'))
            .then(mediaDeviceInfos => mediaDeviceInfos.filter(filterPredicate).map(RTCMediaDevice.toMediaDevice));
    }

    private static getMediaStreamForDevice(mediaDevice: MediaDevice): Promise<MediaStream> {
        if (mediaDevice.kind.endsWith("output")) {
            return Promise.reject("Cannot get media stream for output device.");
        }
        if (mediaDevice.kind.startsWith("video")) {
            return RTCMediaDevice.getVideoMediaStream(mediaDevice.deviceId);
        } else {
            return RTCMediaDevice.getAudioMediaStream(mediaDevice.deviceId);
        }
    }

    private static toMediaDevice(mediaDeviceInfo: any) {
        return new MediaDevice(mediaDeviceInfo.deviceId, mediaDeviceInfo.groupId, mediaDeviceInfo.kind, mediaDeviceInfo.label);
    }

    private static async requestPermission(constraints: any) {
        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        RTCMediaDevice.closeMediaStream(stream);
    }
}

