import {ApplicationCall} from "../ApplicationCall";
import {CameraOrientation} from "../options/CameraOrientation";
import {Audio, Media, Participant, State} from "../../util/Participant";
import {CallStatus} from "../CallStatus";
import {EventEmitter} from "events";
import {InfobipGateway} from "../../gateway/InfobipGateway";
import {Logger} from "../../log/Logger";
import {Device} from "../../device/Device";
import MonitoredPeerConnection from "../../log/monitor/MonitoredPeerConnection";
import LocalMedia from "../../util/LocalMedia";
import {MediaUpdateStatus} from "./MediaUpdateStatus";
import {v4 as uuid} from "uuid";
import {ErrorLog} from "../../log/Log";
import HangupReasonFactory from "./util/HangupReasonFactory";
import HangupStatusFactory from "../util/HangupStatusFactory";
import {MediaType} from "../../log/util/MediaType";
import SimulcastEncodings from "../../util/SimulcastEncodings";
import VideoType from "../../util/VideoType";
import {ApplicationErrorCode} from "../ApplicationErrorCode";
import {WsEvent} from "../ws/WsEvent";
import RemoteVideo from "./RemoteVideo";
import Browser from "../../util/Browser";
import {ApiEventEmitter} from "../../util/ApiEventEmitter";
import CallProperties from "./CallProperties";
import {
    Endpoint,
    EndpointType,
    PhoneEndpoint,
    SipEndpoint,
    ViberEndpoint,
    WebrtcEndpoint,
    WhatsAppEndpoint
} from "../../util/Endpoint";
import {WsAction} from "../ws/WsAction";
import {EmptyAudioStream} from "./EmptyAudioStream";
import {PeerConnectionTag} from "../../log/monitor/media/PeerConnectionTag";
import {VideoFilter} from "../options/filters/VideoFilter";
import {ManagedVideoFilter} from "../options/filters/ManagedVideoFilter";
import {applyAudioFilter, applyVideoFilter} from "../options/filters/FilterUtils";
import {NetworkQualityStatistics} from "../../log/monitor/media/NetworkQualityStatistics";
import {NetworkQuality} from "../event/NetworkQuality";
import {NetworkQualityMonitor} from "./network/NetworkQualityMonitor";
import {AudioFilter} from "../options/filters/AudioFilter";
import {ManagedAudioFilter} from "../options/filters/ManagedAudioFilter";
import {CustomData} from "../CustomDataType";
import {AnyCallsApiEvent, CallsApiEvent, CallsApiEvents} from "../event/CallsApiEvents";
import {CallsEventHandlers} from "../event/CallsEventHandlers";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import {
    DialogCreatedEvent,
    DialogEstablishedEvent,
    DialogFailedEvent,
    DialogFinishedEvent
} from "../ws/event/DialogEvents";
import {
    ConferenceParticipant,
    JoinedApplicationConferenceEvent,
    LeftApplicationConferenceEvent,
    ParticipantJoinedEvent,
    ParticipantJoiningEvent,
    ParticipantLeftEvent,
    ParticipantMediaChangedEvent,
    ParticipantNetworkQualityEvent,
    ParticipantStartedTalkingEvent,
    ParticipantStoppedTalkingEvent
} from "../ws/event/ConferenceEvents";
import {
    JoinVideoConferenceError,
    PublishedVideoConferenceEvent,
    PublishVideoConferenceError
} from "../ws/event/ConferenceVideoPublishEvents";
import {
    StreamsMap,
    SubscribedVideoEvent,
    SubscribeVideoConferenceErrorEvent,
    UpdatedVideoEvent
} from "../ws/event/ConferenceVideoSubscribeEvents";
import {CallAcceptedEvent, CallErrorEvent, CallResponseEvent, HangupEvent} from "../ws/event/CallEvents";
import {JoinVideoCallError, PublishedVideoCallEvent, PublishVideoCallError} from "../ws/event/CallVideoPublishEvents";
import {TrickleIceEvent} from "../ws/event/IceEvents";
import VideoRemovalReason from "../../util/VideoRemovalReason";
import {DefaultLocalCapturer} from "./DefaultLocalCapturer";
import {LocalCapturer} from "../LocalCapturer";
import {ServerCapturer} from "../ServerCapturer";
import {DefaultServerCapturer} from "./DefaultServerCapturer";
import {CurrentMediaStats} from "../stats/CurrentMediaStats";
import {TotalMediaStats} from "../stats/TotalMediaStats";
import {DataChannel} from "../DataChannel";
import {SetupDataChannelErrorEvent, SetupDataChannelEvent} from "../ws/event/DataChannelEvents";
import {DefaultDataChannel} from "./DefaultDataChannel";
import {ReconnectHandler} from "../ReconnectHandler";
import {AudioOptions} from "../options/AudioOptions";
import {VideoOptions} from "../options/VideoOptions";
import {AudioQualityMode} from "../options/AudioQualityMode";
import {DisplayOptions, DisplaySurface} from "../options/DisplayOptions";
import {RTCMediaDevice} from "../../RTCMediaDevice";
import {BitrateConfig, configureForSending} from "./util/BitrateUtil";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";
import ReconnectingEvent = CallsApiEvents.ReconnectingEvent;

type RemoteVideoMap = { [mid: string]: RemoteVideo };
type ParticipantMap = { [identity: string]: Participant };

const CAMERA_VIDEO_ENCODINGS = Browser.isMobile() ? SimulcastEncodings.cameraEncodingsMobile : SimulcastEncodings.cameraEncodings;
const LOW_DATA_AUDIO_BITRATE = 10_000;

export abstract class DefaultApplicationCall implements ApplicationCall {
    protected callId: string;
    protected conferenceId: string;
    protected dialogId: string;
    protected audioPC: MonitoredPeerConnection;
    private videoPublisherPC: MonitoredPeerConnection;
    private videoSubscriberPC: MonitoredPeerConnection;
    protected localAudio: LocalMedia;
    protected emptyAudioStream: EmptyAudioStream;
    protected localCameraVideo: LocalMedia;
    private localScreenShare: LocalMedia;
    protected localAudioStream?: MediaStream;
    private remoteAudioStream?: MediaStream;
    private localCameraVideoStream?: MediaStream;
    private localScreenShareStream?: MediaStream;
    private hasRemoteDescription: boolean = false;
    private mediaUpdateStatus: MediaUpdateStatus;
    private remoteCandidates: string[] = [];
    protected callStatus: CallStatus;
    private apiEventEmitter: ApiEventEmitter;
    private isEarlyMedia = false;
    private callStartTime: Date;
    private callEstablishTime: Date;
    private callEndTime: Date;
    private joinedConference: boolean = false;
    private joinedDialog: boolean = false;
    private participants: ParticipantMap = {};
    private remoteVideos: RemoteVideoMap = {};
    private dtmfSender: RTCDTMFSender;
    private dtmfUnavailable: boolean = false;
    private _videoFilter: ManagedVideoFilter = null;
    private _audioFilter: ManagedAudioFilter = null;
    private networkQualityMonitor: NetworkQualityMonitor;
    protected defaultLocalCaputuer: LocalCapturer;
    protected defaultServerCapturer: ServerCapturer;
    private _dataChannel: DefaultDataChannel;
    protected reconnectHandler: ReconnectHandler;
    private reconnecting: boolean = false;
    private _audioQualityMode: AudioQualityMode;

    private reconnectingHandler = (event: any) => this.handleReconnecting(event);
    private reconnectedHandler = (event: any) => this.handleReconnected(event);

    constructor(protected eventEmitter: EventEmitter,
                protected gateway: InfobipGateway,
                protected logger: Logger,
                protected rtcConfig: any,
                private device: Device,
                protected _applicationId: string,
                protected applicationCallOptions: ApplicationCallOptions,
                public currentUserIdentity: string,
                private token: string,
                private apiUrl: string,
                callId: string = null) {
        this.callStartTime = new Date();
        this.callStatus = CallStatus.INITIALIZED;
        this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
        this.apiEventEmitter = new ApiEventEmitter();
        this.callId = callId || uuid();
        this.localScreenShare = {active: false};
        this.networkQualityMonitor = new NetworkQualityMonitor();
        this._videoFilter = ManagedVideoFilter.createWrapped(this.applicationCallOptions?.videoOptions?.videoFilterFactory);
        this._audioFilter = ManagedAudioFilter.createWrapped(this.applicationCallOptions?.audioOptions?.audioFilterFactory);
        this._audioQualityMode = this.applicationCallOptions?.audioOptions?.audioQualityMode || AudioQualityMode.AUTO;

        if (this.applicationCallOptions?.dataChannel) {
            this.createDataChannel();
        }

        this.initEventHandlers();
        this.handleDeviceChange();
    }

    on(name: AnyCallsApiEvent, handler: CallsEventHandlers.Any): void {
        if (!Object.values(CallsApiEvent)
            .find(apiEvent => apiEvent === name)) {
            throw new Error(`Unknown event: ${name}!`);
        }
        this.apiEventEmitter.on(name, handler); // TODO once for established, hangup...
    }

    id(): string {
        return this.callId;
    }

    options(): ApplicationCallOptions {
        return this.applicationCallOptions;
    }

    customData(): CustomData {
        return this.applicationCallOptions?.customData;
    }

    applicationId(): string {
        return this._applicationId;
    }

    duration(): number {
        if (!this.callEstablishTime) {
            return 0;
        }
        return !this.callEndTime ? this.getDurationInSeconds(new Date()) : this.getDurationInSeconds(this.callEndTime);
    }

    endTime(): Date {
        return this.callEndTime;
    }

    establishTime(): Date {
        return this.callEstablishTime;
    }

    hangup(): void {
        if (this.callStatus !== CallStatus.FINISHED) {
            this.callStatus = CallStatus.FINISHED;
            this.dataChannelCleanup();
            this.scheduleHangup({callId: this.callId, status: {id: '0', name: 'NO_ERROR', description: 'No Error.'}});
            this.gateway.send({action: WsAction.HANGUP, callId: this.callId, reason: 'Normal call clearing'});
        }
    }

    startTime(): Date {
        return this.callStartTime;
    }

    status(): CallStatus {
        return this.callStatus;
    }

    async mute(shouldMute: boolean): Promise<void> {
        if (!this.localAudioStream) {
            throw ApplicationErrorCode.MEDIA_ERROR;
        }

        if (this.emptyAudioStream && !shouldMute) {
            this.localAudio.active = true;
            this.emptyAudioStream.close();
            delete this.emptyAudioStream;

            try {
                const stream = await this.getLocalAudioStream(true, false);
                await applyAudioFilter(this._audioFilter, stream, 0);
                const track = this.setAudioStream(stream);
                await this.replaceTrack(this.localAudio, track);
                this.sendInfo(shouldMute);
            } catch (error) {
                return this.throwMediaError(error);
            }
        }

        let audioTracks = this.localAudioStream.getAudioTracks();
        if (audioTracks.length === 0) {
            throw ApplicationErrorCode.MEDIA_ERROR;
        }
        let audioTrack = audioTracks[0];
        this.localAudio.active = !shouldMute;
        audioTrack.enabled = this.localAudio.active;
        this.sendInfo(shouldMute);
    }

    private setAudioStream(stream: MediaStream) {
        this.localAudioStream = stream;
        return stream.getAudioTracks()[0];
    }

    private sendInfo(shouldMute: boolean) {
        this.gateway.send({
            action: WsAction.MUTE,
            muted: shouldMute,
            callId: this.callId
        });
    }

    muted(): boolean {
        return !this.localAudio.active;
    }

    async sendDTMF(dtmf: string): Promise<void> {
        if (!dtmf || dtmf.toString().match(/[^0-9*#A-D]/)) {
            throw ApplicationErrorCode.MEDIA_ERROR;
        }

        if (!this.dtmfSender) {
            await this.createDTMFSender();
        }

        if (this.dtmfSender && !this.dtmfUnavailable) {
            try {
                this.dtmfSender.insertDTMF(dtmf, CallProperties.DTMF_TONE_DURATION, CallProperties.DTMF_TONE_GAP);
            } catch (e) {
                this.dtmfUnavailable = true;
                this.sendDTMFInfo(dtmf, CallProperties.DTMF_TONE_DURATION);
            }
        } else {
            this.sendDTMFInfo(dtmf, CallProperties.DTMF_TONE_DURATION);
        }
    }

    pauseIncomingVideo() {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.gateway.send({
            action: WsAction.PAUSE_INCOMING_VIDEO
        });
    }

    resumeIncomingVideo() {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.gateway.send({
            action: WsAction.RESUME_INCOMING_VIDEO
        });
    }

    async setAudioInputDevice(deviceId: string): Promise<void> {
        this.device.setAudioInputDevice(deviceId);
        this.localAudioStream?.getAudioTracks().forEach(track => track.stop())

        try {
            const stream = await this.getLocalAudioStream(true, false);
            await applyAudioFilter(this._audioFilter, stream, 0);
            const track = this.setAudioStream(stream);
            track.enabled = this.localAudio.active;
            return this.replaceTrack(this.localAudio, track);
        } catch (error) {
            return this.throwMediaError(error);
        }
    }

    public audioFilter(): AudioFilter {
        return this._audioFilter?.delegate;
    }

    public async setAudioFilter(audioFilter: AudioFilter): Promise<void> {
        if (this._audioFilter?.delegate === audioFilter) {
            return;
        }
        await this._audioFilter?.restore(this.localAudioStream);
        await this._audioFilter?.release();
        this._audioFilter = ManagedAudioFilter.wrap(audioFilter);

        try {
            if (this.localAudioStream) {
                this.localAudioStream.getAudioTracks().forEach((track: MediaStreamTrack) => track.stop());
            }
            const stream = await this.getLocalAudioStream(true, false);
            await applyAudioFilter(this._audioFilter, stream, 0);
            const track = this.setAudioStream(stream);
            track.enabled = this.localAudio.active;
            await this.replaceTrack(this.localAudio, track);
        } catch (error) {
            return this.throwMediaError(error);
        }
    }

    public clearAudioFilter(): Promise<void> {
        return this.setAudioFilter(null);
    }

    public videoFilter(): VideoFilter {
        return this._videoFilter?.delegate;
    }

    public async setVideoFilter(videoFilter: VideoFilter): Promise<void> {
        if (this._videoFilter?.delegate === videoFilter) {
            return;
        }
        if (this.hasCameraVideo()) {
            await this._videoFilter?.restore(this.localCameraVideoStream);
        }
        await this._videoFilter?.release();
        this._videoFilter = ManagedVideoFilter.wrap(videoFilter);
        if (this.hasCameraVideo()) {
            await applyVideoFilter(this._videoFilter, this.localCameraVideoStream, 0);
            await this.updateTransceiver(this.localCameraVideo, this.localCameraVideoStream, CAMERA_VIDEO_ENCODINGS);
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_UPDATED, <CallsApiEvents.CameraVideoUpdatedEvent>{stream: this.localCameraVideoStream});
        }
    }

    public clearVideoFilter(): Promise<void> {
        return this.setVideoFilter(null);
    }

    async setVideoInputDevice(deviceId: string): Promise<void> {
        this.device.setVideoInputDevice(deviceId);
        await this.updateCameraStream(this.cameraOrientation());
    }

    cameraOrientation(): CameraOrientation {
        return this.device.getCameraOrientation();
    }

    setCameraOrientation(cameraOrientation: CameraOrientation): Promise<void> {
        return this.updateCameraStream(cameraOrientation, false);
    }

    localCapturer(): LocalCapturer {
        return this.defaultLocalCaputuer ??= new DefaultLocalCapturer(
            () => this.localCameraVideoStream,
            () => this.localScreenShareStream,
            () => this.videoSubscriberPC?.peerConnection,
            (identity: string, videoType: VideoType) => this.getMidByIdentityAndVideoType(identity, videoType),
            this.currentUserIdentity
        );
    }

    serverCapturer(): ServerCapturer {
        return this.defaultServerCapturer ??= new DefaultServerCapturer(
            () => this.localCameraVideoStream,
            () => this.localScreenShareStream,
            () => this.videoSubscriberPC?.peerConnection,
            (identity: string, videoType: VideoType) => this.getMidByIdentityAndVideoType(identity, videoType),
            this.currentUserIdentity,
            this.token,
            this.apiUrl,
            this.logger
        );
    }

    dataChannel(): DataChannel {
        return this._dataChannel;
    }

    setAudioQualityMode(audioQualityMode: AudioQualityMode): void {
        this._audioQualityMode = audioQualityMode;
        const senders = this.audioPC.peerConnection.getSenders();
        senders.forEach((sender) => {
            if (sender.track.kind !== 'audio') {
                return
            }

            const parameters = sender.getParameters()
            parameters.encodings ??= [{}]

            parameters.encodings.forEach(encoding => {
                switch (audioQualityMode) {
                    case AudioQualityMode.AUTO:
                        encoding.maxBitrate = undefined;
                        encoding.networkPriority = 'low';
                        break;
                    case AudioQualityMode.LOW_DATA:
                        encoding.maxBitrate = LOW_DATA_AUDIO_BITRATE;
                        encoding.networkPriority = 'high'
                        break;
                    case AudioQualityMode.HIGH_QUALITY:
                        encoding.maxBitrate = undefined;
                        encoding.networkPriority = 'high'
                        break;
                }
            })

            sender.setParameters(parameters)
                .catch(err => this.logger.error(`Changing data consumption mode failed: ${err?.message}`))
        });
    }

    audioQualityMode(): AudioQualityMode {
        return this._audioQualityMode;
    }

    private getMidByIdentityAndVideoType(identity: string, type: VideoType): string {
        return Object.values(this.remoteVideos).find(value => value.participant.endpoint.identifier === identity && value.type === type)?.mid;
    }

    hasCameraVideo(): boolean {
        return this.localCameraVideo && this.localCameraVideo.active;
    }

    hasScreenShare(): boolean {
        return this.localScreenShare && this.localScreenShare.active;
    }

    async cameraVideo(localVideo: boolean): Promise<void> {
        this.validateMediaUpdateStatus();
        if (localVideo !== this.localCameraVideo.active) {
            this.localCameraVideo.active = localVideo;
            return localVideo ? this.addCameraVideo() : this.removeCameraVideo();
        }
    }

    async screenShare(screenShare: boolean): Promise<void> {
        this.validateMediaUpdateStatus();
        if (screenShare !== this.localScreenShare.active) {
            this.localScreenShare.active = screenShare;
            return screenShare ? this.addScreenShareVideo() : this.removeScreenShareVideo(VideoRemovalReason.USER_REQUEST);
        }
    }

    async startScreenShare(displayOptions?: DisplayOptions): Promise<void> {
        this.validateMediaUpdateStatus();
        this.localScreenShare.active = true;
        return this.addScreenShareVideo(displayOptions);
    }

    async stopScreenShare(): Promise<void> {
        this.validateMediaUpdateStatus();
        this.localScreenShare.active = false;
        return this.removeScreenShareVideo(VideoRemovalReason.USER_REQUEST)
    }

    private async removeScreenShareVideoWithReason(reason: VideoRemovalReason): Promise<void> {
        this.validateMediaUpdateStatus();
        if (this.localScreenShare.active) {
            this.localScreenShare.active = false;
            return this.removeScreenShareVideo(reason);
        }
    }

    protected createDataChannel() {
        this._dataChannel = new DefaultDataChannel(
            this.gateway,
            this.logger,
            this.callId,
            this.currentUserIdentity,
            identity => this.participants[identity],
            ice => this.onIceCandidate(WsAction.ICE_CANDIDATE_DATA_CHANNEL, ice),
            () => this.joinedDialog || this.joinedConference,
            this.apiEventEmitter,
            this.conferenceId
        );
    }

    private initEventHandlers() {
        // ice
        // TODO trickle for audio PC, video publisher PC, video subscriber PC
        this.eventEmitter.on(WsEvent.TRICKLE_ICE, event => this.handleTrickleIce(event));
        // call
        this.eventEmitter.once(WsEvent.RINGING, () => this.ringingHandler());
        this.eventEmitter.on(WsEvent.CALL_RESPONSE, event => this.responseHandler(event));
        this.eventEmitter.once(WsEvent.CALL_ACCEPTED, event => this.callAcceptedHandler(event));
        this.eventEmitter.once(WsEvent.HANGUP, event => this.hangupHandler(event));
        this.eventEmitter.once(WsEvent.CALL_ERROR, event => this.errorHandler(event));
        // call - video publish
        this.eventEmitter.on(WsEvent.JOINED_VIDEO_CALL, () => this.videoCallJoinedHandler());
        this.eventEmitter.on(WsEvent.PUBLISHED_VIDEO_CALL, event => this.videoCallPublishedHandler(event));
        this.eventEmitter.on(WsEvent.UNPUBLISHED_VIDEO_CALL, () => this.videoCallUnpublishedHandler());
        this.eventEmitter.on(WsEvent.JOIN_VIDEO_CALL_ERROR, event => this.handleJoinVideoCallError(event));
        this.eventEmitter.on(WsEvent.PUBLISH_VIDEO_CALL_ERROR, event => this.publishVideoCallErrorHandler(event));
        // conference
        this.eventEmitter.on(WsEvent.JOINED_APPLICATION_CONFERENCE, event => this.handleJoinedApplicationConference(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_JOINING, event => this.handleParticipantJoining(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_JOINED, event => this.handleParticipantJoined(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_MEDIA_CHANGED, event => this.handleParticipantMediaChanged(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_STARTED_TALKING, event => this.handleParticipantStartedTalking(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_STOPPED_TALKING, event => this.handleParticipantStoppedTalking(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_LEFT, event => this.handleParticipantLeft(event));
        this.eventEmitter.on(WsEvent.LEFT_APPLICATION_CONFERENCE, event => this.handleLeftApplicationConference(event));
        // conference - video publish
        this.eventEmitter.on(WsEvent.JOINED_VIDEO_CONFERENCE, () => this.handleJoinedVideoConference());
        this.eventEmitter.on(WsEvent.PUBLISHED_VIDEO_CONFERENCE, event => this.videoConferencePublishedHandler(event));
        this.eventEmitter.on(WsEvent.UNPUBLISHED_VIDEO_CONFERENCE, () => this.videoConferenceUnpublishedHandler());
        this.eventEmitter.on(WsEvent.JOIN_VIDEO_CONFERENCE_ERROR, event => this.handleJoinVideoConferenceError(event));
        this.eventEmitter.on(WsEvent.PUBLISH_VIDEO_CONFERENCE_ERROR, event => this.publishVideoConferenceErrorHandler(event));
        // conference - video subscribe
        this.eventEmitter.on(WsEvent.SUBSCRIBED_VIDEO, event => this.subscribedVideoHandler(event));
        this.eventEmitter.on(WsEvent.SUBSCRIBE_VIDEO_CONFERENCE_ERROR, event => this.subscribeVideoConferenceError(event));
        this.eventEmitter.on(WsEvent.UPDATED_VIDEO, event => this.updatedVideoHandler(event));
        // dialog
        this.eventEmitter.on(WsEvent.DIALOG_CREATED, event => this.handleDialogCreated(event));
        this.eventEmitter.on(WsEvent.DIALOG_ESTABLISHED, event => this.handleDialogEstablished(event));
        this.eventEmitter.on(WsEvent.DIALOG_FINISHED, event => this.handleDialogFinished(event));
        this.eventEmitter.on(WsEvent.DIALOG_FAILED, event => this.handleDialogFailed(event));
        this.eventEmitter.on("reconnecting", this.reconnectingHandler);
        this.eventEmitter.on("reconnected", this.reconnectedHandler);
        // data channel
        this.eventEmitter.on(WsEvent.SETUP_DATA_CHANNEL, event => this.handleSetupDataChannel(event));
        this.eventEmitter.on(WsEvent.SETUP_DATA_CHANNEL_ERROR, event => this.setupDataChannelError(event));
        this.eventEmitter.on(WsEvent.PARTICIPANT_NETWORK_QUALITY, event => this.handleParticipantNetworkQuality(event));
    }

    private handleDeviceChange() {
        navigator.mediaDevices.ondevicechange = async () => {
            let currentlyUsedDeviceId = this.device.getAudioInputDevice() || "default";
            if (currentlyUsedDeviceId === "default") {
                this.switchToDefaultDevice();
            } else {
                let shouldChange = await this.device.audioInputDeviceShouldChange();
                if (shouldChange) {
                    this.switchToDefaultDevice();
                }
            }
        }
    }

    private switchToDefaultDevice(): void {
        this.setAudioInputDevice("default")
            .catch(err => {
                this.logger.error(`Switching audio input device failed (${err?.message})`, this.callId)
            })
    }

    protected createAudioPeerConnection(): void {
        this.audioPC = MonitoredPeerConnection.create(
            this.rtcConfig,
            this.callId,
            PeerConnectionTag.Audio,
            this.conferenceId || this.dialogId,
            MediaType.AUDIO,
            this.logger
        );
        this.audioPC.peerConnection.onicecandidate = ice => this.onIceCandidate(WsAction.ICE_CANDIDATE, ice);
        this.audioPC.peerConnection.ontrack = event => this.onAudioPcTrack(event);
        this.audioPC.peerConnection.onconnectionstatechange = () => this.onConnectionStateChanged();
        this.audioPC.monitor.onNetworkQualityStatistics(
            (networkQualityStatistics, currentMediaStats) =>
                this.onNetworkQualityStatisticsChanged(networkQualityStatistics, currentMediaStats)
        );
    }

    private updateVideoBitrate() {
        if (this.videoPublisherPC.peerConnection.connectionState !== 'connected') {
            return;
        }
        let desiredBitrate = [
            this.hasCameraVideo() ? BitrateConfig.CAMERA : 0,
            this.hasScreenShare() ? BitrateConfig.SCREENSHARE : 0
        ].reduce((prev, curr) => prev + curr, 0);
        configureForSending(this.videoPublisherPC.peerConnection, "video", desiredBitrate, this.logger);
    }

    private onVideoPublisherConnectionStateChanged() {
        this.updateVideoBitrate();
    }

    private onConnectionStateChanged() {
        if (this.audioPC.peerConnection.connectionState === 'connected' && this._audioQualityMode !== AudioQualityMode.AUTO) {
            this.setAudioQualityMode(this._audioQualityMode)
        }
    }

    protected setLocalAudioStream(audioStream: MediaStream) {
        this.localAudioStream = audioStream;
        let track = this.localAudioStream.getAudioTracks()[0];
        if (!track) {
            this.emptyAudioStream = new EmptyAudioStream();
            return;
        }
        track.enabled = this.localAudio.active;
    }

    protected createAudioTransceiver() {
        let stream = this.emptyAudioStream ? this.emptyAudioStream.stream() : this.localAudioStream;
        let track = stream.getAudioTracks()[0];

        this.localAudio.transceiver = this.audioPC.peerConnection.addTransceiver(track, {
            streams: [stream],
            direction: 'sendrecv'
        });
    }

    protected async setLocalDescription(pc: RTCPeerConnection, localDescription: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
        await pc.setLocalDescription(localDescription);
        return localDescription;
    }

    private ringingHandler() {
        this.callStatus = CallStatus.RINGING;
        this.apiEventEmitter.emit(CallsApiEvent.RINGING, <CallsApiEvents.RingingEvent>{});
    }

    private responseHandler(event: CallResponseEvent) {
        this.isEarlyMedia = event.isEarlyMedia;

        this.audioPC.peerConnection.setRemoteDescription(event.description)
            .then(() => this.setRemoteCandidates())
            .catch(error => this.handleCallFlowError(error, true));
    }

    private callAcceptedHandler(event: CallAcceptedEvent) {
        this.callStatus = CallStatus.ESTABLISHED;
        this.callEstablishTime = new Date();
        this.apiEventEmitter.emit(CallsApiEvent.ESTABLISHED, <CallsApiEvents.EstablishedEvent>{stream: this.remoteAudioStream});
    }

    private videoCallJoinedHandler() {
        if (this.joinedConference || this.joinedDialog) {
            return;
        }
        if (this.hasCameraVideo() || this.hasScreenShare()) {
            this.validateMediaUpdateStatus();
            this.mediaUpdateStatus = MediaUpdateStatus.ADDING_VIDEO;
            this.publishVideo(false);
        }
    }

    private changeMonitorConferenceId(conferenceId: string) {
        if (this.audioPC) {
            this.audioPC.monitor.conferenceId = conferenceId;
        }
        if (this.videoPublisherPC) {
            this.videoPublisherPC.monitor.conferenceId = conferenceId;
        }
        if (this.videoSubscriberPC) {
            this.videoSubscriberPC.monitor.conferenceId = conferenceId;
        }
    }

    private async handleSetupDataChannel(event: SetupDataChannelEvent) {
        this._dataChannel?.initialize(event, this.rtcConfig);
    }

    private handleJoinedApplicationConference(event: JoinedApplicationConferenceEvent) {
        this.joinedConference = true;
        this.conferenceId = event.id;
        this.changeMonitorConferenceId(event.id);
        this.participants = this.createParticipantMap(event.participants);

        this.apiEventEmitter.emit(CallsApiEvent.CONFERENCE_JOINED, <CallsApiEvents.ConferenceJoinedEvent>{
            id: event.id,
            name: event.name,
            participants: Object.values(this.participants)
        });
    }

    private handleDialogCreated(event: DialogCreatedEvent) {
        this.joinedDialog = true;
        this.dialogId = event.id;
        this.changeMonitorConferenceId(event.id);

        this.participants = this.createParticipantMap(event.participants);
        const participant = Object.values(this.participants)
            .find(participant => participant.endpoint.identifier !== this.currentUserIdentity);

        this.apiEventEmitter.emit(CallsApiEvent.DIALOG_JOINED, <CallsApiEvents.DialogJoinedEvent>{
            id: event.id,
            remote: participant
        });
    }

    private createParticipantMap(participants: ConferenceParticipant[]) {
        return participants.reduce((map: ParticipantMap, p: ConferenceParticipant) => {
            let participant = this.loadParticipant(p);
            let identifier = participant.endpoint.identifier;
            map[identifier] = participant;
            return map;
        }, {});
    }

    private handleDialogEstablished(event: DialogEstablishedEvent) {
        this.participants = this.createParticipantMap(event.participants);
        const participant = Object.values(this.participants)
            .find(participant => participant.endpoint.identifier !== this.currentUserIdentity);

        if (!this.joinedDialog) {
            this.joinedDialog = true;
            this.dialogId = event.id;
            this.changeMonitorConferenceId(event.id);

            this.apiEventEmitter.emit(CallsApiEvent.DIALOG_JOINED, <CallsApiEvents.DialogJoinedEvent>{
                id: event.id,
                remote: participant
            });
        }

        if (participant.media.audio.muted) {
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_MUTED, <CallsApiEvents.ParticipantMutedEvent>{participant: participant});
        }
    }

    private handleParticipantJoining(event: ParticipantJoiningEvent) {
        if (this.joinedConference) {
            const participant = this.loadParticipant(event.participant);
            const identifier = participant.endpoint.identifier;
            if (!this.participants[identifier]) {
                this.participants[identifier] = participant;
                this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_JOINING, <CallsApiEvents.ParticipantJoiningEvent>{participant: participant});
            }
        }
    }

    private handleParticipantJoined(event: ParticipantJoinedEvent) {
        if (this.joinedConference) {
            const participant = this.loadParticipant(event.participant);
            const identifier = participant.endpoint.identifier;
            if (!this.participants[identifier] || this.participants[identifier].state === State.JOINING) {
                this.participants[identifier] = participant;
                this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_JOINED, <CallsApiEvents.ParticipantJoinedEvent>{participant: participant});
            }
        }
    }

    private handleParticipantMediaChanged(event: ParticipantMediaChangedEvent) {
        if (this.joinedConference || this.joinedDialog) {
            const endpoint = this.getEndpoint(event.endpoint);
            const identifier = endpoint.identifier;
            if (this.participants[identifier]) {
                let participant = this.participants[identifier];
                if (event.media.audio?.muted !== undefined) {
                    participant.media.audio.muted = event.media.audio.muted;
                    this.emitMutedEvent(event, participant);
                }
                if (event.media.audio?.deaf !== undefined && !this.joinedDialog) {
                    participant.media.audio.deaf = event.media.audio.deaf;
                    this.emitDeafEvent(event, participant);
                }
            }
        }
    }

    private emitDeafEvent(event: any, participant: Participant) {
        let eventType = event.media.audio.deaf ? CallsApiEvent.PARTICIPANT_DEAF
            : CallsApiEvent.PARTICIPANT_UNDEAF;
        this.apiEventEmitter.emit(eventType, <CallsApiEvents.ParticipantDeafEvent>{participant: participant});
    }

    private emitMutedEvent(event: any, participant: Participant) {
        let eventType = event.media.audio.muted ? CallsApiEvent.PARTICIPANT_MUTED
            : CallsApiEvent.PARTICIPANT_UNMUTED;
        this.apiEventEmitter.emit(eventType, <CallsApiEvents.ParticipantMutedEvent>{participant: participant});
    }

    private handleParticipantStartedTalking(event: ParticipantStartedTalkingEvent) {
        if (this.joinedConference) {
            const endpoint = this.getEndpoint(event.endpoint);
            const identifier = endpoint.identifier;
            if (this.participants[identifier]) {
                let participant = this.participants[identifier];
                participant.media.audio.talking = true;
                this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_STARTED_TALKING, <CallsApiEvents.ParticipantStartedTalkingEvent>{participant: participant});
            }
        }
    }

    private handleParticipantStoppedTalking(event: ParticipantStoppedTalkingEvent) {
        if (this.joinedConference) {
            const endpoint = this.getEndpoint(event.endpoint);
            const identifier = endpoint.identifier;
            if (this.participants[identifier]) {
                let participant = this.participants[identifier];
                participant.media.audio.talking = false;
                this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_STOPPED_TALKING, <CallsApiEvents.ParticipantStoppedTalkingEvent>{participant: participant});
            }
        }
    }

    private handleParticipantLeft(event: ParticipantLeftEvent) {
        if (this.joinedConference) {
            const participant = this.loadParticipant(event.participant);
            const identifier = participant.endpoint.identifier;
            delete this.participants[identifier];
            this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_LEFT, <CallsApiEvents.ParticipantRemovedEvent>{participant: participant});
        }
    }

    private async handleJoinedVideoConference() {
        this.videoPublisherCleanup(true);

        if (this.hasCameraVideo() || this.hasScreenShare()) {
            await this.migrateLocalVideos();

            try {
                await this.negotiateVideoPublisher(true);
            } catch (error) {
                this.handlePublishVideoFlowError(error);
            }
        }
    }

    private subscribedVideoHandler(event: SubscribedVideoEvent) {
        this.remoteVideos = this.mapStreamEvent(event.streams);
        this.createVideoSubscriberPC();
        this.videoSubscriberPC.peerConnection.setRemoteDescription(event.description)
            .then(() => this.videoSubscriberPC.peerConnection.createAnswer())
            .then(answer => this.setLocalDescription(this.videoSubscriberPC.peerConnection, answer))
            .then(answer => {
                this.gateway.send({
                    action: WsAction.START_VIDEO_CONFERENCE,
                    description: answer
                });
            })
            .catch(error => this.handleSubscribeVideoFlowError(error));
    }

    private handleSubscribeVideoFlowError(error: string) {
        this.logger.error(`Subscribe video flow error occurred: ${error}`, this.callId);
        this.videoSubscriberCleanup();
        let errorCode = HangupStatusFactory.getApplicationHangupStatus(error);
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode});
    }

    private updatedVideoHandler(event: UpdatedVideoEvent) {
        Object.keys(this.remoteVideos)
            .filter(mid => !event.streams[mid])
            .forEach(mid => {
                let remoteVideo = this.remoteVideos[mid];
                delete this.remoteVideos[mid];
                this.apiEventEmitter.emit(remoteVideo.type === VideoType.CAMERA ?
                    CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED :
                    CallsApiEvent.PARTICIPANT_SCREEN_SHARE_REMOVED, <CallsApiEvents.ParticipantCameraVideoAddedEvent>{
                    participant: remoteVideo.participant
                });
            });
        this.remoteVideos = this.mapStreamEvent(event.streams);
        this.videoSubscriberPC.peerConnection.setRemoteDescription(event.description)
            .then(() => this.videoSubscriberPC.peerConnection.createAnswer())
            .then(answer => this.setLocalDescription(this.videoSubscriberPC.peerConnection, answer))
            .then(answer => {
                this.gateway.send({
                    action: WsAction.START_VIDEO_CONFERENCE,
                    description: answer
                });
            })
            .catch(error => this.handleSubscribeVideoFlowError(error));
    }

    private async updateCameraStream(cameraOrientation: CameraOrientation = CameraOrientation.FRONT, useExactDevice: boolean = true): Promise<void> {
        if (!this.hasCameraVideo()) {
            throw new Error("Camera video is not enabled.");
        }
        this.localCameraVideoStream?.getVideoTracks().forEach(track => track.stop());
        try {
            const stream = await this.getCameraVideoStream(cameraOrientation, useExactDevice);
            this.localCameraVideoStream = stream;
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_UPDATED, <CallsApiEvents.CameraVideoUpdatedEvent>{stream: stream});
            await applyVideoFilter(this._videoFilter, stream, 0);
            return this.replaceTrack(this.localCameraVideo, stream.getVideoTracks()[0]);
        } catch (error) {
            this.throwMediaError(error);
        }
    }

    private async handleLeftApplicationConference(event: LeftApplicationConferenceEvent) {
        this.joinedConference = false;
        this.conferenceId = null;
        this.changeMonitorConferenceId(null);
        this.participants = {};
        this.remoteVideos = {};
        this.videoPublisherCleanup(true);
        this.videoSubscriberCleanup();
        this.dataChannelCleanup();

        if (this.hasCameraVideo() || this.hasScreenShare()) {
            await this.migrateLocalVideos();
        }

        this.apiEventEmitter.emit(CallsApiEvent.CONFERENCE_LEFT, <CallsApiEvents.ConferenceLeftEvent>{errorCode: event.status});
    }

    private async handleDialogFinished(event: DialogFinishedEvent) {
        this.joinedDialog = false;
        this.dialogId = null;
        this.changeMonitorConferenceId(null);
        this.participants = {};
        this.remoteVideos = {};
        this.videoPublisherCleanup(true);
        this.videoSubscriberCleanup();
        this.dataChannelCleanup();

        if (this.hasCameraVideo() || this.hasScreenShare()) {
            await this.migrateLocalVideos();
        }

        this.apiEventEmitter.emit(CallsApiEvent.DIALOG_LEFT, <CallsApiEvents.DialogLeftEvent>{errorCode: event.status});
    }

    private async handleReconnecting(event: any) {
        if (!this.reconnectHandler) {
            this.logger.debug("Websocket is reconnecting, but the active call doesn't support reconnect. Hanging up...");
            this.hangupHandler({callId: this.callId, status: ApplicationErrorCode.NETWORK_ERROR});
            return;
        }
        this.audioCleanup();
        this.videoPublisherCleanup(true);
        this.videoSubscriberCleanup();
        this.dataChannelCleanup();
        this.reconnecting = true;
        this.apiEventEmitter.emit(CallsApiEvent.RECONNECTING, <ReconnectingEvent>{});
    }

    private async handleReconnected(event: any) {
        if (!this.reconnectHandler) {
            return;
        }
        const newCallId = uuid();
        const oldCallId = this.callId;
        this.logger.info(`Reconnected call ${oldCallId} with new call ${newCallId}`, oldCallId)
        this.callId = newCallId;
        this.createAudioPeerConnection();
        await this.negotiateAudio(this.reconnectHandler(oldCallId, this.createCallOptions()));
    }

    protected abstract negotiateAudio(options?: ApplicationCallOptions): Promise<void>;

    private createCallOptions(): ApplicationCallOptions {
        const oldOptions = this.options() || ApplicationCallOptions.builder().build();
        const isInternal: boolean = oldOptions instanceof InternalApplicationCallOptions

        let applicationCallOptions = ApplicationCallOptions.builder()
            .setAudioOptions(AudioOptions.builder()
                .setAudioFilterFactory(oldOptions.audioOptions?.audioFilterFactory)
                .setAudioQualityMode(this._audioQualityMode)
                .build())
            .setVideoOptions(VideoOptions.builder()
                .setVideoMode(oldOptions.videoOptions?.videoMode)
                .setVideoFilterFactory(oldOptions.videoOptions?.videoFilterFactory)
                .setCameraOrientation(this.cameraOrientation())
                .setCameraVideoFrameRate(oldOptions.videoOptions?.cameraVideoFrameRate)
                .setScreenShareFrameRate(oldOptions.videoOptions?.screenShareFrameRate)
                .build())
            .setAudio(!this.muted())
            .setVideo(this.hasCameraVideo())
            .setCustomData(oldOptions.customData)
            .setDataChannel(oldOptions.dataChannel)
            .setEntityId(oldOptions.entityId)
            .build();

        if (isInternal) {
            const internalCustomData = (<InternalApplicationCallOptions>oldOptions)?.internalCustomData();
            applicationCallOptions = new InternalApplicationCallOptions(applicationCallOptions, internalCustomData);
        }

        return applicationCallOptions;
    }

    private async handleDialogFailed(event: DialogFailedEvent) {
        this.joinedDialog = false;
        this.dialogId = null;
        this.changeMonitorConferenceId(null);
        this.participants = {};
        this.remoteVideos = {};
        this.videoPublisherCleanup(true);
        this.videoSubscriberCleanup();
        this.dataChannelCleanup();

        if (this.hasCameraVideo() || this.hasScreenShare()) {
            await this.migrateLocalVideos();
        }

        this.apiEventEmitter.emit(CallsApiEvent.DIALOG_LEFT, <CallsApiEvents.DialogLeftEvent>{errorCode: event.status});
    }

    private videoSubscriberCleanup() {
        if (this.videoSubscriberPC) {
            this.videoSubscriberPC.close();
            this.videoSubscriberPC = null;
        }
    }

    private dataChannelCleanup() {
        this._dataChannel?.destroy();
    }

    private handleJoinVideoCallError(event: JoinVideoCallError) {
        if (this.joinedConference || this.joinedDialog) {
            return;
        }
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode: event.status});
        this.videoSubscriberCleanup();
        this.videoPublisherCleanup();
    }

    private handleJoinVideoConferenceError(event: JoinVideoConferenceError) {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode: event.status});
        this.dataChannelCleanup();
        this.videoSubscriberCleanup();
        this.videoPublisherCleanup();
    }

    private subscribeVideoConferenceError(event: SubscribeVideoConferenceErrorEvent) {
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode: event.status});
        this.videoSubscriberCleanup();
    }

    private setupDataChannelError(event: SetupDataChannelErrorEvent) {
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, {errorCode: event.status});
        this.dataChannelCleanup();
    }

    private handleParticipantNetworkQuality(event: ParticipantNetworkQualityEvent) {
        const participant = this.participants[event.endpoint.identity];
        this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_NETWORK_QUALITY_CHANGED, <CallsApiEvents.ParticipantNetworkQualityChangedEvent>{
            networkQuality: event.networkQuality,
            participant
        });
    }

    private mapStreamEvent(streams: StreamsMap): RemoteVideoMap {
        return Object.keys(streams).reduce((videos: RemoteVideoMap, mid: string) => ({
            ...videos,
            [mid]: {
                mid: mid,
                participant: this.participants[streams[mid].participant],
                type: VideoType[streams[mid].type]
            }
        }), {});
    }

    private createVideoSubscriberPC() {
        this.videoSubscriberPC = MonitoredPeerConnection.create(
            this.rtcConfig,
            this.callId,
            PeerConnectionTag.VideoSubscription,
            this.conferenceId || this.dialogId,
            MediaType.VIDEO,
            this.logger
        );
        this.videoSubscriberPC.peerConnection.onicecandidate = ice => this.onIceCandidate(WsAction.ICE_CANDIDATE_VIDEO_CONFERENCE, ice, 'subscriber');
        this.videoSubscriberPC.peerConnection.ontrack = event => this.onVideoPcTrack(event);
    }

    private onVideoPcTrack(event: RTCTrackEvent) {
        if (event.track.kind === 'video') {
            let remoteVideo = this.remoteVideos[event.transceiver.mid];
            if (remoteVideo.type === VideoType.SCREENSHARE && this.hasScreenShare()) {
                this.removeScreenShareVideoWithReason(VideoRemovalReason.ACTIVE_PRESENTER_CHANGED);
            }
            if (remoteVideo.type === VideoType.CAMERA) {
                this.apiEventEmitter.emit(CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED, <CallsApiEvents.ParticipantCameraVideoAddedEvent>{
                    participant: remoteVideo.participant,
                    stream: new MediaStream([event.track])
                });
            } else {
                this.apiEventEmitter.emit(
                    CallsApiEvent.PARTICIPANT_SCREEN_SHARE_ADDED, <CallsApiEvents.ParticipantScreenShareAddedEvent>{
                        participant: remoteVideo.participant,
                        stream: new MediaStream([event.track])
                    });
            }
        }
    }

    private videoCallPublishedHandler(event: PublishedVideoCallEvent) {
        if (this.joinedConference || this.joinedDialog) {
            return;
        }
        this.videoPublisherPC.peerConnection.setRemoteDescription(event.description)
            .then(() => {
                this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
            })
            .then(() => this.updateVideoBitrate())
            .catch(error => this.handlePublishVideoFlowError(error));
    }

    private videoConferencePublishedHandler(event: PublishedVideoConferenceEvent) {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.videoPublisherPC.peerConnection.setRemoteDescription(event.description)
            .then(() => {
                this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
            })
            .then(() => this.updateVideoBitrate())
            .catch(error => this.handlePublishVideoFlowError(error));
    }

    private publishVideoCallErrorHandler(event: PublishVideoCallError) {
        if (this.joinedConference || this.joinedDialog) {
            return;
        }
        this.videoPublisherCleanup();
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode: event.status});
    }

    private publishVideoConferenceErrorHandler(event: PublishVideoConferenceError) {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.videoPublisherCleanup();
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode: event.status});
    }

    private videoCallUnpublishedHandler() {
        if (this.joinedConference || this.joinedDialog) {
            return;
        }
        this.videoPublisherCleanup();
    }

    private videoConferenceUnpublishedHandler() {
        if (!this.joinedConference && !this.joinedDialog) {
            return;
        }
        this.videoPublisherCleanup();
    }

    private hangupHandler(event: HangupEvent) {
        this.callStatus = CallStatus.FINISHED;
        this.callEndTime = new Date();
        this.eventEmitter.emit('call_finished', {});
        let totalMediaStats = this.audioCleanup();
        this.apiEventEmitter.emit(CallsApiEvent.HANGUP, <CallsApiEvents.HangupEvent>{
            errorCode: event.status,
            totalMediaStats: totalMediaStats
        });
        this.cleanup();
    }

    private errorHandler(event: CallErrorEvent) {
        this.logger.log(new ErrorLog(this.callId, event.status));
        this.callStatus = CallStatus.FINISHED;
        this.callEndTime = new Date();
        this.eventEmitter.emit('call_finished', {});
        let totalMediaStats = this.audioCleanup();
        this.apiEventEmitter.emit(CallsApiEvent.HANGUP, <CallsApiEvents.HangupEvent>{
            errorCode: event.status,
            totalMediaStats: totalMediaStats
        });
        this.cleanup();
    }

    private async cleanup() {
        this.dataChannelCleanup();
        this.videoPublisherCleanup();
        this.videoSubscriberCleanup();
        Object.values(WsEvent).forEach(name => this.eventEmitter.removeAllListeners(name));
        this.eventEmitter.removeListener("reconnected", this.reconnectedHandler);
        this.eventEmitter.removeListener("reconnecting", this.reconnectingHandler);
        Object.values(CallsApiEvent).forEach(eventName => this.apiEventEmitter.removeAllListeners(eventName));
        navigator.mediaDevices.ondevicechange = null;
        await this._videoFilter?.release();
        await this._audioFilter?.release();
    }

    private handleTrickleIce(event: TrickleIceEvent) {
        if (!this.hasRemoteDescription) {
            this.remoteCandidates.push(event.candidate);
            return;
        }
        this.addIceCandidate(event.candidate);
    }

    private addIceCandidate(candidate: any) {
        let iceCandidate = candidate.completed ? null : candidate;
        this.audioPC.peerConnection.addIceCandidate(iceCandidate)
            .catch(error =>
                this.logger.log(new ErrorLog(this.callId, `Error adding ice candidate ${candidate}: ${error}`)));
    }

    protected setRemoteCandidates() {
        this.hasRemoteDescription = true;
        if (this.remoteCandidates.length > 0) {
            this.remoteCandidates.forEach(candidate => this.addIceCandidate(candidate));
            this.remoteCandidates = [];
        }
    }

    protected async addCameraVideo(): Promise<void> {
        this.mediaUpdateStatus = MediaUpdateStatus.ADDING_VIDEO;
        try {
            this.localCameraVideoStream = await this.getCameraVideoStream(this.cameraOrientation());
            await applyVideoFilter(this._videoFilter, this.localCameraVideoStream, 0);
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_ADDED, <CallsApiEvents.CameraVideoAddedEvent>{stream: this.localCameraVideoStream});
        } catch (error) {
            this.localCameraVideo.active = false;
            this.handleGetUserMediaError(error, "camera");
            return;
        }
        await this.publishVideo(this.joinedConference || this.joinedDialog);
    }

    private async publishVideo(isConference: boolean) {
        if (!this.videoPublisherPC) {
            this.createVideoPublisherPC(isConference);
        }

        try {
            await this.updateTransceiver(this.localCameraVideo, this.localCameraVideoStream, CAMERA_VIDEO_ENCODINGS);
            await this.negotiateVideoPublisher(isConference);
        } catch (error) {
            this.handlePublishVideoFlowError(error);
        }
    }

    protected async addScreenShareVideo(displayOptions?: DisplayOptions): Promise<void> {
        this.mediaUpdateStatus = MediaUpdateStatus.ADDING_VIDEO;
        let isConference = this.joinedConference || this.joinedDialog;
        if (!this.videoPublisherPC) {
            this.createVideoPublisherPC(isConference);
        }
        try {
            this.localScreenShareStream = await this.getScreenShareVideoStream(displayOptions);
        } catch (error) {
            this.handleGetUserMediaError(error, "screen-share");
            return;
        }

        try {
            await this.updateTransceiver(this.localScreenShare, this.localScreenShareStream, SimulcastEncodings.screenShareEncodings);
            await this.negotiateVideoPublisher(isConference);
            this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_ADDED, <CallsApiEvents.ScreenShareAddedEvent>{stream: this.localScreenShareStream});
        } catch (error) {
            this.handlePublishVideoFlowError(error);
        }
    }

    private async updateTransceiver(media: LocalMedia, stream: MediaStream, encodings: RTCRtpEncodingParameters[]) {
        let track = stream.getVideoTracks()[0];
        if (media.transceiver) {
            await media.transceiver.sender.replaceTrack(track);
            media.transceiver.direction = 'sendonly';
        } else {
            this.addTransceiver(media, track, stream, encodings);
        }
    }

    private async migrateLocalVideos() {
        this.createVideoPublisherPC(this.joinedConference || this.joinedDialog);

        if (this.hasCameraVideo()) {
            this.addTransceiver(this.localCameraVideo,
                this.localCameraVideoStream.getVideoTracks()[0],
                this.localCameraVideoStream,
                Browser.isMobile() ? SimulcastEncodings.cameraEncodingsMobile : SimulcastEncodings.cameraEncodings);
        }
        if (this.hasScreenShare()) {
            this.addTransceiver(this.localScreenShare,
                this.localScreenShareStream.getVideoTracks()[0],
                this.localScreenShareStream,
                SimulcastEncodings.screenShareEncodings);
        }
    }

    private addTransceiver(media: LocalMedia, track: MediaStreamTrack, stream: MediaStream, encodings: RTCRtpEncodingParameters[]) {
        if (media.transceiver) {
            const oldTrack = media.transceiver?.sender?.track;
            if (oldTrack && oldTrack !== track) {
                oldTrack.stop();
            }
        }
        media.transceiver = this.videoPublisherPC.peerConnection.addTransceiver(track, {
            streams: [stream],
            direction: 'sendonly',
            sendEncodings: encodings
        });
    }

    // TODO refactor
    private async negotiateVideoPublisher(isConference: boolean): Promise<void> {
        let offer = await this.videoPublisherPC.peerConnection.createOffer();
        await this.setLocalDescription(this.videoPublisherPC.peerConnection, offer);

        let labels = [];
        if (this.localCameraVideo.active) {
            labels.push({mid: this.localCameraVideo.transceiver.mid, description: VideoType.CAMERA});
        }
        if (this.localScreenShare.active) {
            labels.push({mid: this.localScreenShare.transceiver.mid, description: VideoType.SCREENSHARE});
        }

        let action = isConference ? WsAction.PUBLISH_VIDEO_CONFERENCE : WsAction.PUBLISH_VIDEO_CALL;
        this.gateway.send({
            action: action,
            callId: this.callId,
            description: offer,
            labels: labels
        });
    }

    private async removeCameraVideo() {
        if (!this.localCameraVideo.transceiver) {
            return;
        }
        this.mediaUpdateStatus = MediaUpdateStatus.REMOVING_VIDEO;
        await this.disableLocalMedia(this.localCameraVideo);
        await this._videoFilter?.release();
        this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_REMOVED, <CallsApiEvents.CameraVideoRemovedEvent>{});
    }

    private async removeScreenShareVideo(reason: VideoRemovalReason) {
        if (!this.localScreenShare.transceiver) {
            return;
        }
        this.mediaUpdateStatus = MediaUpdateStatus.REMOVING_VIDEO;
        await this.disableLocalMedia(this.localScreenShare);
        this.apiEventEmitter.emit(CallsApiEvent.SCREEN_SHARE_REMOVED, <CallsApiEvents.ScreenShareRemovedEvent>{reason: reason});
    }

    private async disableLocalMedia(media: LocalMedia) {
        await this.disableTransceiver(media);
        if (!this.localCameraVideo.active && !this.localScreenShare.active) {
            let action = this.joinedConference || this.joinedDialog ? WsAction.UNPUBLISH_VIDEO_CONFERENCE : WsAction.UNPUBLISH_VIDEO_CALL;
            this.gateway.send({
                action: action,
                callId: this.callId
            });
        } else {
            await this.negotiateVideoPublisher(this.joinedConference || this.joinedDialog);
        }
    }

    private async disableTransceiver(media: LocalMedia) {
        media.transceiver.sender.track?.stop();
        await media.transceiver.sender.replaceTrack(null);
        media.transceiver.direction = 'inactive';
    }

    private async replaceTrack(media: LocalMedia, track: MediaStreamTrack): Promise<void> {
        if (!media.transceiver) {
            return;
        }
        if (media.transceiver.sender.track === track) {
            return;
        }

        media.transceiver.sender.track?.stop();
        await media.transceiver.sender.replaceTrack(track);
    }

    private throwMediaError(error: any) {
        switch (error.name) {
            case ("NotAllowedError"):
                throw ApplicationErrorCode.DEVICE_FORBIDDEN;
            case ("NotFoundError"):
                throw ApplicationErrorCode.DEVICE_NOT_FOUND;
            case ("NotReadableError"):
                throw ApplicationErrorCode.DEVICE_UNAVAILABLE;
            default:
                throw error;
        }
    }

    private getDurationInSeconds(currentTime: Date) {
        return Math.floor((currentTime.getTime() - this.callEstablishTime.getTime()) / 1000);
    }

    private onIceCandidate(action: string = WsAction.ICE_CANDIDATE, event: any, type: string = undefined) {
        let candidate = event.candidate ? event.candidate : {completed: true};
        this.gateway.send({action: action, callId: this.callId, type: type, ice: candidate});
    }

    private emitReconnected(audioStream: MediaStream) {
        this.remoteAudioStream = audioStream;
        this.callStatus = CallStatus.ESTABLISHED;
        this.reconnecting = false;
        this.apiEventEmitter.emit(CallsApiEvent.RECONNECTED, <CallsApiEvents.ReconnectedEvent>{
            stream: this.remoteAudioStream
        });
    }

    private emitEstablished(audioStream: MediaStream) {
        this.remoteAudioStream = audioStream;
        this.callStatus = CallStatus.ESTABLISHED;
        this.callEstablishTime = new Date();
        this.apiEventEmitter.emit(CallsApiEvent.ESTABLISHED, <CallsApiEvents.EstablishedEvent>{
            stream: this.remoteAudioStream
        });
    }

    private emitEarlyMedia(audioStream: MediaStream) {
        this.remoteAudioStream = audioStream;
        this.apiEventEmitter.emit(CallsApiEvent.EARLY_MEDIA, <CallsApiEvents.EarlyMediaEvent>{
            stream: this.remoteAudioStream
        });
    }

    private onAudioPcTrack(event: RTCTrackEvent) {
        // TODO use on ice connected?
        if (this.isEarlyMedia) {
            this.emitEarlyMedia(event.streams[0]);
        } else if (this.reconnecting) {
            this.emitReconnected(event.streams[0]);
        } else {
            this.emitEstablished(event.streams[0]);
        }
    }

    private scheduleHangup(hangupEvent: HangupEvent) {
        const timeout = setTimeout(() => {
            this.eventEmitter.emit('hangup', {status: hangupEvent.status})
        }, 3000);
        this.eventEmitter.once(WsEvent.HANGUP, event => clearTimeout(timeout));
    }

    protected handleCallFlowError(error: string, sendHangup: boolean = false) {
        this.logger.log(new ErrorLog(this.callId, error));
        if (sendHangup) {
            let reason = HangupReasonFactory.getHangupReason(error);
            this.gateway.send({action: WsAction.HANGUP, callId: this.callId, reason: reason});
        }
        let hangupStatus = HangupStatusFactory.getApplicationHangupStatus(error);
        this.eventEmitter.emit('call-error', {status: hangupStatus});
    }

    protected validateMediaUpdateStatus() {
        if (this.mediaUpdateStatus !== MediaUpdateStatus.IDLE) {
            throw new Error("User media already updating.");
        }
    }

    private createVideoPublisherPC(isConference: boolean) {
        this.videoPublisherPC = MonitoredPeerConnection.create(
            this.rtcConfig,
            this.callId,
            PeerConnectionTag.VideoPublisher,
            this.conferenceId || this.dialogId,
            MediaType.VIDEO,
            this.logger
        );
        let action = isConference ? WsAction.ICE_CANDIDATE_VIDEO_CONFERENCE : WsAction.ICE_CANDIDATE_VIDEO_CALL;
        this.videoPublisherPC.peerConnection.onicecandidate = ice => this.onIceCandidate(action, ice, 'publisher');
        this.videoPublisherPC.peerConnection.onconnectionstatechange = () => this.onVideoPublisherConnectionStateChanged();
        this.videoPublisherPC.peerConnection.onsignalingstatechange = () => {
            if (this.videoPublisherPC.peerConnection.signalingState === 'stable')
                this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
        };
    }

    private handlePublishVideoFlowError(error: string) {
        this.logger.error(`Publish video flow error occurred: ${error}`, this.callId);
        // TODO check if we should do cleanup
        this.videoPublisherCleanup();
        let errorCode = HangupStatusFactory.getApplicationHangupStatus(error);
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode});
    }

    private handleGetUserMediaError(error: any, userMediaErrorType: "screen-share" | "camera") {
        this.logger.error(`Get user media flow error occurred: ${error}`, this.callId);
        if (!this.hasCameraVideo()) {
            // TODO check if we should do cleanup
            this.videoPublisherCleanup();
        } else if (userMediaErrorType === "screen-share") {
            this.localScreenShare.active = false;
            this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
        }
        let errorCode = HangupStatusFactory.getApplicationHangupStatus(error);
        this.apiEventEmitter.emit(CallsApiEvent.ERROR, <CallsApiEvents.ErrorEvent>{errorCode});
    }

    protected async getLocalAudioStream(audio: boolean, requestVideo: boolean, cameraOrientation?: CameraOrientation, cameraVideoFrameRate?: number): Promise<MediaStream> {
        if (!audio && !requestVideo) {
            return new MediaStream();
        }
        let stream = await this.device.getLocalStream(audio, requestVideo, cameraOrientation, true, false, cameraVideoFrameRate);
        if (requestVideo && stream.getVideoTracks().length > 0) {
            let videoTrack = stream.getVideoTracks()[0];
            this.localCameraVideoStream = new MediaStream([videoTrack]);
            await applyVideoFilter(this._videoFilter, this.localCameraVideoStream, 0);
            this.apiEventEmitter.emit(CallsApiEvent.CAMERA_VIDEO_ADDED, <CallsApiEvents.CameraVideoAddedEvent>{stream: this.localCameraVideoStream});
        }
        return audio ? new MediaStream([stream.getAudioTracks()[0]]) : new MediaStream();
    }

    private getCameraVideoStream(cameraOrientation: CameraOrientation = CameraOrientation.FRONT, useExactDevice: boolean = true): Promise<MediaStream> {
        return this.device.getLocalStream(false, true, cameraOrientation, true, useExactDevice, this.applicationCallOptions?.videoOptions?.cameraVideoFrameRate);
    }

    private async getScreenShareVideoStream(displayOptions?: DisplayOptions): Promise<MediaStream> {
        if (displayOptions) {
            this.checkDisplaySurfaceSupported();
        }

        const stream = await this.device.getDisplayMedia(displayOptions, this.applicationCallOptions?.videoOptions?.screenShareFrameRate);
        if (displayOptions) {
            this.checkAllowedDisplaySurface(stream, displayOptions);
        }
        return this.setScreenShareInactive(stream);
    }

    private checkAllowedDisplaySurface(stream: MediaStream, displayOptions: DisplayOptions) {
        stream.getVideoTracks().forEach(track => {
            const isSharedScreenAllowed = displayOptions.allowedDisplayOptions.includes(<DisplaySurface>track.getCapabilities().displaySurface);
            if (!isSharedScreenAllowed) {
                RTCMediaDevice.closeMediaStream(stream);
                throw ApplicationErrorCode.FORBIDDEN_DISPLAY_SURFACE;
            }
        });
    }

    private checkDisplaySurfaceSupported() {
        if (!("displaySurface" in navigator.mediaDevices.getSupportedConstraints())) {
            throw ApplicationErrorCode.SCREEN_SHARING_CONTROL_UNSUPPORTED;
        }
    }

    private setScreenShareInactive(stream: MediaStream): MediaStream {
        stream.getVideoTracks()[0].onended = () => this.screenShare(false);
        return stream;
    }

    private audioCleanup(): TotalMediaStats {
        let totalMediaStats: TotalMediaStats;
        if (this.audioPC) {
            totalMediaStats = this.audioPC.close();
            this.audioPC = null;
            this.hasRemoteDescription = false;
        }
        if (this.emptyAudioStream) {
            this.emptyAudioStream.close();
        }
        if (this.localAudioStream) {
            this.localAudioStream.getAudioTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
        return totalMediaStats;
    }

    private onNetworkQualityStatisticsChanged(networkQualityStatistics: NetworkQualityStatistics, currentMediaStats: CurrentMediaStats) {
        this.networkQualityMonitor.observe(networkQualityStatistics);
        if (this.networkQualityMonitor.localNetworkChanged() && this.callStatus === CallStatus.ESTABLISHED) {
            let currentQualityStatistics = this.networkQualityMonitor.current();
            currentMediaStats.mos = currentQualityStatistics.mos;
            this.emitNetworkQualityChanged({
                networkQuality: this.currentNetworkQuality(),
                currentMediaStats: currentMediaStats
            });
        }
    }

    private emitNetworkQualityChanged(event: CallsApiEvents.NetworkQualityChangedEvent) {
        this.apiEventEmitter.emit(CallsApiEvent.NETWORK_QUALITY_CHANGED, event);
        this.gateway.send({
            action: WsAction.NETWORK_QUALITY_CHANGED,
            callId: this.callId,
            networkQuality: event.networkQuality,
        });
    }

    private currentNetworkQuality(): NetworkQuality {
        return this.networkQualityMonitor.current().networkQuality;
    }

    private videoPublisherCleanup(isMigration: boolean = false) {
        if (!isMigration) {
            for (let media of [this.localCameraVideo, this.localScreenShare]) {
                if (media) {
                    media.active = false;
                    media.transceiver?.sender.track?.stop();
                    if (this.videoPublisherPC?.peerConnection.connectionState !== "closed") {
                        media.transceiver?.stop();
                    }
                    media.transceiver = null;
                }
            }
            if (this.localCameraVideoStream) {
                this.localCameraVideoStream?.getVideoTracks().forEach(track => track.stop());
            }
            if (this.localScreenShareStream) {
                this.localScreenShareStream?.getVideoTracks().forEach(track => track.stop());
            }
        }
        if (this.videoPublisherPC) {
            this.videoPublisherPC.close();
            this.videoPublisherPC = null;
        }
        this.mediaUpdateStatus = MediaUpdateStatus.IDLE;
    }

    private loadParticipant(rawParticipant: ConferenceParticipant) {
        const participant = new Participant();
        Object.assign(participant, rawParticipant);
        participant.media = Object.assign(new Media(Object.assign(new Audio(), participant.media.audio)), participant.media);
        participant.endpoint = this.getEndpoint(participant.endpoint);
        return participant;
    }

    private getEndpoint(rawEndpoint: any): Endpoint {
        switch (rawEndpoint.type) {
            case (EndpointType.PHONE):
                return Object.assign(new PhoneEndpoint(), rawEndpoint);
            case (EndpointType.WEBRTC):
                return Object.assign(new WebrtcEndpoint(), rawEndpoint);
            case (EndpointType.VIBER):
                return Object.assign(new ViberEndpoint(), rawEndpoint);
            case (EndpointType.WHATSAPP):
                return Object.assign(new WhatsAppEndpoint(), rawEndpoint);
            default:
                return Object.assign(new SipEndpoint(), rawEndpoint);
        }
    }

    private async createDTMFSender() {
        if (!this.audioPC.peerConnection) {
            throw ApplicationErrorCode.MEDIA_ERROR;
        }
        let audioSender = this.audioPC.peerConnection.getSenders().find(sender => sender.track?.kind === 'audio');
        if (!audioSender) {
            throw ApplicationErrorCode.MEDIA_ERROR;
        }
        this.dtmfSender = audioSender.dtmf;
        if ("canInsertDTMF" in this.dtmfSender && this.dtmfSender.canInsertDTMF === false) {
            this.dtmfUnavailable = true;
            return;
        }
    }

    private sendDTMFInfo(dtmf: string, toneDuration: number) {
        this.gateway.send({
            action: WsAction.DTMF_INFO,
            callId: this.callId,
            digit: dtmf,
            duration: toneDuration
        });
    }

    public setReconnectHandler(reconnectHandler: ReconnectHandler) {
        this.reconnectHandler = reconnectHandler;
    }
}
