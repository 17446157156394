export enum WsAction {
    APP_CALL_DECLINE = 'application_call_decline',
    APP_CALL_ACCEPT = 'application_call_accept',
    MUTE = 'mute',
    DTMF_INFO = 'dtmf_info',
    HANGUP = 'hangup',
    ICE_CANDIDATE = 'ice_candidate',
    PUBLISH_VIDEO_CALL = 'publish_video_call',
    UNPUBLISH_VIDEO_CALL = 'unpublish_video_call',
    ICE_CANDIDATE_VIDEO_CALL = 'ice_candidate_video_call',
    PUBLISH_VIDEO_CONFERENCE = 'publish_video_conference',
    UNPUBLISH_VIDEO_CONFERENCE = 'unpublish_video_conference',
    PAUSE_INCOMING_VIDEO = 'pause_incoming_video',
    RESUME_INCOMING_VIDEO = 'resume_incoming_video',
    ICE_CANDIDATE_VIDEO_CONFERENCE = 'ice_candidate_video_conference',
    START_VIDEO_CONFERENCE = 'start_video_conference',
    ICE_CANDIDATE_DATA_CHANNEL = 'ice_candidate_data_channel',
    SETUP_ACK = "setup_ack",
    NETWORK_QUALITY_CHANGED = "network_quality_changed"
}