import {Log} from "../Log";
import {LoggerOptions} from "../LoggerOptions";
import {Logger} from "../Logger";
import {LogLevel, LogLevelLabels} from "../LogLevel";
import log from "loglevel"
import {ApiResponse, DefaultHttpClient, Headers, HttpClient} from "../../util/HttpClient";

export class DefaultLogger implements Logger {
    private static DEFAULT_PERIOD: number = 5000;
    private static FORBIDDEN_STATUS_CODE: number = 403;

    private logs: Log[];
    private httpClient: HttpClient;
    private readonly interval: any;

    constructor(
        private readonly token: string,
        private options: LoggerOptions = new LoggerOptions(),
        private apiUrl: string = "api.infobip.com",
        private logLevel: LogLevel = LogLevel.ERROR,
        private readonly headersProvider?: () => Headers
    ) {
        this.logs = [];
        this.interval = this.createInterval(options.period);
        if (!this.headersProvider) {
            this.headersProvider = () => null;
        }
        this.httpClient = new DefaultHttpClient(`https://${apiUrl}`, {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
        });
    }

    private static ifNotForbidden(apiResponse: ApiResponse<any>) {
        return apiResponse.statusCode !== DefaultLogger.FORBIDDEN_STATUS_CODE;
    }

    log(log: Log): void {
        this.logs.push(log);
    }

    debug(message: string, callId: string): void {
        this.logMessage(LogLevel.DEBUG, message, callId);
    }

    info(message: string, callId: string): void {
        this.logMessage(LogLevel.INFO, message, callId);
    }

    warn(message: string, callId: string): void {
        this.logMessage(LogLevel.WARNING, message, callId);
    }

    error(message: string, callId: string): void {
        this.logMessage(LogLevel.ERROR, message, callId);
    }

    stop(): void {
        clearInterval(this.interval);
        this.sendLogs();
    }

    setOptions(options: LoggerOptions): void {
        if (options.period) {
            clearInterval(this.interval);
            this.createInterval(options.period);
        }
    }

    setApiUrl(apiUrl: string): void {
        if (apiUrl && apiUrl !== this.apiUrl) {
            this.apiUrl = apiUrl;
            this.httpClient = new DefaultHttpClient(`https://${apiUrl}`, {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json'
            });
        }
    }

    setLogLevel(logLevel: string): void {
        if (LogLevel[logLevel]) {
            this.logLevel = LogLevel[logLevel];
        }
    }

    private logMessage(logLevel: LogLevel, message: string, callId: string = null): void {
        switch (logLevel) {
            case LogLevel.DEBUG:
                log.debug(message);
                break;
            case LogLevel.INFO:
                log.info(message);
                break;
            case LogLevel.WARNING:
                log.warn(message);
                break;
            case LogLevel.ERROR:
                log.error(message);
                break;
        }

        if (logLevel >= this.logLevel) {
            this.log(new Log(LogLevelLabels.get(logLevel), callId, {message: message}));
        }
    }

    private sendLogs(): void {
        if (this.logs.length === 0) {
            return;
        }
        let tempLogs = this.logs;
        this.logs = [];

        this.httpClient.post('/webrtc/1/log', {logEvents: tempLogs}, this.headersProvider())
            .then(apiResponse => {
                if (!apiResponse.ok && DefaultLogger.ifNotForbidden(apiResponse)) {
                    this.logs = this.logs.concat(tempLogs);
                }
            })
            .catch(error => {
                log.error(`Failed to submit logs: ${error.message}`);
            });
    }

    private createInterval(period: number) {
        return setInterval(() => {
            this.sendLogs();
        }, period || DefaultLogger.DEFAULT_PERIOD);
    }
}
