import {ApplicationErrorCode} from "../ApplicationErrorCode";
import {ErrorCode} from "../event/ErrorCode";

class HangupStatusFactory {
    static getApplicationHangupStatus(error: any): ErrorCode {
        if (!error || (!error.name && !error.message)) {
            return ApplicationErrorCode.UNKNOWN;
        }

        switch (error.name) {
            case ("NotAllowedError"):
                return ApplicationErrorCode.DEVICE_FORBIDDEN
            case ("NotFoundError"):
                return ApplicationErrorCode.DEVICE_NOT_FOUND
            case ("NotReadableError"):
                return ApplicationErrorCode.DEVICE_UNAVAILABLE
        }

        if (error?.id && error?.name && error?.description) {
            return {
                id: error.id,
                name: error.name,
                description: error.description
            };
        }

        let errorDescription = '';

        if (error.name) {
            errorDescription += error.name + ' ';
        }
        if (error.message) {
            errorDescription += error.message;
        }
        return {id: '10310', name: 'UNKNOWN', description: errorDescription};
    }
}

export default HangupStatusFactory;
