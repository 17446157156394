export enum LogLevel {
    'DEBUG' = 1,
    'INFO' = 2,
    'WARNING' = 3,
    'ERROR' = 4,
    'OFF' = 5
}

export const LogLevelLabels = new Map<number, string>([
    [LogLevel.DEBUG, "DEBUG"],
    [LogLevel.INFO, "INFO"],
    [LogLevel.WARNING, "WARNING"],
    [LogLevel.ERROR, "ERROR"],
    [LogLevel.OFF, "OFF"]
]);
