import {IncomingApplicationCall} from "../IncomingApplicationCall";
import {CustomData} from "../CustomDataType";

export class IncomingApplicationCallEvent {
    constructor(private _incomingCall: IncomingApplicationCall,
                private _customData: CustomData) {
    }

    get incomingCall(): IncomingApplicationCall {
        return this._incomingCall;
    }

    get customData(): CustomData {
        return this._customData;
    }
}