import "webrtc-adapter";

import {CameraOrientation} from "../call/options/CameraOrientation";
import {Device} from "./Device";
import {Logger} from "../log/Logger";
import _ from "lodash";
import {DisplayOptions} from "../call/options/DisplayOptions";
import {VideoOptionsUtil} from "../util/VideoOptionsUtil";

declare global {
    interface MediaDevices {
        getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>;
    }

    interface MediaTrackConstraintSet {
        cursor?: "always" | "motion" | "never";
        displaySurface?: "browser" | "monitor" | "window";
        logicalSurface?: ConstrainBoolean;
    }
}

interface InputDevice {
    deviceId: string;
    kind: MediaDeviceKind;
}

export class DefaultDevice implements Device {
    private deviceKindNames = {
        "audioinput": "Microphone",
        "audiooutput": "Speaker",
        "videoinput": "Camera"
    };
    private audioInputDevice: InputDevice;
    private videoInputDevice: InputDevice;
    private cameraOrientation: CameraOrientation;

    private static CAMERA_VIDEO_FPS_DEFAULT: number = 24;
    private static SCREEN_SHARE_FPS_DEFAULT: number = 8;

    constructor(private logger: Logger) {
    }

    getAudioInputDevices(): Promise<MediaDeviceInfo[]> {
        return this.getDevices("audioinput")
            .then(devices => this.updateDevices(devices));
    }

    getAudioOutputDevices(): Promise<MediaDeviceInfo[]> {
        return this.getDevices("audiooutput")
            .then(devices => this.updateDevices(devices));
    }

    getVideoInputDevices(): Promise<MediaDeviceInfo[]> {
        return this.getDevices("videoinput")
            .then(devices => this.updateDevices(devices));
    }

    setAudioInputDevice(id: string): void {
        this.audioInputDevice = {
            deviceId: id,
            kind: "audioinput"
        }
    }

    unsetAudioInputDevice(): void {
        this.audioInputDevice = null;
    }

    getAudioInputDevice(): string {
        return this.audioInputDevice?.deviceId;
    }

    audioInputDeviceShouldChange(): Promise<boolean> {
        return this.getAudioInputDevices()
            .then(devices => {
                return devices.findIndex(device => device.deviceId === this.audioInputDevice?.deviceId) === -1 && devices.length > 0;
            })
            .catch(() => {
                return false;
            })
    }

    setVideoInputDevice(id: string): void {
        this.videoInputDevice = {
            deviceId: id,
            kind: "videoinput"
        }
    }

    unsetVideoInputDevice(): void {
        this.videoInputDevice = null;
    }

    getVideoInputDevice(): string {
        return this.videoInputDevice?.deviceId;
    }

    getCameraOrientation(): CameraOrientation {
        return this.cameraOrientation;
    }

    async getLocalStream(
            audio: boolean = true,
            video: boolean = false,
            cameraOrientation: CameraOrientation = CameraOrientation.FRONT,
            hdResolution: boolean = false,
            useExactDevice: boolean = true,
            cameraVideoFrameRate?: number
    ): Promise<MediaStream> {
        let mediaConstraints;
        let devices;
        try {
            this.cameraOrientation = cameraOrientation;
            if (this.audioInputDevice || this.videoInputDevice) {
                devices = await this.getDevices();
                const audioDeviceConstraints = this.getDeviceConstraint(devices, this.audioInputDevice);
                const videoDeviceConstraints = this.getDeviceConstraint(devices, this.videoInputDevice, useExactDevice);
                const audioConstraints = audio && this.getAudioConstraints(audioDeviceConstraints);
                const videoConstraints = video && this.getVideoConstraints(videoDeviceConstraints, cameraOrientation, hdResolution, cameraVideoFrameRate);
                mediaConstraints = {
                    audio: audioConstraints,
                    video: videoConstraints
                };
            } else {
                let audioConstraints = audio && this.getAudioConstraints(null);
                let videoConstraints = video && this.getVideoConstraints(null, cameraOrientation, hdResolution, cameraVideoFrameRate);
                mediaConstraints = {
                    audio: audioConstraints,
                    video: videoConstraints
                };
            }
            return await navigator.mediaDevices.getUserMedia(mediaConstraints);
        } catch (error) {
            if (error) {
                this.logger.error(`Failed to request device audio=${audio} video=${video} with constraints ${mediaConstraints && JSON.stringify(mediaConstraints)} (${devices && JSON.stringify(devices)}) - ${error}`);
            }
            throw error;
        }
    }

    getDisplayMedia(displayOptions?: DisplayOptions, screenShareFrameRate?: number): Promise<MediaStream> {
        const constraints = {
            video: this.getScreenShareDefaults(screenShareFrameRate),
            ...(displayOptions && {monitorTypeSurfaces: "include"}),
        };

        if (displayOptions) {
            constraints.video.displaySurface = displayOptions.allowedDisplayOptions[0];
        }
        if (displayOptions && !displayOptions.allowedDisplayOptions.includes("monitor")) {
            constraints.monitorTypeSurfaces = "exclude";
        }

        return navigator.mediaDevices.getDisplayMedia?.(constraints) ??
            Promise.reject("Screen share is not supported.");
    }

    protected getMicrophoneDefaults = (): MediaTrackConstraintSet => ({
        // TODO: test if autoGainControl is good to have here
        // @ts-ignore
        noiseSuppression: true,
        echoCancellation: true
    })

    protected getCameraDefaults = (hdResolution: boolean = false, cameraVideoFrameRate?: number): MediaTrackConstraintSet => ({
        width: hdResolution ? 1280 : 640,
        height: hdResolution ? 720 : 480,
        frameRate: VideoOptionsUtil.limitFps(cameraVideoFrameRate) || DefaultDevice.CAMERA_VIDEO_FPS_DEFAULT
    })

    protected getScreenShareDefaults = (screenShareFrameRate?: number): MediaTrackConstraintSet => ({
        width: {
            max: 1920
        },
        height: {
            max: 1080
        },
        frameRate: VideoOptionsUtil.limitFps(screenShareFrameRate) || DefaultDevice.SCREEN_SHARE_FPS_DEFAULT,
        cursor: "always"
    })

    private getAudioConstraints(deviceId: ConstrainDOMString = null): MediaTrackConstraintSet {
        return {
            ...this.getMicrophoneDefaults(),
            deviceId
        };
    }

    private getVideoConstraints(
            deviceId: ConstrainDOMString = null,
            cameraOrientation: CameraOrientation = CameraOrientation.FRONT,
            hdResolution: boolean = false,
            cameraVideoFrameRate?: number): MediaTrackConstraintSet {
        return {
            ...this.getCameraDefaults(hdResolution, cameraVideoFrameRate),
            facingMode: cameraOrientation,
            deviceId
        };
    }

    private getDeviceConstraint(devices: MediaDeviceInfo[], inputDevice: InputDevice, useExactDevice: boolean = true): ConstrainDOMString {
        if (!inputDevice) return null;
        let videoInputDeviceExists = _.findIndex(devices, {
            kind: inputDevice.kind,
            deviceId: inputDevice.deviceId
        }) !== -1;
        return videoInputDeviceExists && useExactDevice ? {exact: inputDevice.deviceId} : null;
    }

    private getDevices(deviceKind: MediaDeviceKind = null): Promise<MediaDeviceInfo[]> {
        return this.enumerateDevices(deviceKind)
            .then(mediaDevices => {
                if (mediaDevices.length === 0) {
                    return [];
                }
                if (mediaDevices[0].label) {
                    return this.filterDevices(mediaDevices, deviceKind);
                } else {
                    return this.getUserMediaDevices(deviceKind);
                }
            });
    }

    private filterDevices(mediaDeviceInfos: MediaDeviceInfo[], deviceKind: MediaDeviceKind): MediaDeviceInfo[] {
        if (deviceKind) {
            let devices = mediaDeviceInfos.filter(device => device.kind === deviceKind);
            return _.uniqBy(devices, 'deviceId');
        }
        return _.uniqBy(mediaDeviceInfos, 'deviceId');
    }

    private getUserMediaDevices(deviceKind: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
        let constraints = deviceKind === 'videoinput' ? {video: true} : {audio: true};
        return navigator.mediaDevices.getUserMedia(constraints).then(this.stopTracks)
            .then(() => this.enumerateDevices(deviceKind));
    }

    private enumerateDevices(deviceKind: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
        return navigator.mediaDevices.enumerateDevices()
            .then(mediaDevices => this.filterDevices(mediaDevices, deviceKind));
    }

    private stopTracks(stream: MediaStream) {
        stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }

    private updateDevices(devices: MediaDeviceInfo[]) {
        return devices.map((device, index) => {
            return this.generateCustomMediaDeviceInfo(device, index + 1);
        });
    }

    private generateCustomMediaDeviceInfo(mediaDeviceInfo: MediaDeviceInfo, index: number) {
        let deviceKindName = this.deviceKindNames[mediaDeviceInfo.kind];
        let customMediaDeviceInfo = mediaDeviceInfo.toJSON();
        if (!mediaDeviceInfo.label) {
            customMediaDeviceInfo.label = mediaDeviceInfo.deviceId === "default" ? `Default ${deviceKindName}` : `${deviceKindName} ${index}`;
        }
        return customMediaDeviceInfo as MediaDeviceInfo;
    }
}
