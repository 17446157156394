export class User {
    constructor(private _identity: string, private _displayName: string) {
    }

    get identity(): string {
        return this._identity;
    }

    get displayName(): string {
        return this._displayName;
    }
}
