export class Log {
    constructor(private event: string,
                private callId: string,
                private data: any,
                private timestamp: number = Date.now()) {
    }
}

export class IceConnectionChangeLog extends Log {

    constructor(callId: string, data: any) {
        super("ice-connection-change", callId, data);
    }
}

export class SelectedIceCandidatePairLog extends Log {

    constructor(callId: string, data: any) {
        super("ice-selected-candidate-pair", callId, data);
    }
}

export class CallMediaStatsLog extends Log {

    constructor(event: string, conferenceId: string, callId: string, name: string, data: any, timestamp: number) {
        super(event, callId, {stats: data, peerConnection: name, conferenceId: conferenceId}, timestamp);
    }
}

export class CallFeedbackLog extends Log {

    constructor(callId: string, data: any) {
        super("call-feedback", callId, data);
    }
}

export class ConferenceFeedbackLog extends Log {

    constructor(conferenceId: string, data: any) {
        super("conference-feedback", conferenceId, {...data, conferenceId: conferenceId});
    }
}

export class ErrorLog extends Log {

    constructor(callId: string, data: any) {
        super("error", callId, data);
    }
}