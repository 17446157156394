enum Status  {
    OFFLINE,
    CONNECTING,
    RECONNECTING,
    CONNECTED
}

export default Status;

export const StatusLabels = new Map<number, string>([
   [Status.OFFLINE, "OFFLINE"],
   [Status.CONNECTING, "CONNECTING"],
   [Status.RECONNECTING, "RECONNECTING"],
   [Status.CONNECTED, "CONNECTED"]
]);
