import {ManagedVideoFilter} from "./ManagedVideoFilter";
import {ManagedAudioFilter} from "./ManagedAudioFilter";

export async function applyFilters(
    stream: MediaStream,
    audioFilter: ManagedAudioFilter,
    videoFilter: ManagedVideoFilter
): Promise<MediaStream> {
    await applyAudioFilter(audioFilter, stream, 0);
    await applyVideoFilter(videoFilter, stream, 0);
    return stream;
}

export async function applyAudioFilter(audioFilter: ManagedAudioFilter, stream: MediaStream, trackIndex: number): Promise<void> {
    if (!audioFilter) {
        return;
    }
    await audioFilter.apply(stream, trackIndex)
}

export async function applyVideoFilter(videoFilter: ManagedVideoFilter, stream: MediaStream, trackIndex: number): Promise<void> {
    if (!videoFilter) {
        return;
    }
    await videoFilter.apply(stream, trackIndex)
}
