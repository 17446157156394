import {CustomData} from "../../CustomDataType";

class ValidationUtil {
    private static readonly CUSTOM_DATA_LENGTH_LIMIT: Number = 920;

    static validateCustomData(customData: CustomData) {
        if (JSON.stringify(customData).length > this.CUSTOM_DATA_LENGTH_LIMIT) {
            throw new Error(`Custom data length limit exceeded.`);
        }
        Object.values(customData).forEach(value => {
            if (value && typeof value !== "string") {
                throw new Error(`Invalid custom data. Value ${value} must be string!`);
            }
        });
    }
}

export default ValidationUtil;
