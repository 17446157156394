const RECORD_UNDEFINED = "UNDEFINED";
const RECORD_AUDIO = "AUDIO";
const RECORD_AUDIO_AND_VIDEO = "AUDIO_AND_VIDEO";

export type RecordingType = typeof RECORD_UNDEFINED | typeof RECORD_AUDIO | typeof RECORD_AUDIO_AND_VIDEO;
export type PhoneCallRecordingType = typeof RECORD_UNDEFINED | typeof RECORD_AUDIO;
export type WebrtcCallRecordingType = typeof RECORD_UNDEFINED | typeof RECORD_AUDIO | typeof RECORD_AUDIO_AND_VIDEO;
export type ViberCallRecordingType = typeof RECORD_UNDEFINED | typeof RECORD_AUDIO;
export type RoomCallRecordingType = typeof RECORD_UNDEFINED | typeof RECORD_AUDIO | typeof RECORD_AUDIO_AND_VIDEO;

export class RecordingOptions {
    constructor(private _recordingType: RecordingType = RECORD_UNDEFINED) {
    }

    get recordingType(): RecordingType {
        return this._recordingType;
    }

    public toJSON(): any {
        return {
            recordingType: this._recordingType
        }
    }
}

