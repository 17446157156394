import {Endpoint} from "./Endpoint";

export class Participant {
    public state: State;
    public media: Media;
    public endpoint: Endpoint;
}

export enum State {
    JOINING = "JOINING", JOINED = "JOINED"
}

export class Media {
    constructor(
        public audio: Audio,
    ) {
    }
}

export class Audio {
    public talking: boolean = false;

    constructor(
        public muted: boolean = false,
        public deaf: boolean = false
    ) {
    }
}