export enum EndpointType {
    WEBRTC = "WEBRTC", SIP = "SIP", PHONE = "PHONE", VIBER = "VIBER", WHATSAPP = "WHATSAPP"
}

export abstract class Endpoint {
    constructor(public readonly type: EndpointType) {
    }

    abstract get identifier(): string;

    abstract get displayIdentifier(): string;
}

export class WebrtcEndpoint extends Endpoint {
    public identity: string;
    public displayName: string;

    constructor(identity: string = null) {
        super(EndpointType.WEBRTC);
        this.identity = identity;
    }

    get identifier(): string {
        return this.identity;
    }

    get displayIdentifier(): string {
        return this.displayName;
    }
}

export class PhoneEndpoint extends Endpoint {
    public readonly phoneNumber: string;

    constructor(phoneNumber: string = null) {
        super(EndpointType.PHONE);
        this.phoneNumber = phoneNumber;
    }

    get identifier(): string {
        return this.phoneNumber;
    }

    get displayIdentifier(): string {
        return this.phoneNumber;
    }
}

export class ViberEndpoint extends Endpoint {
    public readonly phoneNumber: string;

    constructor(phoneNumber: string = null) {
        super(EndpointType.VIBER);
        this.phoneNumber = phoneNumber;
    }

    get identifier(): string {
        return this.phoneNumber;
    }

    get displayIdentifier(): string {
        return this.phoneNumber;
    }
}

export class SipEndpoint extends Endpoint {
    public readonly username: string;

    constructor(username: string = null) {
        super(EndpointType.SIP);
        this.username = username;
    }

    get identifier(): string {
        return this.username;
    }

    get displayIdentifier(): string {
        return this.username;
    }
}

export class WhatsAppEndpoint extends Endpoint {
    public readonly phoneNumber: string;

    constructor(phoneNumber: string = null) {
        super(EndpointType.WHATSAPP);
        this.phoneNumber = phoneNumber;
    }

    get identifier(): string {
        return this.phoneNumber;
    }

    get displayIdentifier(): string {
        return this.phoneNumber;
    }
}