import {Endpoint, ViberEndpoint, WebrtcEndpoint} from "../../util/Endpoint";
import {FlowType} from "../FlowType";
import {ViberCallOptions} from "../options/ViberCallOptions";
import {DefaultCall} from "./DefaultCall";
import {ViberCall} from "../ViberCall";
import {CallsApiEvent, CallsApiEvents} from "../event/CallsApiEvents";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import ValidationUtil from "./util/ValidationUtil";
import {CustomData} from "../CustomDataType";
import {InfobipRTC} from "../../InfobipRTC";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";
import EarlyMediaEvent = CallsApiEvents.EarlyMediaEvent;
import EstablishedEvent = CallsApiEvents.EstablishedEvent;
import HangupEvent = CallsApiEvents.HangupEvent;
import NetworkQualityChangedEvent = CallsApiEvents.NetworkQualityChangedEvent;
import ErrorEvent = CallsApiEvents.ErrorEvent;
import {getRecordingOptions} from "./util/CallOptionsUtil";

export class DefaultOutgoingViberCall extends DefaultCall implements ViberCall {
    constructor(infobipRtc: InfobipRTC,
                viberCallOptions: ViberCallOptions,
                currentUserIdentity: string,
                phoneNumberDestination: string,
                callerIdentifier: string) {
        super(viberCallOptions, new WebrtcEndpoint(currentUserIdentity), new ViberEndpoint(phoneNumberDestination));
        let applicationCallOptions = this.mapOptions(phoneNumberDestination, callerIdentifier, viberCallOptions);
        this.applicationCall = infobipRtc.callApplication(phoneNumberDestination, applicationCallOptions);
        this.initEventHandlers();
    }

    counterpart(): Endpoint {
        return this.destination();
    }

    options(): ViberCallOptions {
        return <ViberCallOptions>this.basicCallOptions;
    }

    private mapOptions(phoneNumberDestination: string, callerIdentifier: string, viberCallOptions: ViberCallOptions): ApplicationCallOptions {
        if (viberCallOptions?.customData) {
            ValidationUtil.validateCustomData(viberCallOptions?.customData);
        }

        let internalCustomData: CustomData = {
            to: phoneNumberDestination,
            from: callerIdentifier,
            type: FlowType.PHONE_NUMBER.toString(),
            ...getRecordingOptions(viberCallOptions),
            viber: true.toString(),
            withDialog: "true"
        };

        let applicationCallOptions = ApplicationCallOptions.builder()
            .setAudio(viberCallOptions?.audio)
            .setAudioOptions(viberCallOptions?.audioOptions)
            .build();

        if (viberCallOptions?.customData) {
            applicationCallOptions.customData = viberCallOptions?.customData;
        }

        return new InternalApplicationCallOptions(
            applicationCallOptions,
            internalCustomData
        );
    }

    private initEventHandlers() {
        this.applicationCall.on(CallsApiEvent.RINGING, () => {
            this.apiEventEmitter.emit(CallsApiEvent.RINGING, {});
        });
        this.applicationCall.on(CallsApiEvent.EARLY_MEDIA, (event: EarlyMediaEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.EARLY_MEDIA, event);
        });
        this.applicationCall.on(CallsApiEvent.ESTABLISHED, (event: EstablishedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ESTABLISHED, event);
        });
        this.applicationCall.on(CallsApiEvent.HANGUP, (event: HangupEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.HANGUP, event);
        });
        this.applicationCall.on(CallsApiEvent.ERROR, (event: ErrorEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.ERROR, event);
        });
        this.applicationCall.on(CallsApiEvent.NETWORK_QUALITY_CHANGED, (event: NetworkQualityChangedEvent) => {
            this.apiEventEmitter.emit(CallsApiEvent.NETWORK_QUALITY_CHANGED, event);
        });
    }
}
