import {CameraOrientation} from "./CameraOrientation";
import {VideoOptions} from "./VideoOptions";
import {AudioOptions} from "./AudioOptions";
import {CustomData} from "../CustomDataType";
import {VideoMode} from "./VideoMode";
import {AudioQualityMode} from "./AudioQualityMode";

export class ApplicationCallOptions {
    constructor(private _audio: boolean,
                private _audioOptions: AudioOptions,
                private _video: boolean,
                private _videoOptions: VideoOptions,
                private _customData: CustomData,
                private _entityId: string,
                private _dataChannel: boolean) {
    }

    get audio(): boolean {
        return this._audio;
    }

    set audio(value: boolean) {
        this._audio = value;
    }

    get audioOptions(): AudioOptions {
        return this._audioOptions;
    }

    set audioOptions(value: AudioOptions) {
        this._audioOptions = value;
    }

    get video(): boolean {
        return this._video;
    }

    set video(value: boolean) {
        this._video = value;
    }

    get videoOptions(): VideoOptions {
        return this._videoOptions;
    }

    set videoOptions(value: VideoOptions) {
        this._videoOptions = value;
    }

    get customData(): CustomData {
        return this._customData;
    }

    set customData(value: CustomData) {
        this._customData = value;
    }

    get entityId(): string {
        return this._entityId;
    }

    set entityId(value: string) {
        this._entityId = value;
    }

    get dataChannel(): boolean {
        return this._dataChannel;
    }

    set dataChannel(value: boolean) {
        this._dataChannel = value;
    }

    static builder(): ApplicationCallOptionsBuilder {
        return new ApplicationCallOptionsBuilder();
    }
}

class ApplicationCallOptionsBuilder {
    private _audio: boolean = true;
    private _audioOptions: AudioOptions = new AudioOptions(null, AudioQualityMode.AUTO);
    private _video: boolean = false;
    private _videoOptions: VideoOptions = new VideoOptions(CameraOrientation.FRONT, null, VideoMode.PRESENTATION, 24, 8);
    private _customData: CustomData = {};
    private _entityId: string = undefined;
    private _dataChannel: boolean = false;

    setAudio(_audio: boolean): ApplicationCallOptionsBuilder {
        this._audio = _audio;
        return this;
    }

    setAudioOptions(_audioOptions: AudioOptions): ApplicationCallOptionsBuilder {
        this._audioOptions = _audioOptions;
        return this;
    }

    setVideo(_video: boolean): ApplicationCallOptionsBuilder {
        this._video = _video;
        return this;
    }

    setVideoOptions(_videoOptions: VideoOptions): ApplicationCallOptionsBuilder {
        this._videoOptions = _videoOptions;
        return this;
    }

    setCustomData(_customData: CustomData): ApplicationCallOptionsBuilder {
        this._customData = _customData;
        return this;
    }

    setEntityId(_entityId: string): ApplicationCallOptionsBuilder {
        this._entityId = _entityId;
        return this;
    }

    setDataChannel(_dataChannel: boolean): ApplicationCallOptionsBuilder {
        this._dataChannel = _dataChannel;
        return this;
    }

    build() {
        return new ApplicationCallOptions(
            this._audio,
            this._audioOptions,
            this._video,
            this._videoOptions,
            this._customData,
            this._entityId,
            this._dataChannel,
        );
    }
}
