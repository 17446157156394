import {PeerConnectionMediaMonitor} from "./PeerConnectionMediaMonitor";
import {MediaType} from "../util/MediaType";
import {Logger} from "../Logger";
import {PeerConnectionTag} from "./media/PeerConnectionTag";
import {TotalMediaStats} from "../../call/stats/TotalMediaStats";

export default class MonitoredPeerConnection {
    static PEER_CONNECTION_NAMING_MAPPING: { [key: number]: string } = {
        [PeerConnectionTag.AV]: "AudioVideo",
        [PeerConnectionTag.VideoPublisher]: "VideoPublisher",
        [PeerConnectionTag.VideoSubscription]: "VideoSubscriber",
        [PeerConnectionTag.Audio]: "Audio"
    };

    public static create(rtcConfig: RTCConfiguration,
                         callId: string,
                         peerConnectionTag: PeerConnectionTag,
                         conferenceId: string,
                         mediaType: MediaType,
                         logger: Logger): MonitoredPeerConnection {
        const peerConnection = new RTCPeerConnection(rtcConfig);
        const monitor = new PeerConnectionMediaMonitor(
            callId,
            MonitoredPeerConnection.PEER_CONNECTION_NAMING_MAPPING[peerConnectionTag],
            conferenceId,
            peerConnection,
            mediaType === MediaType.VIDEO,
            logger
        );
        return new MonitoredPeerConnection(peerConnection, monitor);
    }

    private constructor(
        public readonly peerConnection: RTCPeerConnection,
        public readonly monitor: PeerConnectionMediaMonitor
    ) {
    }

    close(): TotalMediaStats {
        this.peerConnection.close();
        return this.monitor.stop();
    }
}