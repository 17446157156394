import {IncomingWebrtcCall} from "../IncomingWebrtcCall";
import {WebrtcCallOptions} from "../options/WebrtcCallOptions";
import {DeclineOptions} from "../options/DeclineOptions";
import {DefaultWebrtcCall} from "./DefaultWebrtcCall";
import {EventEmitter} from "events";
import {InfobipGateway} from "../../gateway/InfobipGateway";
import {Logger} from "../../log/Logger";
import {Device} from "../../device/Device";
import {Endpoint, WebrtcEndpoint} from "../../util/Endpoint";
import {DefaultIncomingApplicationCall} from "./DefaultIncomingApplicationCall";
import {Properties} from "../../Properties";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import {CallStatus} from "../CallStatus";

export class DefaultIncomingWebrtcCall extends DefaultWebrtcCall implements IncomingWebrtcCall {
    constructor(protected eventEmitter: EventEmitter,
                protected gateway: InfobipGateway,
                protected logger: Logger,
                protected rtcConfig: any,
                protected device: Device,
                private hasRemoteVideo: boolean,
                currentUserIdentity: string,
                counterpartIdentity: string,
                remoteOffer: RTCSessionDescriptionInit,
                token: string,
                apiUrl: string,
                correlationId: string) {

        super(WebrtcCallOptions.builder().build(), new WebrtcEndpoint(counterpartIdentity), new WebrtcEndpoint(currentUserIdentity));
        this.applicationCall = new DefaultIncomingApplicationCall(this.eventEmitter, this.gateway, this.logger, this.rtcConfig,
            this.device, counterpartIdentity, Properties.WEBRTC_APPLICATION_ID, remoteOffer, currentUserIdentity, token, apiUrl, correlationId);
        this.initEventHandlers();
    }

    private static mapOptions(webrtcCallOptions: WebrtcCallOptions): ApplicationCallOptions {
        return ApplicationCallOptions.builder()
            .setAudio(webrtcCallOptions.audio)
            .setAudioOptions(webrtcCallOptions.audioOptions)
            .setVideo(webrtcCallOptions.video)
            .setVideoOptions(webrtcCallOptions.videoOptions)
            .setCustomData(webrtcCallOptions.customData)
            .setDataChannel(webrtcCallOptions.dataChannel)
            .build();
    }

    hasRemoteCameraVideo(): boolean {
        if (this.status() === CallStatus.RINGING || this.status() === CallStatus.CONNECTING) {
            return this.hasRemoteVideo;
        }
        return super.hasRemoteCameraVideo();
    }

    counterpart(): Endpoint {
        return this.sourceEndpoint;
    }

    accept(options?: WebrtcCallOptions): void {
        this.basicCallOptions = options || this.basicCallOptions;
        (<DefaultIncomingApplicationCall>this.applicationCall).accept(DefaultIncomingWebrtcCall.mapOptions(<WebrtcCallOptions>this.basicCallOptions));
    }

    decline(options?: DeclineOptions): void {
        (<DefaultIncomingApplicationCall>this.applicationCall).decline(options);
    }
}
