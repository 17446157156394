import {EventEmitter} from "events";
import {v4 as uuid} from "uuid";
import {CallsApiEvent} from "../event/CallsApiEvents";
import {SetupDataChannelEvent} from "../ws/event/DataChannelEvents";
import {WsAction} from "../ws/WsAction";
import {Participant} from "../../util/Participant";
import {Logger} from "../../log/Logger";
import {InfobipGateway} from "../../gateway/InfobipGateway";
import {ApiEventEmitter} from "../../util/ApiEventEmitter";
import {DataChannel} from "../DataChannel";
import {AnyDataChannelEvent, DataChannelEvent, DataChannelEvents} from "../event/DataChannelEvents";
import {DataChannelEventHandlers} from "../event/DataChannelEventHandlers";
import {ApplicationErrorCode} from "../ApplicationErrorCode";
import {AcquiredLock, AsyncLock} from "./util/AsyncLock";
import {Endpoint} from "../../util/Endpoint";

type ParticipantMap = { [identity: string]: Participant };
type IceCandidateHandler = (ev: RTCPeerConnectionIceEvent) => any;

interface JanusLeaveData {
    textroom: string;
    room: string;
    username: string;
}

interface JanusErrorData {
    transaction: string;
    textroom: string;
}

interface JanusJoinData {
    textroom: string;
    room: string;
    username: string;
}

interface JanusMessageData {
    date: string;
    from: string;
    room: string;
    text: string;
    textroom: string;
    whisper?: boolean;
}

interface JanusAnnouncementData {
    date: string
    text: string
}

interface JanusSuccessData {
    textroom: string;
    transaction: string;
    sent?: { [identity: string]: boolean };
    participants?: { username: string }[];
}

const MAX_MSGS_PER_SEC = 50;
const BUFFER_THRESHOLD = 8192;

export class DefaultDataChannel implements DataChannel {
    private readonly dataEventEmitter = new EventEmitter();
    private textRoomId: string;
    private dataChannel: RTCDataChannel;
    private textRoomParticipants: ParticipantMap = {};
    private dataChannelPc: RTCPeerConnection;
    private readonly availabilityLock: AsyncLock = new AsyncLock();
    private readonly timeLock: AsyncLock = new AsyncLock();
    private readonly bufferLock: AsyncLock = new AsyncLock();
    private readonly pendingMessages: { [transactionId: string]: string } = {};

    private currentAvailabilityLock: AcquiredLock;
    private currentBufferLock: AcquiredLock;

    constructor(private gateway: InfobipGateway,
                private logger: Logger,
                private callId: string,
                private currentUserIdentity: string,
                private participantResolver: (identity: string) => Participant,
                private iceCandidateHandler: IceCandidateHandler,
                private canSendMessage: () => boolean,
                private apiEventEmitter: ApiEventEmitter,
                private conferenceId: string,
    ) {
        this.setupAvailabilityLock();
    }

    private setupAvailabilityLock() {
        this.availabilityLock.acquireLock().then(lock => {
            const oldLock = this.currentAvailabilityLock;
            this.currentAvailabilityLock = lock;
            oldLock?.release();
        });
    }

    public initialize(event: SetupDataChannelEvent, rtcConfig?: RTCConfiguration) {
        this.textRoomId = event.id;

        this.dataChannelPc = new RTCPeerConnection(rtcConfig);
        this.dataChannelPc.ondatachannel = event => this.onDataChannel(event);
        this.dataChannelPc.onicecandidate = ev => this.iceCandidateHandler(ev);

        this.dataChannel = this.dataChannelPc.createDataChannel(this.conferenceId);
        this.initializeDataChannelListeners();

        this.sendAnswer(event.description);
    }

    on(name: AnyDataChannelEvent, handler: DataChannelEventHandlers.Any): void {
        if (!Object.values(DataChannelEvent)
            .find(apiEvent => apiEvent === name)) {
            throw new Error(`Unknown event: ${name}!`);
        }
        this.dataEventEmitter.on(name, handler);
    }

    async send(text: string, to?: Endpoint): Promise<string> {
        if (!this.canSendMessage()) {
            this.logger.warn("Data channel is unavailable until a call is added to a conference or a dialog!");
            throw {
                id: '10103',
                name: 'MEDIA_ERROR',
                description: 'Data channel is unavailable until a call is added to a conference or a dialog'
            };
        }

        return await this.availabilityLock.withLock(async () => {
            if (!this.dataChannel || this.dataChannel.readyState !== 'open') {
                this.logger.warn("Data channel is unavailable!");
                throw {
                    id: '10103',
                    name: 'MEDIA_ERROR',
                    description: 'Data channel is unavailable'
                };
            }

            if (this.dataChannel?.bufferedAmount >= BUFFER_THRESHOLD) {
                this.currentBufferLock?.release();
                this.currentBufferLock = await this.bufferLock.acquireLock();
            }

            const currentTimeLock = await this.timeLock.acquireLock();
            setTimeout(() => currentTimeLock.release(), 1000 / MAX_MSGS_PER_SEC);

            let transactionId = uuid();
            let recipient = this.getMessageRecipient(to);
            this.sendTextMessage(transactionId, recipient, text);

            return transactionId;
        });
    }

    destroy() {
        this.dataChannel?.close();
        this.dataChannelPc?.close();
        this.dataChannelPc = null;
        this.dataChannel = null;
    }

    private getMessageRecipient(to?: Endpoint): string {
        if (!to) {
            return null;
        }
        const identifier = to.identifier;
        if (!this.participantResolver(identifier)) {
            this.logger.warn(`Unknown recipient! ${identifier}`, this.callId);
            throw {
                id: '10103',
                name: 'MEDIA_ERROR',
                description: 'Recipient cannot be found'
            };
        }
        if (!this.textRoomParticipants[identifier]) {
            this.logger.warn(`Recipient data channel is unavailable! ${identifier}`, this.callId);

            throw {
                id: '10103',
                name: 'MEDIA_ERROR',
                description: 'Recipient\'s data channel is unavailable'
            };
        }
        return identifier;
    }

    private onDataChannel(event: RTCDataChannelEvent) {
        const dataChannel = event.channel;
        dataChannel.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            if (data.textroom === "join") {
                this.handleJoinMessage(<JanusJoinData>data);
            } else if (data.textroom === "success") {
                this.handleSuccessMessage(<JanusSuccessData>data);
            } else if (data.textroom === "message") {
                this.handleDataMessage(<JanusMessageData>data);
            } else if (data.textroom === "announcement") {
                this.handleAnnouncementData(<JanusAnnouncementData>data);
            } else if (data.textroom === "leave") {
                this.handleLeaveMessage(<JanusLeaveData>data);
            } else if (data.textroom === "error") {
                this.handleErrorMessage(<JanusErrorData>data);
            }
        };
    }

    private handleErrorMessage(data: JanusErrorData) {
        const transactionId = data.transaction;
        if (this.pendingMessages[transactionId]) {
            this.emitDataSent(transactionId, false);
            delete this.pendingMessages[transactionId];
        } else {
            this.logger.error(`Received TextRoom Plugin error from Janus: ${JSON.stringify(data)}`, this.callId);
            this.apiEventEmitter.emit(CallsApiEvent.ERROR, {
                errorCode: {
                    name: "EC_INTERNAL_SERVER_ERROR",
                    description: "Internal Server Error."
                }
            });
        }
    }

    private handleDataMessage(data: JanusMessageData) {
        const text: string = data.text;
        const isDirect: boolean = !!data.whisper;
        const from = this.textRoomParticipants[data.from];

        if (data.from !== this.currentUserIdentity) {
            this.emitDataReceived(text, from.endpoint, isDirect, new Date(data.date));
        }
    }

    private handleAnnouncementData({text, date}: JanusAnnouncementData) {
        this.emitBroadcastTextReceived(text, new Date(date));
    }

    private emitDataSent(id: string, delivered: boolean) {
        this.dataEventEmitter.emit(DataChannelEvent.TEXT_DELIVERED_EVENT, <DataChannelEvents.TextDeliveredEvent>{
            id: id,
            date: new Date(),
            delivered: delivered
        });
    }

    private emitDataReceived(text: string, from: Endpoint, isDirect: boolean, date: Date) {
        this.dataEventEmitter.emit(DataChannelEvent.TEXT_RECEIVED_EVENT, <DataChannelEvents.TextReceivedEvent>{
            text: text,
            isDirect: isDirect,
            from: from,
            date: date
        });
    }

    private emitBroadcastTextReceived(text: string, date: Date) {
        this.dataEventEmitter.emit(DataChannelEvent.BROADCAST_TEXT_RECEIVED_EVENT, <DataChannelEvents.BroadcastTextReceivedEvent>{
            text: text,
            date: date
        });
    }

    private handleLeaveMessage(data: JanusLeaveData) {
        let username = data.username;
        delete this.textRoomParticipants[username];
    }

    private handleSuccessMessage(data: JanusSuccessData) {
        let participants = data.participants;
        if (participants) {
            for (const participant of participants) {
                let username = participant.username;
                this.textRoomParticipants[username] = this.participantResolver(username);
            }
            this.currentAvailabilityLock?.release();
        }
        let sent = data.sent;
        if (sent) {
            Object.keys(sent).forEach(identity => {
                const success = sent[identity];
                this.emitDataSent(data.transaction, success);
            });
        } else {
            this.emitDataSent(data.transaction, true);
        }
    }

    private handleJoinMessage(data: JanusJoinData) {
        const username = data.username;
        this.textRoomParticipants[username] = this.participantResolver(username);
    }

    private initializeDataChannelListeners() {
        this.dataChannel.onopen = () => this.sendJoinMessage();
        this.dataChannel.onerror = (errorEvent: RTCErrorEvent) => {
            if (errorEvent.error.errorDetail === "data-channel-failure") {
                this.logger.warn(`Data channel failure! ${errorEvent?.error?.message}`);
                this.apiEventEmitter.emit(CallsApiEvent.ERROR, {errorCode: ApplicationErrorCode.MEDIA_ERROR});
            }
        }
        this.dataChannel.bufferedAmountLowThreshold = BUFFER_THRESHOLD / 2;
        this.dataChannel.onbufferedamountlow = () => {
            this.currentBufferLock?.release();
        };
    };

    private async sendAnswer(description: RTCSessionDescriptionInit) {
        await this.dataChannelPc.setRemoteDescription(description);
        const answer = await this.dataChannelPc.createAnswer();
        await this.dataChannelPc.setLocalDescription(answer);

        this.gateway.send({
            action: WsAction.SETUP_ACK,
            description: answer
        });
    }

    private sendJoinMessage() {
        if (!this.dataChannel || this.dataChannel.readyState !== 'open') {
            this.logger.warn('Data channel is not open!', this.callId);
            return;
        }
        const janusMessage = {
            textroom: "join",
            transaction: uuid(),
            room: this.textRoomId,
            username: this.currentUserIdentity
        };
        this.sendDataChannelMessage(janusMessage);
    }

    private sendTextMessage(transactionId: string, recipient: string, text: string) {
        this.pendingMessages[transactionId] = recipient;
        const janusMessage = {
            textroom: "message",
            transaction: transactionId,
            room: this.textRoomId,
            to: recipient || undefined,
            text: text
        };
        this.sendDataChannelMessage(janusMessage);
    }

    private sendDataChannelMessage(janusMessage: any) {
        try {
            this.dataChannel.send(JSON.stringify(janusMessage));
        } catch (error) {
            this.logger.warn(`Failed sending message through data channel! ${error?.code} ${error?.message}`, this.callId);
            throw ApplicationErrorCode.MEDIA_ERROR;
        }
    }
}
