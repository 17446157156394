export class DeclineOptions {
    constructor(private _declineOnAllDevices: boolean) {
    }

    get declineOnAllDevices(): boolean {
        return this._declineOnAllDevices;
    }

    static builder(): DeclineOptionsBuilder {
        return new DeclineOptionsBuilder();
    }
}

class DeclineOptionsBuilder {
    private _declineOnAllDevices: boolean = false;

    setDeclineOnAllDevices(_declineOnAllDevices: boolean): DeclineOptionsBuilder {
        this._declineOnAllDevices = _declineOnAllDevices;
        return this;
    }

    build() {
        return new DeclineOptions(this._declineOnAllDevices);
    }
}
