export enum InfobipRTCEvent {
    CONNECTED = 'connected',
    RECONNECTING = 'reconnecting',
    RECONNECTED = 'reconnected',
    DISCONNECTED = 'disconnected',
    INCOMING_APPLICATION_CALL = 'incoming-application-call',
    INCOMING_WEBRTC_CALL = 'incoming-webrtc-call'
}

export type AnyInfobipRTCEvent =
    InfobipRTCEvent.CONNECTED |
    InfobipRTCEvent.RECONNECTING |
    InfobipRTCEvent.RECONNECTED |
    InfobipRTCEvent.DISCONNECTED |
    InfobipRTCEvent.INCOMING_APPLICATION_CALL |
    InfobipRTCEvent.INCOMING_WEBRTC_CALL;

export namespace InfobipRTCEvents {
    export type ConnectedEvent = { identity: string }
    export type ReconnectedEvent = {}
    export type ReconnectingEvent = {}
    export type DisconnectedEvent = { reason: string }
}
