export abstract class LocalMediaStats {
    public id: string;
    public trackId: string;
    public codec: string;
    public packetsSent: number = 0;
    public bytesSent: number = 0;
    public retransmittedPacketsSent: number = 0;
    public retransmittedBytesSent: number = 0;

    protected constructor(id: string) {
        this.id = id;
    }

    public abstract get video(): boolean;

    public toJSON(): any {
        return {
            trackId: this.trackId,
            codec: this.codec,
            packetsSent: this.packetsSent,
            bytesSent: this.bytesSent,
            retransmittedPacketsSent: this.retransmittedPacketsSent,
            retransmittedBytesSent: this.retransmittedBytesSent,
            category: "local"
        };
    }
}