import {ErrorCode} from "./event/ErrorCode";

const applicationErrorCode = {
    NORMAL_HANGUP: {
        id: '10000',
        name: 'NORMAL_HANGUP',
        description: 'The call ended due to a hangup initiated by the caller, callee, or API'
    },
    ANSWERED_ELSEWHERE: {
        id: '10001',
        name: 'ANSWERED_ELSEWHERE',
        description: 'Another device answered the call'
    },
    MACHINE_DETECTED: {
        id: '10002',
        name: 'MACHINE_DETECTED',
        description: 'A voice machine answered the call'
    },
    HUMAN_DETECTED: {
        id: '10003',
        name: 'HUMAN_DETECTED',
        description: 'A person (not a voice machine) answered the call'
    },
    DEVICE_FORBIDDEN: {
        id: '10100',
        name: 'DEVICE_FORBIDDEN',
        description: 'Permission to use camera and/or microphone was denied'
    },
    DEVICE_NOT_FOUND: {
        id: '10101',
        name: 'DEVICE_NOT_FOUND',
        description: 'Camera and/or microphone not found'
    },
    DEVICE_UNAVAILABLE: {
        id: '10102',
        name: 'DEVICE_UNAVAILABLE',
        description: 'Camera and/or microphone not available'
    },
    MEDIA_ERROR: {
        id: '10103',
        name: 'MEDIA_ERROR',
        description: 'Media invalid or unavailable'
    },
    FORBIDDEN_DISPLAY_SURFACE: {
        id: '10104',
        name: 'FORBIDDEN_DISPLAY_SURFACE',
        description: 'Forbidden screen sharing display surface selected'
    },
    SCREEN_SHARING_CONTROL_UNSUPPORTED: {
        id: '10105',
        name: 'SCREEN_SHARING_CONTROL_UNSUPPORTED',
        description: 'Browser does not support screen sharing control'
    },
    NO_ANSWER: {
        id: '10200',
        name: 'NO_ANSWER',
        description: 'The end user received a call but didn\'t answer it'
    },
    BUSY: {
        id: '10201',
        name: 'BUSY',
        description: 'The end user is currently busy to receive the voice call'
    },
    CANCELLED: {
        id: '10202',
        name: 'CANCELLED',
        description: 'The end user canceled the call'
    },
    REJECTED: {
        id: '10203',
        name: 'REJECTED',
        description: 'The destination cannot join the call at the moment'
    },
    FORBIDDEN: {
        id: '10300',
        name: 'FORBIDDEN',
        description: 'The received request was rejected'
    },
    INSUFFICIENT_FUNDS: {
        id: '10301',
        name: 'INSUFFICIENT_FUNDS',
        description: 'Insufficient funds to make a call'
    },
    UNAUTHENTICATED: {
        id: '10302',
        name: 'UNAUTHENTICATED',
        description: 'The request requires user authentication on the operator\'s end'
    },
    DESTINATION_NOT_FOUND: {
        id: '10303',
        name: 'DESTINATION_NOT_FOUND',
        description: 'The server has definitive information that the user does not exist'
    },
    DESTINATION_UNAVAILABLE: {
        id: '10304',
        name: 'DESTINATION_UNAVAILABLE',
        description: 'The destination is currently out of service'
    },
    INVALID_DESTINATION: {
        id: '10305',
        name: 'INVALID_DESTINATION',
        description: 'The specified destination is invalid'
    },
    INVALID_REQUEST: {
        id: '10306',
        name: 'INVALID_REQUEST',
        description: 'The format of the request is invalid'
    },
    REQUEST_TIMEOUT: {
        id: '10307',
        name: 'REQUEST_TIMEOUT',
        description: 'There was no coverage for a specific destination, or the end user could not be reached in time for the call'
    },
    NETWORK_ERROR: {
        id: '10308',
        name: 'NETWORK_ERROR',
        description: 'There has been a network connection error'
    },
    SERVICE_UNAVAILABLE: {
        id: '10309',
        name: 'SERVICE_UNAVAILABLE',
        description: 'Service currently unavailable'
    },
    UNKNOWN: {
        id: '10310',
        name: 'UNKNOWN',
        description: 'The call has ended due to an unknown reason'
    },
    FEATURE_UNAVAILABLE: {
        id: '10311',
        name: 'FEATURE_UNAVAILABLE',
        description: 'The requested feature is not enabled'
    },
    URL_NOT_FOUND: {
        id: '10400',
        name: 'URL_NOT_FOUND',
        description: 'The specified application URL does not exist or cannot be found'
    },
    URL_UNREACHABLE: {
        id: '10401',
        name: 'URL_UNREACHABLE',
        description: 'The specified application URL is unreachable'
    },
    INVALID_RESPONSE: {
        id: '10402',
        name: 'INVALID_RESPONSE',
        description: 'Specified application returned response with invalid format'
    }
};

export const ApplicationErrorCode: { [name in keyof typeof applicationErrorCode]: ErrorCode } = applicationErrorCode;
