import {Participant} from "../../util/Participant";
import {NetworkQuality} from "./NetworkQuality";
import VideoRemovalReason from "../../util/VideoRemovalReason";
import {CurrentMediaStats} from "../stats/CurrentMediaStats";
import {TotalMediaStats} from "../stats/TotalMediaStats";
import {ErrorCode} from "./ErrorCode";

export enum CallsApiEvent {
    RINGING = 'ringing',
    EARLY_MEDIA = 'early-media',
    ESTABLISHED = 'established',
    HANGUP = 'hangup',
    ERROR = 'error',
    CAMERA_VIDEO_ADDED = 'camera-video-added',
    CAMERA_VIDEO_UPDATED = 'camera-video-updated',
    CAMERA_VIDEO_REMOVED = 'camera-video-removed',
    SCREEN_SHARE_ADDED = 'screen-share-added',
    SCREEN_SHARE_REMOVED = 'screen-share-removed',
    CONFERENCE_JOINED = 'conference-joined',
    CONFERENCE_LEFT = 'conference-left',
    PARTICIPANT_JOINING = 'participant-joining',
    PARTICIPANT_JOINED = 'participant-joined',
    PARTICIPANT_MUTED = 'participant-muted',
    PARTICIPANT_UNMUTED = 'participant-unmuted',
    PARTICIPANT_DEAF = 'participant-deaf',
    PARTICIPANT_UNDEAF = 'participant-undeaf',
    PARTICIPANT_STARTED_TALKING = 'participant-started-talking',
    PARTICIPANT_STOPPED_TALKING = 'participant-stopped-talking',
    PARTICIPANT_LEFT = 'participant-left',
    PARTICIPANT_CAMERA_VIDEO_ADDED = 'participant-camera-video-added',
    PARTICIPANT_CAMERA_VIDEO_REMOVED = 'participant-camera-video-removed',
    PARTICIPANT_SCREEN_SHARE_ADDED = 'participant-screen-share-added',
    PARTICIPANT_SCREEN_SHARE_REMOVED = 'participant-screen-share-removed',
    PARTICIPANT_NETWORK_QUALITY_CHANGED = 'participant-network-quality-changed',
    ROOM_JOINED = 'room-joined',
    ROOM_LEFT = 'room-left',
    REMOTE_MUTED = 'remote-muted',
    REMOTE_UNMUTED = 'remote-unmuted',
    REMOTE_CAMERA_VIDEO_ADDED = 'remote-camera-video-added',
    REMOTE_CAMERA_VIDEO_REMOVED = 'remote-camera-video-removed',
    REMOTE_SCREEN_SHARE_ADDED = 'remote-screen-share-added',
    REMOTE_SCREEN_SHARE_REMOVED = 'remote-screen-share-removed',
    NETWORK_QUALITY_CHANGED = 'network-quality-changed',
    REMOTE_NETWORK_QUALITY_CHANGED = 'remote-network-quality-changed',
    DIALOG_JOINED = 'dialog-joined',
    DIALOG_LEFT = 'dialog-left',
    RECONNECTING = 'reconnecting',
    RECONNECTED = 'reconnected',
    ROOM_REJOINED = 'room-rejoined',
    ROOM_REJOINING = 'room-rejoining',
}

export type AnyCallsApiEvent = CallsApiEvent.RINGING |
    CallsApiEvent.EARLY_MEDIA |
    CallsApiEvent.ESTABLISHED |
    CallsApiEvent.HANGUP |
    CallsApiEvent.ERROR |
    CallsApiEvent.CAMERA_VIDEO_ADDED |
    CallsApiEvent.CAMERA_VIDEO_UPDATED |
    CallsApiEvent.CAMERA_VIDEO_REMOVED |
    CallsApiEvent.SCREEN_SHARE_ADDED |
    CallsApiEvent.SCREEN_SHARE_REMOVED |
    CallsApiEvent.CONFERENCE_JOINED |
    CallsApiEvent.CONFERENCE_LEFT |
    CallsApiEvent.ROOM_JOINED |
    CallsApiEvent.ROOM_LEFT |
    CallsApiEvent.PARTICIPANT_JOINING |
    CallsApiEvent.PARTICIPANT_JOINED |
    CallsApiEvent.PARTICIPANT_MUTED |
    CallsApiEvent.PARTICIPANT_UNMUTED |
    CallsApiEvent.PARTICIPANT_DEAF |
    CallsApiEvent.PARTICIPANT_UNDEAF |
    CallsApiEvent.PARTICIPANT_STARTED_TALKING |
    CallsApiEvent.PARTICIPANT_STOPPED_TALKING |
    CallsApiEvent.PARTICIPANT_LEFT |
    CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED |
    CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED |
    CallsApiEvent.PARTICIPANT_SCREEN_SHARE_ADDED |
    CallsApiEvent.PARTICIPANT_SCREEN_SHARE_REMOVED |
    CallsApiEvent.REMOTE_MUTED |
    CallsApiEvent.REMOTE_UNMUTED |
    CallsApiEvent.REMOTE_CAMERA_VIDEO_ADDED |
    CallsApiEvent.REMOTE_CAMERA_VIDEO_REMOVED |
    CallsApiEvent.REMOTE_SCREEN_SHARE_ADDED |
    CallsApiEvent.REMOTE_SCREEN_SHARE_REMOVED |
    CallsApiEvent.NETWORK_QUALITY_CHANGED |
    CallsApiEvent.PARTICIPANT_NETWORK_QUALITY_CHANGED |
    CallsApiEvent.REMOTE_NETWORK_QUALITY_CHANGED |
    CallsApiEvent.DIALOG_JOINED |
    CallsApiEvent.DIALOG_LEFT |
    CallsApiEvent.RECONNECTED |
    CallsApiEvent.RECONNECTING |
    CallsApiEvent.ROOM_REJOINED |
    CallsApiEvent.ROOM_REJOINING;

export namespace CallsApiEvents {
    export type RingingEvent = {};
    export type EarlyMediaEvent = { stream: MediaStream };
    export type EstablishedEvent = { stream: MediaStream };
    export type HangupEvent = { errorCode: ErrorCode, totalMediaStats: TotalMediaStats };
    export type ErrorEvent = { errorCode: ErrorCode };
    export type CameraVideoAddedEvent = { stream: MediaStream };
    export type CameraVideoUpdatedEvent = { stream: MediaStream };
    export type CameraVideoRemovedEvent = {};
    export type ScreenShareAddedEvent = { stream: MediaStream };
    export type ScreenShareRemovedEvent = { reason: VideoRemovalReason };
    export type ConferenceJoinedEvent = { id: string, name: string, participants: Participant[] };
    export type ConferenceLeftEvent = { errorCode: ErrorCode };
    export type RoomJoinedEvent = { id: string, name: string, participants: Participant[], stream: MediaStream };
    export type RoomRejoinedEvent = { id: string, name: string, participants: Participant[], stream: MediaStream };
    export type RoomRejoiningEvent = {};
    export type RoomLeftEvent = { errorCode: ErrorCode, totalMediaStats: TotalMediaStats };
    export type ConferenceFinishedEvent = { errorCode: ErrorCode };
    export type ParticipantJoiningEvent = { participant: Participant };
    export type ParticipantJoinedEvent = { participant: Participant };
    export type ParticipantMutedEvent = { participant: Participant };
    export type ParticipantUnmutedEvent = { participant: Participant };
    export type ParticipantDeafEvent = { participant: Participant };
    export type ParticipantUndeafEvent = { participant: Participant };
    export type ParticipantStartedTalkingEvent = { participant: Participant };
    export type ParticipantStoppedTalkingEvent = { participant: Participant };
    export type ParticipantRemovedEvent = { participant: Participant };
    export type ParticipantCameraVideoAddedEvent = { participant: Participant, stream: MediaStream };
    export type ParticipantCameraVideoRemovedEvent = { participant: Participant };
    export type ParticipantScreenShareAddedEvent = { participant: Participant, stream: MediaStream };
    export type ParticipantScreenShareRemovedEvent = { participant: Participant };
    export type NetworkQualityChangedEvent = { networkQuality: NetworkQuality, currentMediaStats: CurrentMediaStats };
    export type ParticipantNetworkQualityChangedEvent = { participant: Participant, networkQuality: NetworkQuality };
    export type ReconnectedEvent = { stream: MediaStream };
    export type ReconnectingEvent = {};

    export type RemoteMutedEvent = {};
    export type RemoteUnmutedEvent = {};
    export type RemoteCameraVideoAddedEvent = { stream: MediaStream };
    export type RemoteCameraVideoRemovedEvent = {};
    export type RemoteScreenShareAddedEvent = { stream: MediaStream };
    export type RemoteScreenShareRemovedEvent = {};
    export type RemoteNetworkQualityChangedEvent = { networkQuality: NetworkQuality };
    export type DialogJoinedEvent = { id: string, remote: Participant };
    export type DialogLeftEvent = { errorCode: ErrorCode };
}
