export interface HttpClient {
    get<T, E = any>(url: string, headers?: Headers): Promise<ApiResponse<T, E>>;

    post<T, E = any>(url: string, data?: any, headers?: Headers): Promise<ApiResponse<T, E>>;

    put<T, E = any>(url: string, data?: any, headers?: Headers): Promise<ApiResponse<T, E>>;

    delete<T, E = any>(url: string, headers?: Headers): Promise<ApiResponse<T, E>>;
}

export type ApiResponse<T, E = any> = {
    ok: boolean;
    statusCode: number;
    data?: T;
    error?: E;
};

export type Method = "POST" | "PUT" | "GET" | "DELETE";
export type Headers = { [name: string]: string }

const networkErrorMessages = new Set([
    'network error', // Chrome
    'Failed to fetch', // Chrome
    'NetworkError when attempting to fetch resource.', // Firefox
    'The Internet connection appears to be offline.', // Safari 16
    'Load failed', // Safari 17+
    'Network request failed', // `cross-fetch`
    'fetch failed', // Undici (Node.js)
]);

const DEFAULT_NETWORK_ERROR_MESSAGE = "Network error occurred.";

export class NetworkError extends Error {
    constructor(message?: string) {
        super(message || DEFAULT_NETWORK_ERROR_MESSAGE);
        Object.setPrototypeOf(this, NetworkError.prototype);
        this.name = 'NetworkError';
    }
}

function isNetworkError(error: Error) {
    const isValid = error.name === 'TypeError' && typeof error.message === 'string';

    if (!isValid) {
        return false;
    }

    // We do an extra check for Safari 17+ as it has a very generic error message.
    // Network errors in Safari have no stack.
    if (error.message === 'Load failed') {
        return error.stack === undefined;
    }

    return networkErrorMessages.has(error.message);
}

export class DefaultHttpClient implements HttpClient {
    constructor(private baseURL: string, private headers?: Headers) {
    }

    delete<T, E = any>(url: string, headers?: Headers): Promise<ApiResponse<T, E>> {
        return this.request(url, "DELETE", headers);
    }

    get<T, E = any>(url: string, headers?: Headers): Promise<ApiResponse<T, E>> {
        return this.request(url, "GET", headers)
    }

    post<T, E = any>(url: string, data?: any, headers?: Headers): Promise<ApiResponse<T, E>> {
        return this.request(url, "POST", data, headers);
    }

    put<T, E = any>(url: string, data?: any, headers?: Headers): Promise<ApiResponse<T, E>> {
        return this.request(url, "PUT", data, headers);
    }

    private async request<T, E = any>(url: string, method: Method, data?: any, headers?: Headers): Promise<ApiResponse<T, E>> {
        const fullPath = this.baseURL + url;

        const options: RequestInit = {
            method: method
        }

        if (data != null) {
            const isFormData = data instanceof FormData;
            options.body = isFormData ? data : JSON.stringify(data);
        }

        if (this.headers != null || headers != null) {
            options.headers = {
                ...this.headers,
                ...headers
            }
        }

        try {
            const response = await fetch(fullPath, options);
            const resultJson = await response.json();

            const result: ApiResponse<T, E> = {
                statusCode: response.status,
                ok: response.ok
            };

            if (result.ok) {
                result.data = resultJson;
            } else {
                result.error = resultJson;
            }

            return result;
        } catch (e) {
            if (isNetworkError(e)) {
                throw new NetworkError()
            }
            throw e;
        }
    }
}
