import {ApplicationCallOptions} from "./ApplicationCallOptions";
import {CustomData} from "../CustomDataType";

export class InternalApplicationCallOptions extends ApplicationCallOptions {
    constructor(private _applicationCallOptions: ApplicationCallOptions, private _internalCustomData?: CustomData) {
        super(
            _applicationCallOptions.audio,
            _applicationCallOptions.audioOptions,
            _applicationCallOptions.video,
            _applicationCallOptions.videoOptions,
            _applicationCallOptions.customData,
            _applicationCallOptions.entityId,
            _applicationCallOptions.dataChannel
        );
    }

    public internalCustomData(): CustomData {
        return this._internalCustomData;
    }

    public setInternalCustomData(customData: CustomData): void {
        this._internalCustomData = customData;
    }
}
