export class MediaDevice {
    readonly deviceId: string;
    readonly groupId: string;
    readonly kind: MediaDeviceKind;
    readonly label: string;

    constructor(deviceId: string, groupId: string, kind: MediaDeviceKind, label: string) {
        this.deviceId = deviceId;
        this.groupId = groupId;
        this.kind = kind;
        this.label = label;
    }
}
