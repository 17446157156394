export abstract class RemoteMediaStats {
    public id: string;
    public trackId: string;
    public codec: string;
    public packetsReceived: number = 0;
    public bytesReceived: number = 0;
    public packetsLost: number = 0;
    public jitterBufferDelay: number = 0;
    public jitterBufferEmittedCount: number = 0;
    public averageJitterBufferDelay: number = 0;

    protected constructor(id: string) {
        this.id = id;
    }

    public abstract get video(): boolean;

    public toJSON(): any {
        return {
            trackId: this.trackId,
            codec: this.codec,
            packetsReceived: this.packetsReceived,
            bytesReceived: this.bytesReceived,
            packetsLost: this.packetsLost,
            averageJitterBufferDelay: this.averageJitterBufferDelay,
            category: "remote"
        }
    }
}