import {AudioOptions} from "./AudioOptions";
import {VideoOptions} from "./VideoOptions";
import {CameraOrientation} from "./CameraOrientation";
import {RoomCallRecordingOptions} from "./RoomCallRecordingOptions";
import {VideoMode} from "./VideoMode";
import {CustomData} from "../CustomDataType";
import {AudioQualityMode} from "./AudioQualityMode";

export class RoomCallOptions {
    constructor(private _audio: boolean,
                private _audioOptions: AudioOptions,
                private _video: boolean,
                private _videoOptions: VideoOptions,
                private _recordingOptions: RoomCallRecordingOptions,
                private _customData: CustomData,
                private _dataChannel: boolean,
                private _autoRejoin: boolean) {
    }

    get audio(): boolean {
        return this._audio;
    }

    set audio(value: boolean) {
        this._audio = value;
    }

    get audioOptions(): AudioOptions {
        return this._audioOptions;
    }

    set audioOptions(value: AudioOptions) {
        this._audioOptions = value;
    }

    get video(): boolean {
        return this._video;
    }

    set video(value: boolean) {
        this._video = value;
    }

    get videoOptions(): VideoOptions {
        return this._videoOptions;
    }

    set videoOptions(value: VideoOptions) {
        this._videoOptions = value;
    }

    get recordingOptions(): RoomCallRecordingOptions {
        return this._recordingOptions;
    }

    set recordingOptions(value: RoomCallRecordingOptions) {
        this._recordingOptions = value;
    }

    get customData(): CustomData {
        return this._customData;
    }

    set customData(value: CustomData) {
        this._customData = value;
    }

    get dataChannel(): boolean {
        return this._dataChannel;
    }

    set dataChannel(value: boolean) {
        this._dataChannel = value;
    }

    get autoRejoin(): boolean {
        return this._autoRejoin;
    }

    set autoRejoin(value: boolean) {
        this._autoRejoin = value;
    }

    static builder(): RoomCallOptionsBuilder {
        return new RoomCallOptionsBuilder();
    }
}

class RoomCallOptionsBuilder {
    private _audio: boolean = true;
    private _audioOptions: AudioOptions = new AudioOptions(null, AudioQualityMode.AUTO);
    private _video: boolean = false;
    private _videoOptions: VideoOptions = new VideoOptions(CameraOrientation.FRONT, null, VideoMode.PRESENTATION, 24, 8);
    private _recordingOptions: RoomCallRecordingOptions = new RoomCallRecordingOptions();
    private _customData: CustomData = {};
    private _dataChannel: boolean = false;
    private _autoRejoin: boolean = false;

    setAudio(value: boolean): RoomCallOptionsBuilder {
        this._audio = value;
        return this;
    }

    setAudioOptions(value: AudioOptions): RoomCallOptionsBuilder {
        this._audioOptions = value;
        return this;
    }

    setVideo(value: boolean): RoomCallOptionsBuilder {
        this._video = value;
        return this;
    }

    setVideoOptions(value: VideoOptions): RoomCallOptionsBuilder {
        this._videoOptions = value;
        return this;
    }

    setRecordingOptions(value: RoomCallRecordingOptions): RoomCallOptionsBuilder {
        this._recordingOptions = value;
        return this;
    }

    setCustomData(_customData: CustomData): RoomCallOptionsBuilder {
        this._customData = _customData;
        return this;
    }

    setAutoRejoin(value: boolean): RoomCallOptionsBuilder {
        this._autoRejoin = value;
        return this;
    }

    setDataChannel(_dataChannel: boolean): RoomCallOptionsBuilder {
        this._dataChannel = _dataChannel;
        return this;
    }

    build(): RoomCallOptions {
        return new RoomCallOptions(this._audio,
            this._audioOptions,
            this._video,
            this._videoOptions,
            this._recordingOptions,
            this._customData,
            this._dataChannel,
            this._autoRejoin
        );
    }
}
