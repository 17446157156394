import {WebrtcCallOptions} from "../options/WebrtcCallOptions";
import {FlowType} from "../FlowType";
import {Endpoint, WebrtcEndpoint} from "../../util/Endpoint";
import {CustomData} from "../CustomDataType";
import {DefaultWebrtcCall} from "./DefaultWebrtcCall";
import {ApplicationCallOptions} from "../options/ApplicationCallOptions";
import ValidationUtil from "./util/ValidationUtil";
import {InfobipRTC} from "../../InfobipRTC";
import {InternalApplicationCallOptions} from "../options/InternalApplicationCallOptions";
import {getRecordingOptions} from "./util/CallOptionsUtil";

export class DefaultOutgoingWebrtcCall extends DefaultWebrtcCall {
    constructor(infobipRtc: InfobipRTC,
                webrtcCallOptions: WebrtcCallOptions,
                currentUserIdentity: string,
                counterpartIdentity: string) {
        super(webrtcCallOptions, new WebrtcEndpoint(currentUserIdentity), new WebrtcEndpoint(counterpartIdentity));
        let applicationCallOptions = this.mapOptions(counterpartIdentity, webrtcCallOptions);
        this.applicationCall = infobipRtc.callApplication(counterpartIdentity, applicationCallOptions);
        this.initEventHandlers();
    }

    counterpart(): Endpoint {
        return this.destinationEndpoint;
    }

    private mapOptions(counterpartIdentity: string, webrtcCallOptions: WebrtcCallOptions): ApplicationCallOptions {
        if (webrtcCallOptions?.customData) {
            ValidationUtil.validateCustomData(webrtcCallOptions?.customData);
        }

        let internalCustomData: CustomData = {
            to: counterpartIdentity,
            type: FlowType.WEBRTC.toString(),
            ...getRecordingOptions(webrtcCallOptions),
            withDialog: "true",
            ...webrtcCallOptions?.video && {isVideo: "true"}
        }

        let applicationCallOptions = ApplicationCallOptions.builder()
            .setAudio(webrtcCallOptions?.audio)
            .setAudioOptions(webrtcCallOptions?.audioOptions)
            .setVideo(webrtcCallOptions?.video)
            .setVideoOptions(webrtcCallOptions?.videoOptions)
            .setDataChannel(webrtcCallOptions?.dataChannel)
            .build();

        if (webrtcCallOptions?.customData) {
            applicationCallOptions.customData = webrtcCallOptions?.customData;
        }

        return new InternalApplicationCallOptions(
            applicationCallOptions,
            internalCustomData
        );
    }
}
