class HangupReasonFactory {

    static getHangupReason(error: any): string {
        if (!error) {
            return 'Unknown error.';
        }

        if (error.name && error.message) {
            return `${error.name} ${error.message}`;
        }

        return error;
    }
}

export default HangupReasonFactory;
