import {LocalMediaStats} from "../LocalMediaStats";

export class VideoLocalMediaStats extends LocalMediaStats {
    public frameWidth: number;
    public frameHeight: number;
    public framesSent: number = 0;
    public framesEncoded: number = 0;
    public keyFramesEncoded: number = 0;
    public nackCount: number = 0;
    public firCount: number = 0;
    public pliCount: number = 0;
    public qpSum: number = 0;
    public averageQP: number = 0;
    public qualityLimitationReason: string;

    constructor(id: string) {
        super(id);
    }

    get video(): boolean {
        return true;
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            frameWidth: this.frameWidth,
            frameHeight: this.frameHeight,
            framesSent: this.framesSent,
            framesEncoded: this.framesEncoded,
            keyFramesEncoded: this.keyFramesEncoded,
            nackCount: this.nackCount,
            firCount: this.firCount,
            pliCount: this.pliCount,
            averageQP: this.averageQP,
            qualityLimitationReason: this.qualityLimitationReason
        };
    }
}
