import jwtDecode from 'jwt-decode';
import Properties from "./Properties";
import Status from "./Status";
import {EventEmitter} from "events";
import {version} from "../Version";
import Retry from '../util/Retry';
import {Logger} from "../log/Logger";
import Browser from '../util/Browser';

export interface InfobipGateway {
    status: Status
    connect(isReconnect: boolean): void
    disconnect(): void
    send(message: any): void
    setLogger(logger: Logger): void
}

export class InfobipGatewayImpl implements InfobipGateway {
    status = Status.OFFLINE;

    private ws: WebSocket;
    private readonly url: string;
    private retry: Retry;
    private heartbeat: any;
    private heartbeatCheck: any;
    private deviceInfo: string;

    constructor(private eventEmitter: EventEmitter, private logger: Logger, private token: string) {
        this.url = this.generatePortunusUrl(jwtDecode(token));
        this.deviceInfo = this.encodeDeviceInfo();
        this.initRetry();
    }

    connect(isReconnect = false) {
        this.ws = new WebSocket(`wss://${this.url}`, [this.token, this.deviceInfo]);
        this.status = isReconnect ? Status.RECONNECTING : Status.CONNECTING;
        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onclose = this.onClose.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onerror = this.onError.bind(this); // TODO
    }

    disconnect() {
        if (this.status === Status.CONNECTED) {
            this.ws.close(4000, "USER_DISCONNECT");
        }
        this.status = Status.OFFLINE;
    };

    send(data: any) {
        if (this.status !== Status.CONNECTED) {
            this.logger?.error(`Socket to Infobip WebRTC Gateway not opened, could not send ${JSON.stringify(data)}!`);
            return;
        }
        this.logSendingMessage(data);
        let message = JSON.stringify(data);
        this.ws.send(message);
    }

    setLogger(logger: Logger) {
        this.logger = logger;
    }

    private encodeDeviceInfo() {
        let generatedDeviceInfo = JSON.stringify(this.generateDeviceInfo());
        return this.base64EncodeUrl(window.btoa(generatedDeviceInfo));
    }

    private generatePortunusUrl(token: any) {
        let location = token.location;

        if (!location) {
            return "portunus.infobip.com";
        }

        // TODO: remove when io is no longer in use
        if (location === "io") {
            return "portunus.ioinfobip.com";
        }

        if (location === "iop1") {
            return "portunus-iop1.ioinfobip.com";
        }

        return "portunus-" + location + ".infobip.com";
    }

    private logSendingMessage(message: any) {
        if (message.action !== 'heartbeat' && message.action !== 'heartbeat_resp') {
            this.logger?.debug(`Sending ${JSON.stringify(message)} to Infobip WebRTC Gateway...`);
        }
    }

    private onOpen() {
        this.logger?.info('Socket to Infobip WebRTC Gateway opened.');
        let reconnected = this.status === Status.RECONNECTING;
        if (reconnected) {
            this.eventEmitter.once("registered", args => {
                this.eventEmitter.emit('reconnected');
            });
        }
        this.status = Status.CONNECTED;
        this.scheduleHeartbeat();
        this.initRetry();
    }

    private onMessage(message: any) {
        let data = JSON.parse(message.data);
        this.logReceivedMessage(data);
        if (data.event === 'heartbeat_resp') {
            this.cancelHeartbeatCheck();
            return;
        }
        if (data.event === 'heartbeat') {
            this.sendHeartbeatResponse();
            return;
        }

        if (data.event === 'registration_failed') {
            this.disconnect();
        }
        this.eventEmitter.emit(data.event, data);
    }

    private sendHeartbeatResponse() {
        this.send({action: 'heartbeat_resp'});
    }

    private logReceivedMessage(message: any) {
        if (message.event !== 'heartbeat_resp' && message.event !== 'heartbeat') {
            this.logger?.debug(`Received message from Infobip Gateway: ${JSON.stringify(message)}.`);
        }
    }

    private onClose(event: any) {
        this.logger?.info(`Socket closed, code ${event.code}, reason: ${event.reason}.`);
        const prevStatus = this.status;
        this.status = Status.OFFLINE;
        this.cleanup();

        if (prevStatus !== Status.OFFLINE && event.code < 4000) {
            this.retry.retry();
            if (prevStatus === Status.CONNECTED) {
                this.eventEmitter.emit('reconnecting');
            }
        } else {
            this.eventEmitter.emit('disconnected', event);
        }
    }

    private cleanup() {
        clearInterval(this.heartbeat);
        this.cancelHeartbeatCheck();

        this.ws.onopen = null;
        this.ws.onclose = null;
        this.ws.onmessage = null;
        this.ws.onerror = null;
        delete this.ws;
    }

    private onError(event: any) {
        this.logger?.error(`Socket error: ${JSON.stringify(event)}.`);
    }

    private scheduleHeartbeat() {
        this.heartbeat = setInterval(() => {
            if (this.ws.readyState === WebSocket.OPEN) {
                this.send({'action': 'heartbeat'});
                this.scheduleHeartbeatCheck();
            }
        }, Properties.HEARTBEAT_PERIOD);
    }

    private scheduleHeartbeatCheck() {
        if (this.heartbeatCheck) {
            this.logger?.warn("Did not receive previous heartbeat response, skipping new heartbeat check.");
            return;
        }
        this.heartbeatCheck = setTimeout(() => {
            this.logger?.info(`No heartbeat response in ${Properties.HEARTBEAT_TIMEOUT} milliseconds. Closing socket...`);
            this.onClose({code: 3000, reason: 'Heartbeat timeout.'});
        }, Properties.HEARTBEAT_TIMEOUT);
    }

    private initRetry() {
        this.retry = new Retry(
            iteration => {
                this.logger?.info(`Reconnect attempt ${iteration}...`);
                this.connect(true);
            },
            Retry.exponentialDelay(Properties.RECONNECT_INITIAL_DELAY),
            Properties.RECONNECT_MAX_DELAY,
            Properties.RECONNECT_MAX
        )
            .catch(reason => {
                this.logger?.info(`Reconnecting failed, reason: ${reason}`);
                this.eventEmitter.emit('disconnected', {reason: 'Connection error.'});
            });
    }

    private generateDeviceInfo() {
        return {
            sdk: {type: 'js', version: version},
            device: {browser: Browser.getBrowser(), os: Browser.getOS()}
        };
    }

    private base64EncodeUrl(value: String) {
        return value.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    private cancelHeartbeatCheck() {
        clearTimeout(this.heartbeatCheck);
        this.heartbeatCheck = undefined;
    }
}
